@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="2" [span]="2">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div class="flex flex-col gap-2 md:grid md:h-full">
			<div edit-container-content>
				<h1>Layer Templates</h1>
				<yuno-forms-title>General Settings</yuno-forms-title>

				@if (service.form) {
					<form [formGroup]="service.form">
						<yuno-forms-text
							class="mb-4 flex-1"
							formControlName="id"
							label="Template name"
							placeholder="template name"></yuno-forms-text>

						<yuno-forms-image
							class="mb-4 flex-1"
							formControlName="img"
							label="Image"
							placeholder="template image"></yuno-forms-image>

						<ng-container formGroupName="options">
							<yuno-forms-select
								class="mb-4 flex-1"
								formControlName="layerType"
								label="Layer Type"
								[selectValues]="service.layerTypes"
								placeholder="template image"></yuno-forms-select>
						</ng-container>

						<div class="grid grid-cols-1">
							<yuno-forms-title class="mt-6">Tags</yuno-forms-title>
							<yuno-forms-span
								>tags allow you to quickly find your templates
							</yuno-forms-span>
						</div>

						<yuno-forms-array-container
							[control]="service.tags"
							formArrayName="tags"
							cdkDropList
							(cdkDropListDropped)="drop($event)">
							<div arrayBody class="mb-4 grid grid-cols-1 gap-2">
								@for (url of service.tags.controls; track url; let i = $index) {
									<yuno-admin-drag-row
										[keepButtons]="true"
										cdkDrag
										cdkDragLockAxis="y">
										<div class="flex-1 select-none" title>
											<yuno-forms-text
												[formControlName]="i"
												placeholder="Template Tag">
												@if (
													service.tags.controls[i].errors?.[
														'minlength'
													] ||
													service.tags.controls[i].errors?.['required']
												) {
													<span>
														please provide at least 3 characters
													</span>
												}

												@if (
													service.tags.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['whitespace']
												) {
													<span> whitespaces are not allowed! </span>
												}

												@if (
													service.tags.controls[i].errors?.[
														'noSpacesLowerCase'
													]?.['uppercase']
												) {
													<span> only lowercase is allowed </span>
												}
											</yuno-forms-text>
										</div>
										<ng-container buttons>
											<button
												yuno-admin-button-table
												(click)="onRemoveTag(i)"
												color="danger">
												delete
											</button>
										</ng-container>
									</yuno-admin-drag-row>
								}
							</div>
							<div arrayErrors>
								@if (!service.tags.valid && !service.tags.errors) {
									<span> one of the tags is not valid! </span>
								}
								@if (service.tags.errors?.['minlength']) {
									<span> please provide at least one tag! </span>
								}
							</div>
						</yuno-forms-array-container>
						<div class="flex items-center gap-4">
							<button
								yuno-admin-button
								color="primary"
								[add]="true"
								(click)="onAddTag()">
								Add Tag
							</button>
						</div>

						<yuno-forms-title class="mt-6">Layers</yuno-forms-title>
						@if (notFoundCount > 0) {
							<yuno-admin-annotation type="info" class="mb-4">
								{{ notFoundCount }} Layers found with a different AppId.
							</yuno-admin-annotation>
						}
						<yuno-admin-table
							[pagination]="true"
							[pageOptions]="{
								pageSize: 50,
								pageSizeOptions: [25, 50, 100],
								hidePageSize: false
							}"
							[selectable]="true"
							[filterable]="true"
							[data]="data.layers"
							[selectData]="data.changes"
							[columns]="tableColumns"
							(selected)="onSelect($event)"
							(selectAll)="onSelectAll($event)">
						</yuno-admin-table>
					</form>
				}
			</div>
		</div>
	</yuno-edit-container>
}
