<div class="flex flex-col gap-4">
	<!-- Config -->
	@if (service.form) {
		<form [formGroup]="service.form" class="grid grid-cols-1 gap-4">
			<yuno-forms-title>Cluster Configuration</yuno-forms-title>
			<div formGroupName="cluster" class="mt-4">
				<div class="grid grid-cols-2 gap-4" formGroupName="options">
					<yuno-forms-number
						formControlName="minZoom"
						label="min. Zoom"></yuno-forms-number>
					<yuno-forms-number
						formControlName="maxZoom"
						label="max. Zoom"></yuno-forms-number>
					<yuno-forms-number
						formControlName="minPoints"
						label="min. markers in Cluster"></yuno-forms-number>
					<yuno-forms-number
						formControlName="radius"
						label="collapse radius in pixels"></yuno-forms-number>
				</div>
			</div>
		</form>
	}

	<yuno-forms-divider></yuno-forms-divider>

	<!-- TESTING COUNTER SEPERATE FROM FORM -->
	@if (service.cluster.value; as cluster) {
		<div>
			<yuno-forms-title>Selected Icon</yuno-forms-title>
			<div class="yuno-fake-marker-icon mt-8" [ngClass]="cluster.style?.alignment || ''">
				<img
					attr="marker-icon"
					[src]="cluster.style?.icon"
					class="max-w-none"
					[ngStyle]="{
						transform: 'scale(' + cluster.style?.scale || 1 + ')'
					}" />
				<div
					class="yuno-marker-cluster-count !rounded-full px-2 py-1"
					[ngStyle]="{
						backgroundColor: cluster.countStyle?.backgroundColor,
						color: cluster.countStyle?.color
					}"
					[ngClass]="'count-' + (cluster.countStyle?.alignment || 'top')">
					{{ counterValue }}
				</div>
			</div>
			<label class="yuno-form">
				<span>Counter Testing</span>
				<input id="counter" type="number" [(ngModel)]="counterValue" />
			</label>
		</div>
	}
	<!-- END TESTING COUNTER SEPERATE FROM FORM -->

	<yuno-forms-divider></yuno-forms-divider>

	<!-- Styling -->
	@if (service.form) {
		<form [formGroup]="service.form" class="grid grid-cols-1 gap-4">
			<div formGroupName="cluster" class="mt-4 grid grid-cols-1 gap-4">
				<yuno-forms-title>Icon Styling</yuno-forms-title>
				<div formGroupName="style" class="grid grid-cols-2 gap-4">
					<yuno-forms-select
						formControlName="alignment"
						[display]="service.alignmentSelect"
						[selectValues]="service.alignmentSelect"
						label="alignment"></yuno-forms-select>
					<yuno-forms-number
						formControlName="scale"
						label="scale"
						[step]="0.1"></yuno-forms-number>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-title>Counter Styling</yuno-forms-title>
				<div formGroupName="countStyle" class="grid grid-cols-2 gap-4">
					<yuno-forms-select
						class="col-span-2"
						formControlName="alignment"
						[display]="service.alignmentSelect"
						[selectValues]="service.alignmentSelect"
						placeholder="set counter alignment..."
						label="alignment"></yuno-forms-select>
					<yuno-forms-color
						formControlName="backgroundColor"
						label="counter background color"></yuno-forms-color>
					<yuno-forms-color
						formControlName="color"
						label="counter text color"></yuno-forms-color>
				</div>
			</div>
		</form>
	}
</div>
