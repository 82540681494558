import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Dataset } from '@yuno/api/interface';

import { datasetsActions } from './datasets.actions';
import { datasetsFeature } from './datasets.state';

@Injectable()
export class DatasetsFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(datasetsFeature.selectLoaded));
	datasets$ = this.store.pipe(select(datasetsFeature.selectDatasets));
	selectedDataset$ = this.store.pipe(select(datasetsFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(datasetsFeature.selectSelectedLoaded));
	parent$= this.store.pipe(select(datasetsFeature.selectParent));
	status$ = this.store.pipe(select(datasetsFeature.selectStatus));

	get() {
		this.store.dispatch(datasetsActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(datasetsActions.select({ _id: _id }));
	}

	updateSelect(data: Partial<Dataset>) {
		this.store.dispatch(datasetsActions.updateSelect({ data: data }));
	}

	clearSelect() {
		this.store.dispatch(datasetsActions.clearSelect());
	}

	save() {
		this.store.dispatch(datasetsActions.save());
	}

	saveFromTemplate(id: string) {
		this.store.dispatch(datasetsActions.saveFromTemplate({ id }));
	}

	duplicate(_id: string) {
		this.store.dispatch(datasetsActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(datasetsActions.delete({ _id }));
	}

	getParentRoute(ids: string[]) {
		this.store.dispatch(datasetsActions.getParentRoutes({ ids }));
	}
}
