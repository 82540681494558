@if (form && data$ | async; as data) {
	<form [formGroup]="form">
		<yuno-forms-title>General</yuno-forms-title>
		<yuno-forms-text
			formControlName="id"
			label="Participation name"
			placeholder="Add a name for this participate" />
		<section class="mt-8">
			<yuno-forms-toggle formControlName="active" label="Active" labelPos="side" />
			<yuno-admin-annotation class="mt-2">
				When toggled off, this participation will not be loaded in the application.
			</yuno-admin-annotation>
		</section>

		<section class="mt-8 grid grid-cols-1">
			<yuno-forms-span class="col-span-1 text-sm font-semibold">
				Sender notifications
			</yuno-forms-span>
			<yuno-forms-toggle
				formControlName="doubleOptIn"
				label="Double opt-in"
				labelPos="side" />
			<yuno-admin-annotation type="warning" class="mt-2">
				When toggled on, the sender will recveive a confirmation email to ensure that they
				are truly the person sending the message. To inform the user about this an automated
				last page message is activated.
			</yuno-admin-annotation>
		</section>

		<section class="mt-8 grid grid-cols-1 gap-2">
			<yuno-forms-span class="col-span-1 text-sm font-semibold"> Publishing</yuno-forms-span>
			<yuno-forms-toggle formControlName="liveUpdate" label="Live update" labelPos="side" />
			<yuno-admin-annotation class="mt-2">
				When toggled on, incoming participations will be published by default.
			</yuno-admin-annotation>
		</section>

		@if (language$ | async; as language) {
			<section class="mt-8 flex gap-2">
				<yuno-forms-select
					class="flex-1"
					formControlName="notification"
					[unselectDisplay]="'Do not send emails to participants'"
					[selectValues]="service.notificationSelect"
					[display]="service.notificationDisplay"
					label="Notification Settings">
				</yuno-forms-select>
				<yuno-forms-select
					formControlName="notificationLanguage"
					[unselectDisplay]="'Select a language'"
					[selectValues]="service.languageSelect"
					[display]="service.languageDisplay"
					label="Notification Language">
				</yuno-forms-select>
			</section>

			<section class="mt-8 grid grid-cols-1 gap-2" formGroupName="lastpage">
				<yuno-forms-span class="col-span-1 text-sm font-semibold">
					Last Page Settings
				</yuno-forms-span>
				<yuno-admin-annotation class="mt-2">
					When double-opt in is toggled on, the settings below will be overwritten.
				</yuno-admin-annotation>
				<section formGroupName="header">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								placeholder="Header"
								label="Header" />
						}
					}
				</section>
				<section formGroupName="text">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-html-image-library
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								label="Text"
								placeholder="text" />
						}
					}
				</section>
			</section>
		}
	</form>
}
