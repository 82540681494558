import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';
import {
	catchError,
	exhaustMap,
	filter,
	first,
	map,
	mergeMap,
	of,
	switchMap,
	take,
	tap,
	withLatestFrom
} from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_MARKER,
	DeleteMarkerMutation,
	GET_MARKERS_BY_CATEGORY,
	PlaceMarkersQuery,
	SAVE_PLACEMARKER,
	SavePlaceMarkerMutation
} from '@yuno/admin/features/markers/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { ApiService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';

import {
	CREATE_CATEGORY,
	CREATE_TEMPLATE_CATEGORY,
	CreateCategoryMutation,
	CreateTemplateCategoryMutation,
	DELETE_CATEGORY,
	DUPLICATE_CATEGORY,
	DeleteCategoryMutation,
	DuplicateCategoryMutation,
	GET_MARKERCATEGORIES_BY_APPID,
	GET_MARKERCATEGORY_BY_ID,
	MarkerCategoriesQuery,
	SAVE_CATEGORY,
	SaveCategoryMutation,
	SelectedCategoryQuery
} from '../../utils';
import { markerCategoriesActions } from './marker-categories.actions';
import { markerCategoriesFeature } from './marker-categories.state';

export const loadCategories = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.load, markerCategoriesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<MarkerCategoriesQuery>({
						query: GET_MARKERCATEGORIES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.markerCategories) {
								throw new Error('no categories found');
							}
							return markerCategoriesActions.loadSuccess({
								data: data.data.markerCategories
							});
						}),
						take(1),
						catchError(error => of(markerCategoriesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectCategories = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(markerCategoriesActions.select),
			exhaustMap(category =>
				graphql
					.query<SelectedCategoryQuery>({
						query: GET_MARKERCATEGORY_BY_ID,
						variables: {
							_id: category._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedMarkerCategory) {
								message.sendToast(`Error selecting category!`, 'error');
								throw new Error('no Category with that id found');
							}

							return markerCategoriesActions.selectSuccess({
								data: data.data.selectedMarkerCategory
							});
						}),
						take(1),
						catchError(error => of(markerCategoriesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const createCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.create),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.mutate<CreateCategoryMutation>({
						mutation: CREATE_CATEGORY,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.createMarkerCategory) {
								throw new Error('Failed creating');
							}

							return markerCategoriesActions.createSuccess({
								id: data.data.createMarkerCategory._id
							});
						}),
						take(1),
						catchError(error => of(markerCategoriesActions.createFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const createCategorySuccess = createEffect(
	(actions$ = inject(Actions), store = inject(Store)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.createSuccess),
			tap(data => {
				store.dispatch(markerCategoriesActions.select({ _id: data.id }));
			})
		),
	{ dispatch: false, functional: true }
);

export const createCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.createFailure),
			tap(() => {
				message.sendToast(`Error creating Marker Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const createCategoryFromTemplate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.createTemplate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.mutate<CreateTemplateCategoryMutation>({
						mutation: CREATE_TEMPLATE_CATEGORY,
						variables: {
							_id: action._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.createMarkerCategoryFromTemplate) {
								throw new Error(
									'error creating Place Marker Category from template'
								);
							}
							return markerCategoriesActions.createSuccess({
								id: data.data.createMarkerCategoryFromTemplate._id
							});
						}),
						take(1),
						catchError(error => {
							return of(markerCategoriesActions.createFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateSelectedCategory = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Category!`, 'error');
					throw new Error('no Category found');
				}
				return markerCategoriesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(markerCategoriesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const loadMarkers = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.loadMarkers, markerCategoriesActions.reloadMarkers),
			switchMap(data =>
				graphql
					.query<PlaceMarkersQuery>({
						query: GET_MARKERS_BY_CATEGORY,
						variables: {
							category: data.category
						}
					})
					.pipe(
						map(data => {
							if (!data.data.placeMarkers) {
								throw new Error('no Place-Markers found');
							}
							return markerCategoriesActions.loadMarkersSuccess({
								markers: data.data.placeMarkers.markers,
								fences: data.data.placeMarkers.fences
							});
						}),
						take(1),
						catchError(error =>
							of(markerCategoriesActions.loadMarkersFailure({ error }))
						)
					)
			)
		),
	{ functional: true }
);

export const selectMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.selectMarker),
			mergeMap(action =>
				store.select(markerCategoriesFeature.selectMarkerById(action._id)).pipe(
					filter(data => !!data),
					first()
				)
			),
			switchMap(data => {
				if (!data) {
					return of(
						markerCategoriesActions.selectMarkerFailure({
							error: new Error(`No Marker with this id found inside the store`)
						})
					);
				}

				return of(
					markerCategoriesActions.selectMarkerSuccess({
						data: data
					})
				);
			})
		),
	{ functional: true }
);

export const saveCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.save),
			withLatestFrom(
				store.pipe(select(markerCategoriesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) => {
				const data = { ...selected };
				delete data?.fenceData;
				delete data.appId;

				return graphql
					.mutate<SaveCategoryMutation>({
						mutation: SAVE_CATEGORY,
						variables: {
							category: data,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveCategory) {
								throw new Error('error saving Category to the database');
							}
							store.dispatch(
								markerCategoriesActions.updateSelect({
									data: data.data.saveCategory
								})
							);
							return markerCategoriesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(markerCategoriesActions.saveFailure({ error })))
					);
			})
		),
	{ functional: true }
);

export const duplicateCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([category, appId]) =>
				graphql
					.mutate<DuplicateCategoryMutation>({
						mutation: DUPLICATE_CATEGORY,
						variables: {
							_id: category._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateCategory) {
								throw new Error('error duplicating Category to the database');
							}
							store.dispatch(markerCategoriesActions.reload());
							return markerCategoriesActions.duplicateSuccess({
								data: data.data.duplicateCategory
							});
						}),
						take(1),
						catchError(error => {
							return of(markerCategoriesActions.duplicateFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteCategory = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.delete),
			switchMap(category =>
				graphql
					.mutate<DeleteCategoryMutation>({
						mutation: DELETE_CATEGORY,
						variables: {
							_id: category._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteCategory) {
								throw new Error('error deleting Category from the database');
							}
							store.dispatch(markerCategoriesActions.reload());
							return markerCategoriesActions.deleteSuccess({
								_id: data.data.deleteCategory
							});
						}),
						take(1),
						catchError(error => of(markerCategoriesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.saveMarker),
			withLatestFrom(
				store.pipe(select(markerCategoriesFeature.selectSelectedMarker)),
				store.pipe(select(markerCategoriesFeature.selectedCategoryName)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([data, selected, categoryName, appId]) =>
				graphql
					.mutate<SavePlaceMarkerMutation>({
						mutation: SAVE_PLACEMARKER,
						variables: {
							marker: selected,
							categoryName: categoryName,
							appId: appId,
							fences: data.fences
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePlaceMarker) {
								throw new Error('error saving Marker to the database');
							}

							if (selected?.category) {
								store.dispatch(
									markerCategoriesActions.loadMarkers({
										category: selected.category
									})
								);
							}

							store.dispatch(
								markerCategoriesActions.selectMarkerSuccess({
									data: data.data.savePlaceMarker
								})
							);

							return markerCategoriesActions.saveMarkerSuccess({
								data: data.data.savePlaceMarker
							});
						}),
						take(1),
						catchError(error => {
							return of(markerCategoriesActions.saveMarkerFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.deleteMarker),
			withLatestFrom(store.pipe(select(markerCategoriesFeature.selectSelected))),
			switchMap(([marker, category]) =>
				graphql
					.mutate<DeleteMarkerMutation>({
						mutation: DELETE_MARKER,
						variables: {
							_id: marker._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteMarker) {
								throw new Error('error deleting Marker from the database');
							}
							if (category?._id) {
								store.dispatch(
									markerCategoriesActions.reloadMarkers({
										category: category._id
									})
								);
							}
							return markerCategoriesActions.deleteMarkerSuccess({
								_id: data.data.deleteMarker
							});
						}),
						take(1),
						catchError(error =>
							of(markerCategoriesActions.deleteMarkerFailure({ error }))
						)
					)
			)
		),
	{ functional: true }
);

export const saveCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Category successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Category successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteCategorySuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Category successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteCategoryFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Category!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveMarkerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.saveMarkerSuccess),
			tap(() => {
				message.sendToast(`Marker successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveMarkerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.saveMarkerFailure),
			tap(() => {
				message.sendToast(`Error saving Marker!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteMarkerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.deleteMarkerSuccess),
			tap(() => {
				message.sendToast(`Marker successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteMarkerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.deleteMarkerFailure),
			tap(() => {
				message.sendToast(`Error deleting Marker!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const loadMapStyle = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.loadMapStyle),
			switchMap(action =>
				api.getObservable<StyleSpecification>(action.style).pipe(
					map(data => {
						return markerCategoriesActions.loadMapStyleSuccess({ data });
					}),
					take(1),
					catchError(error => of(markerCategoriesActions.loadMapStyleFailure({ error })))
				)
			)
		),
	{ functional: true }
);

export const loadMapStyleFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markerCategoriesActions.loadMapStyleFailure),
			tap(() => {
				message.sendToast(`Error loading MapStyle!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
