import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_LAYER,
	DUPLICATE_LAYER,
	DeleteLayerMutation,
	DuplicateLayerMutation,
	GET_LAYERS_BY_APPID,
	GET_LAYER_BY_ID,
	LayersQuery,
	SAVE_LAYER,
	SAVE_LAYER_FROM_TEMPLATE,
	SaveLayerFromTemplateMutation,
	SaveLayerMutation,
	SelectLayerQuery
} from '@yuno/admin/features/layers/utils';
import {
	GET_CUSTOMSOURCE_BY_NAME,
	GET_SOURCE_BY_NAME,
	SelectCustomSourceByNameQuery,
	SelectSourceByNameQuery
} from '@yuno/admin/features/sources/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { layersActions } from './layers.actions';
import { layersFeature } from './layers.state';


export const loadLayers = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.load, layersActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<LayersQuery>({
						query: GET_LAYERS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.layers) {
								throw new Error('no layers found');
							}
							return layersActions.loadSuccess({
								data: data.data.layers
							});
						}),
						take(1),
						catchError(error => of(layersActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectLayers = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		store = inject(Store),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(layersActions.select),
			exhaustMap(layer =>
				graphql
					.query<SelectLayerQuery>({
						query: GET_LAYER_BY_ID,
						variables: {
							_id: layer._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedLayer) {
								message.sendToast(`Error selecting layer!`, 'error');
								throw new Error('no layer with that id found');
							}
							if (data.data.selectedLayer.source) {
								const id = data.data.selectedLayer.source;
								store.dispatch(layersActions.selectSource({ id }));
							}

							return layersActions.selectSuccess({
								data: data.data.selectedLayer
							});
						}),
						take(1),
						catchError(error => of(layersActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.selectSource),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			exhaustMap(([source, appId]) =>
				graphql
					.query<SelectSourceByNameQuery>({
						query: GET_SOURCE_BY_NAME,
						variables: {
							id: source.id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectSourceByName) {
								throw new Error('no source with that id found');
							}
							return layersActions.selectSourceSuccess({
								data: data.data.selectSourceByName
							});
						}),
						take(1),
						catchError(() => of(layersActions.selectCustomSource({ name: source.id })))
					)
			)
		),
	{ functional: true }
);

export const selectCustomSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.selectCustomSource),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			exhaustMap(([source, appId]) =>
				graphql
					.query<SelectCustomSourceByNameQuery>({
						query: GET_CUSTOMSOURCE_BY_NAME,
						variables: {
							name: source.name,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectCustomSourceByName) {
								throw new Error('no source with that id found');
							}
							return layersActions.selectSourceSuccess({
								data: data.data.selectCustomSourceByName
							});
						}),
						take(1),
						catchError(error => of(layersActions.selectSourceFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedLayer = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating layer!`, 'error');
					throw new Error('no layer found');
				}
				return layersActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(layersActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveLayer = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.save),
			withLatestFrom(
				store.pipe(select(layersFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveLayerMutation>({
						mutation: SAVE_LAYER,
						variables: {
							layer: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveLayer) {
								throw new Error('error saving layer to the database');
							}
							store.dispatch(
								layersActions.updateSelect({
									data: data.data.saveLayer
								})
							);

							return layersActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(layersActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateLayer = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([layer, appId]) =>
				graphql
					.mutate<DuplicateLayerMutation>({
						mutation: DUPLICATE_LAYER,
						variables: {
							_id: layer._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateLayer) {
								throw new Error('error duplicating Layer to the database');
							}
							store.dispatch(layersActions.reload());
							return layersActions.duplicateSuccess({
								data: data.data.duplicateLayer
							});
						}),
						take(1),
						catchError(error => of(layersActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteLayer = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.delete),
			switchMap(layer =>
				graphql
					.mutate<DeleteLayerMutation>({
						mutation: DELETE_LAYER,
						variables: {
							_id: layer._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteLayer) {
								throw new Error('error deleting Layer from the database');
							}
							store.dispatch(layersActions.reload());
							return layersActions.deleteSuccess({
								_id: data.data.deleteLayer
							});
						}),
						take(1),
						catchError(error => of(layersActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveLayerFromTemplate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(layersActions.saveFromTemplate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([layer, appId]) =>
				graphql
					.mutate<SaveLayerFromTemplateMutation>({
						mutation: SAVE_LAYER_FROM_TEMPLATE,
						variables: {
							_id: layer.id,
							appId: appId,
							name: layer.name
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveLayerFromTemplate) {
								throw new Error('error duplicating Layer to the database');
							}
							store.dispatch(layersActions.reload());
							if (!layer.name) {
								store.dispatch(
									layersActions.updateSelect({
										data: data.data.saveLayerFromTemplate
									})
								);
							}
							return layersActions.duplicateSuccess({
								data: data.data.saveLayerFromTemplate
							});
						}),
						take(1),
						catchError(error => of(layersActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveLayerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.saveSuccess),
			tap(() => {
				message.sendToast(`Layer successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveLayerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Layer!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateLayerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Layer successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateLayerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Layer!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteLayerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Layer successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteLayerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(layersActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Layer!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
