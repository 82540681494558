import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { EditContainerBreadcrumbsService } from './edit-container-breadcrumbs.service';

@Component({
	selector: 'yuno-edit-container-breadcrumbs',
	standalone: true,
	imports: [],
	template: `
		@for (route of $routes(); track route.value; let i = $index; let last = $last) {
			@if (i >= 1) {
				<span class="flex flex-col justify-center py-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" fill="none">
						<path
							fill="#E0E9F1"
							d="M.665.506a.665.665 0 0 1 .472.2l3.328 3.329a.666.666 0 0 1 0 .943L1.133 8.31a.667.667 0 0 1-.944-.943l2.867-2.862L.194 1.644A.667.667 0 0 1 .665.506Z" />
					</svg>
				</span>
			}
			<span class="p-4 font-semibold"> {{ route.value }} </span>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host {
				display: flex;
				flex-direction: row;
				position: absolute;

				@apply left-4 top-0 truncate text-yuno-blue-gray-300;
			}
		`
	]
})
export class EditContainerBreadcrumbsComponent {
	readonly service = inject(EditContainerBreadcrumbsService);

	$routes = this.service.$routes;
}
