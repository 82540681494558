import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { startWith } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { AdminSearchBarComponent, AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { CurlyPipe } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll } from '@yuno/api/interface';

import { ParticipationModelEditorsDefaultComponent } from '../default.component';

@Component({
	selector: 'yuno-admin-participation-model-reaction',
	standalone: true,
	imports: [
		YunoFormsModule,
		AnnotationComponent,
		SpritesheetModule,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		AsyncPipe,
		CurlyPipe,
		AdminSearchBarComponent
	],
	templateUrl: './reaction.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorReactionComponent extends ParticipationModelEditorsDefaultComponent {
	private appFacade = inject(AppFacade);

	protected readonly languages = LanguageAll;

	language$ = this.appFacade.language$.pipe(startWith('nl'));

	$filteredKeys = signal<string[]>([]);
	$filteredValues = signal<string[]>([]);

	constructor() {
		super();

		effect(
			() => {
				this.applyFilter('');
			},
			{
				allowSignalWrites: true
			}
		);
	}

	applyFilter(str: string): void {
		const lowerSearchTerm = str.toLowerCase();

		const data = this.service.$textfieldValues();
		const originalKeys = Object.keys(data);
		const originalValues = Object.values(data);

		// Filter keys and values based on the search term
		this.$filteredKeys.update(() => {
			return [
				'',
				...originalKeys.filter(
					(key, index) =>
						originalValues[index] &&
						originalValues[index].toLowerCase().includes(lowerSearchTerm)
				)
			];
		});

		this.$filteredValues.update(() => {
			return [
				'none',
				...originalValues.filter(
					value => value && value.toLowerCase().includes(lowerSearchTerm)
				)
			];
		});
	}
}
