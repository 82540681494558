import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-styling-rules',
	standalone: true,
	imports: [
		CommonModule,
		YunoFormsModule,
		ReactiveFormsModule,
		JsonFormsModule,
		YunoAdminButtonComponent
	],
	templateUrl: './category-styling-rules.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryStylingRulesComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);

	service = inject(CategoryEditorService);

	addRule(): void {
		this.service.addRuleToLayoutOptions();
	}

	removeRule(index: number): void {
		this.service.removeRuleFromLayoutOptions(index);
	}

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}
}
