import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { combineLatest, map, startWith } from 'rxjs';

import { FencesFacade } from '@yuno/admin/features/fences';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminButtonTableComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { TextfieldOverlayLeftFullComponent } from '@yuno/angular/textfield-overlays';
import { Fence } from '@yuno/api/interface';

import { ShapesDataForm } from '../../dataset-editor.service';
import { DatasetListviewBasicComponent } from '../basic-dataset-list-component.component';

@Component({
	selector: 'yuno-admin-dataset-listview-shapes',
	standalone: true,
	imports: [
		YunoAdminTableComponent,
		YunoAdminButtonTableComponent,
		AsyncPipe,
		ReactiveFormsModule,
		YunoFormsModule,
		RouterOutlet
	],
	templateUrl: './shapes.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewShapesComponent
	extends DatasetListviewBasicComponent
	implements OnInit
{
	@ViewChild('table', { read: YunoAdminTableComponent }) table: YunoAdminTableComponent;

	@ViewChild('participateOverlayContainer', { read: TextfieldOverlayLeftFullComponent })
	participateOverlayContainer: TextfieldOverlayLeftFullComponent;

	private readonly facade = inject(FencesFacade);

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => data?.data?.shapes.map(data => data.fence as TableRow) || [])
		),
		fences: this.facade.fences$.pipe(startWith([]))
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Shape' }];

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	closeButtonPopup() {
		this.table?.closeButton();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'Shapes',
			e.selection.map(data => ({ fence: data }))
		);
	}

	// Triggers when selecting or deselecting a row in the table
	// when adding an item, also adds an event key to the object
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove('Shapes', 'fence', e.row);
			return;
		}

		this.service.add('Shapes', { fence: e.row });
	}

	shapeOptionsData(row: Partial<Fence>): {
		enabled: boolean;
		move: boolean;
		scale: boolean;
		rotate: boolean;
	} {
		if (!row) {
			return { enabled: false, move: false, scale: false, rotate: false };
		}

		const formGroups = this.service.dataShapes.controls as FormGroup[];
		const data = formGroups.find(
			group => group.controls?.['fence'].get('_id')?.value === row._id
		);

		if (!data) {
			return {
				enabled: false,
				move: false,
				scale: false,
				rotate: false
			};
		}

		return {
			enabled: true,
			move: data.value.canMove,
			scale: data.value.canScale,
			rotate: data.value.canRotate
		};
	}

	dataShapesForm(row: Partial<Fence>): FormGroup<ShapesDataForm> {
		const formGroups = this.service.dataShapes.controls as FormGroup[];
		const data = formGroups.find(
			group => group.controls?.['fence'].get('_id')?.value === row._id
		);
		return data as FormGroup<ShapesDataForm>;
	}
}
