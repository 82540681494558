import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { placeMarkersGuard, placeMarkersListGuard } from './place-markers.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [placeMarkersListGuard],
		loadComponent: () => import('./place-markers.component').then(m => m.PlaceMarkersComponent)
	},
	{
		path: 'view/:id',
		canActivate: [placeMarkersGuard],
		loadChildren: () =>
			import('./../view/view.router.module').then(m => m.PlaceMarkersViewViewerRoutingModule)
	},
	{
		path: 'template/create',
		loadComponent: () =>
			import('../templates/template-editor/template-editor.component').then(
				m => m.PlaceMarkerTemplateEditorComponent
			)
	},
	{
		path: 'template/:id',
		loadComponent: () =>
			import('../templates/template-editor/template-editor.component').then(
				m => m.PlaceMarkerTemplateEditorComponent
			)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PlaceMarkersRoutingModule {}
