import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Fence } from '@yuno/api/interface';

export const fencesActions = createActionGroup({
	source: 'Fences',
	events: {
		load: emptyProps(),
		loadFencesSuccess: props<{ fences: Partial<Fence>[] }>(),
		loadFencesFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectFenceSuccess: props<{ data: Partial<Fence> }>(),
		selectFenceFailure: props<{ error: Error }>(),
		updatePolygon: props<{ data: Partial<unknown[]> }>(),

		multi: props<{ ids: string[] }>(),
		multiSuccess: props<{ data: Partial<Fence>[] }>(),
		multiFailure: props<{ error: Error }>(),
		multiClear: emptyProps(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),
		saveData: props<{ fence: Partial<Fence> }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ fence: Partial<Fence> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Fence> }>(),
		updateSelectSuccess: props<{ data: Partial<Fence> }>(),
		updateSelectFailure: props<{ error: Error }>(),

	}
});
