@if (data$ | async; as data) {
	@if (service.form) {
		<form [formGroup]="service.form" class="mt-4">
			<yuno-forms-divider></yuno-forms-divider>
			<yuno-forms-title>Style Editor</yuno-forms-title>
			<div formArrayName="styles" class="mt-4">
				@if (data.selected && enabled) {
					<div [formGroupName]="data.selected.index" class="grid grid-cols-1 gap-4">
						<yuno-forms-text
							formControlName="id"
							label="Style / Category name"></yuno-forms-text>

						<h4>Visibility</h4>
						<div formGroupName="style" class="grid grid-cols-2 gap-4">
							<yuno-forms-number
								formControlName="minZoom"
								label="Marker min. Zoom"></yuno-forms-number>
							<yuno-forms-number
								formControlName="maxZoom"
								label="Marker max. Zoom"></yuno-forms-number>
						</div>
						<yuno-forms-toggle
							class="mb-4"
							labelPos="side"
							formControlName="overwriteZoom"
							label="allow the markers zoom properties to overwrite the category zoom"></yuno-forms-toggle>
						<div formGroupName="style">
							<yuno-forms-select
								formControlName="visibility"
								[selectValues]="service.visibilityType"
								[display]="service.visibilityType"
								label="Marker Visibility"></yuno-forms-select>
						</div>
						<div formGroupName="style" class="grid grid-cols-2 gap-4">
							<yuno-forms-number
								formControlName="minFence"
								label="Fence: min. Zoom"></yuno-forms-number>
							<yuno-forms-number
								formControlName="maxFence"
								label="Fence: max. Zoom"></yuno-forms-number>
							<yuno-forms-color formControlName="areaColor" label="Area: Color">
							</yuno-forms-color>
							<yuno-forms-number
								formControlName="areaOpacity"
								[step]="0.01"
								[min]="0"
								[max]="1"
								label="Area: Opacity"></yuno-forms-number>
						</div>
						<yuno-forms-divider [useGap]="true"></yuno-forms-divider>

						@if (service.type.value === 'label') {
							<div class="flex flex-col gap-4">
								<h4>Label Settings</h4>
								<div formGroupName="style" class="grid grid-cols-2 gap-4">
									<yuno-forms-select
										class="coll-span-1"
										formControlName="class"
										[selectValues]="service.classSelect"
										label="Style"></yuno-forms-select>
									<yuno-forms-select
										class="coll-span-1"
										formControlName="eventStyle"
										[selectValues]="service.eventSelect"
										[display]="service.eventDisplay"
										label="Event Type"></yuno-forms-select>

									<yuno-forms-color
										formControlName="backgroundColor"
										label="Background color"></yuno-forms-color>
									<yuno-forms-color
										formControlName="color"
										label="Text color"></yuno-forms-color>
								</div>
							</div>
						}
						@if (service.type.value === 'icon') {
							<div class="flex flex-col gap-4">
								<h4>Icon Settings</h4>
								@if ({ icon: getIcon(data.selected.index) }; as icon) {
									<div>
										<yuno-forms-span class="font-semibold"
											>Selected Icon
										</yuno-forms-span>
										<div>
											@switch (!!icon.icon) {
												@case (true) {
													<div
														class="flex flex-row items-center justify-start gap-2">
														<div
															class="border-1 pointer-events-auto flex cursor-pointer rounded border border-dashed border-gray-300 p-2 transition-colors hover:bg-yuno-blue-hover">
															<img
																[src]="icon.icon"
																alt="icon image"
																class="pointer-events-none" />
														</div>
														<yuno-forms-span class="italic">
															<a
																[href]="icon.icon"
																target="_blank"
																rel="noopner"
																>image link</a
															>
														</yuno-forms-span>
													</div>
												}
												@default {
													<yuno-forms-span
														>No Icon selected...
													</yuno-forms-span>
												}
											}
										</div>
									</div>
								}
								<yuno-forms-span class="font-semibold">Choose Icon</yuno-forms-span>
								<div
									class="block max-h-96 overflow-y-auto rounded-lg border border-gray-300 bg-gray-100 p-2">
									<yuno-admin-spritesheet-manager
										[disableCopy]="true"
										[appId]="appId"
										[clientId]="clientId"
										(selectIcon)="
											setIcon($event, data.selected.index)
										"></yuno-admin-spritesheet-manager>
								</div>

								<div formGroupName="style" class="grid grid-cols-2 gap-4">
									<yuno-forms-number
										formControlName="rotation"
										label="Icon Rotation"></yuno-forms-number>
									<yuno-forms-number
										formControlName="scale"
										label="Icon Scale"
										[step]="0.1"></yuno-forms-number>
								</div>
							</div>
						}
						<yuno-forms-divider [useGap]="true"></yuno-forms-divider>

						<div formGroupName="style" class="grid grid-cols-2 gap-4">
							<div class="col-span-2 flex flex-row justify-start">
								<yuno-admin-place-marker-alignment-selector
									class="max-w-xs"
									[alignment]="service.getStyleAlignment(data.selected.index)"
									(alignmentChange)="
										service.setStyleAlignment(data.selected.index, $event)
									"></yuno-admin-place-marker-alignment-selector>
							</div>
						</div>
					</div>
				}
			</div>
		</form>
	}
}
