import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_TEMPLATE,
	DeleteTemplateMutation,
	FILTER_TEMPLATES,
	FilterTemplatesQuery,
	GET_TEMPLATES_BY_SELECTOR,
	GET_TEMPLATE_BY_ID,
	SAVE_TEMPLATE,
	SaveTemplateMutation,
	SelectTemplateQuery,
	TemplatesQuery
} from '../../utils';
import { templatesActions } from './templates.actions';
import { templatesFeature } from './templates.state';

export const loadTemplates = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(templatesActions.load, templatesActions.reload),
			switchMap(template =>
				graphql
					.query<TemplatesQuery>({
						query: GET_TEMPLATES_BY_SELECTOR,
						variables: {
							selector: template.selector
						}
					})
					.pipe(
						map(data => {
							if (!data.data.templates) {
								throw new Error('no Templates found');
							}
							return templatesActions.loadSuccess({
								data: data.data.templates
							});
						}),
						take(1),
						catchError(error => of(templatesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const filterTemplates = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(templatesActions.filter),
			switchMap(template =>
				graphql
					.query<FilterTemplatesQuery>({
						query: FILTER_TEMPLATES,
						variables: {
							selector: template.selector,
							filter: template.filter
						}
					})
					.pipe(
						map(data => {
							if (!data.data.filterTemplates) {
								throw new Error('no Templates found');
							}
							return templatesActions.loadSuccess({
								data: data.data.filterTemplates
							});
						}),
						take(1),
						catchError(error => of(templatesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectTemplates = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(templatesActions.select),
			exhaustMap(template =>
				graphql
					.query<SelectTemplateQuery>({
						query: GET_TEMPLATE_BY_ID,
						variables: {
							_id: template._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedTemplate) {
								message.sendToast(`Error selecting Template!`, 'error');
								throw new Error('no Template with that id found');
							}

							return templatesActions.selectSuccess({
								data: data.data.selectedTemplate
							});
						}),
						take(1),
						catchError(error => of(templatesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedTemplate = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(templatesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Template!`, 'error');
					throw new Error('no Template found');
				}
				return templatesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(templatesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveTemplate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(templatesActions.save),
			withLatestFrom(store.pipe(select(templatesFeature.selectSelected))),
			switchMap(([, selected]) =>
				graphql
					.mutate<SaveTemplateMutation>({
						mutation: SAVE_TEMPLATE,
						variables: {
							template: selected
						}
					})
					.pipe(
						map(state => {
							if (!state.data?.saveTemplate) {
								throw new Error('error saving Template to the database');
							}
							store.dispatch(
								templatesActions.updateSelect({
									data: state.data.saveTemplate
								})
							);
							store.dispatch(
								templatesActions.reload({
									selector: state.data.saveTemplate?.selector || ''
								})
							);
							return templatesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(templatesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteTemplate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(templatesActions.delete),
			switchMap(template =>
				graphql
					.mutate<DeleteTemplateMutation>({
						mutation: DELETE_TEMPLATE,
						variables: {
							_id: template._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteTemplate) {
								throw new Error('error deleting Template from the database');
							}
							store.dispatch(
								templatesActions.reload({ selector: template.selector })
							);
							return templatesActions.deleteSuccess({
								_id: data.data.deleteTemplate
							});
						}),
						take(1),
						catchError(error => of(templatesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveTemplateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(templatesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Template successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveTemplateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(templatesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Template!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteTemplateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(templatesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Template successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteTemplateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(templatesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Template!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
