import { Injectable, signal } from '@angular/core';

export type routesBreadCrumb = {
	type: 'theme' | 'dataset' | 'data';
	value: string;
};

export type routesBreadCrumbs = routesBreadCrumb[];
type routeBreadCrumbKeys = routesBreadCrumb['type'];
export const routesBreadCrumbTypeOrder: { [key in routesBreadCrumb['type']]: number } = {
	theme: 1,
	dataset: 2,
	data: 3
};

@Injectable({
	providedIn: 'root'
})
export class EditContainerBreadcrumbsService {
	$routes = signal<routesBreadCrumbs>([]);

	private orderRoutesBreadCrumbs(breadcrumbs: routesBreadCrumbs): routesBreadCrumbs {
		return breadcrumbs.sort(
			(a, b) => routesBreadCrumbTypeOrder[a.type] - routesBreadCrumbTypeOrder[b.type]
		);
	}

	resetRoutes(): void {
		this.$routes.set([]);
	}

	addRoute(type: routeBreadCrumbKeys, value = 'empty'): void {
		const val = this.$routes();

		// Filter out any existing entry with the same type
		const updatedRoutes = val.filter(route => route.type !== type);

		// Add the new route
		updatedRoutes.push({ type, value });

		this.$routes.set(this.orderRoutesBreadCrumbs(updatedRoutes));
	}

	removeLastRoute(): void {
		this.$routes.update(val => val.slice(0, -1));
	}

	removeRouteName(routeName = 'empty'): void {
		this.$routes.update(val => val.filter(route => route.value !== routeName));
	}

	removeRouteByType(type: routeBreadCrumbKeys): void {
		this.$routes.update(val => val.filter(route => route.type !== type));
	}
}
