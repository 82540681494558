import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

@Injectable()
export class MatomoTrackingService {
	disableTracking = false;

	constructor() {
		window._paq = window?._paq || [];
	}

	/* Enabled or Disables Cookie Tracking */
	acceptMatomoCookies(bool: boolean): void {
		window._paq.push([bool ? 'rememberCookieConsentGiven' : 'forgetCookieConsentGiven']);
	}

	/**
	 * Logs an event with an event category (Videos, Music, Games…), an event action (Play, Pause, Duration,
	 * Add Playlist, Downloaded, Clicked…), and an optional event name and optional numeric value.
	 *
	 * @param category Category of the event.
	 * @param action Action of the event.
	 * @param [name] Optional name of the event.
	 *
	 * (MapEvent, OpenPanorama, ${PanoramaName})
	 */
	trackEvent(category: string, action: string, name?: string): void {
		try {
			if (this.disableTracking) {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const args: any[] = [category, action];
			if (name) {
				args.push(name);
			}

			window._paq.push(['trackEvent', ...args]);
		} catch (error) {
			if (!(error instanceof ReferenceError)) {
				throw error;
			}
		}
	}

	/**
	 * Overrides document.title
	 *
	 * @param title Title of the document.
	 */
	setDocumentTitle(title: string): void {
		try {
			if (this.disableTracking) {
				return;
			}

			window._paq.push(['setDocumentTitle', title]);
		} catch (error) {
			if (!(error instanceof ReferenceError)) {
				throw error;
			}
		}
	}

	/* Track Pages visited by Users */
	trackPageView(): void {
		try {
			if (this.disableTracking) {
				return;
			}

			window._paq.push(['setCustomUrl', window.location.href]);
			window._paq.push([
				'setDocumentTitle',
				window.location.pathname.replace(/^\/([^/]*).*$/, '$1')
			]);

			// remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
			window._paq.push(['deleteCustomVariables', 'page']);
			window._paq.push(['trackPageView']);
		} catch (error) {
			if (!(error instanceof ReferenceError)) {
				throw error;
			}
		}
	}
}
