import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { modelsActions } from '@yuno/admin/features/participation/data-access/+state/models/models.actions';
import { Status } from '@yuno/admin/utils';
import { ParticipationModel, ParticipationPages } from '@yuno/api/interface';

export interface ParticipationModelState {
	data: Partial<ParticipationModel>[];
	loaded: Status;
	selected: Partial<ParticipationModel> | undefined;
	originalSelected: Partial<ParticipationModel> | undefined;
}

export const initialStateModels: ParticipationModelState = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined,
	originalSelected: undefined
};

const reducer = createReducer(
	initialStateModels,
	on(appActions.reset, () => initialStateModels),
	on(modelsActions.reset, () => ({
		...initialStateModels
	})),

	on(modelsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),

	on(modelsActions.loadSuccess, (state, action) => ({
		...state,
		data: action.data,
		status: Status.SUCCESS
	})),

	on(modelsActions.loadFailure, state => ({
		...state,
		data: [],
		status: Status.FAILED
	})),
	on(modelsActions.selectSuccess, (state, action) => ({
		...state,
		selected: { ...action.data },
		originalSelected: { ...action.data }
	})),

	on(modelsActions.selectFailure, state => ({
		...state,
		selected: undefined,
		originalSelected: undefined
	})),

	on(modelsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		originalSelected: undefined
	})),
	on(modelsActions.restoreOriginal, state => ({
		...state,
		selected: { ...state.originalSelected }
	})),

	on(modelsActions.updateSelectSuccess, (state, { data }) => {
		console.log(data.textfield);
		console.log(data.textfield && data.textfield.length > 1 ? data.textfield : undefined);
		return {
			...state,
			selected: {
				...state.selected,
				...data,
				textfield: data.textfield && data.textfield.length > 1 ? data.textfield : undefined
			} as Partial<ParticipationModel>
		};
	}),

	on(modelsActions.saveSuccess, state => {
		// Extract the 'notifications' and 'selected' properties from the 'state' object using destructuring.
		const list = state.data;
		const model = state.selected;

		// Find the index of the selected model in the list using 'findIndex'.
		const index = list.findIndex(x => x['_id'] === model?.['_id']);

		// Create a new array 'data' by spreading the 'list' array.
		const data = [...list];

		// If the selected model already exists in the array, update it by merging the existing object with the selected object.
		// Otherwise, if the selected model exists and is truthy, add it to the end of the 'data' array.
		if (index >= 0) {
			data[index] = { ...data[index], ...model };
		} else if (model) {
			data.push(model);
		}

		// Sort the 'data' array based on the 'id' property of its elements.
		// The nullish coalescing operator '??' is used to handle undefined or null values in the 'id' property.
		data.sort((a, b) => {
			const idA = a.id ?? '';
			const idB = b.id ?? '';
			return idA > idB ? 1 : -1;
		});

		return {
			...state,
			data: data,
			originalSelected: state.selected
		};
	}),

	// CUSTOMS
	on(modelsActions.changesSelectedModelPagesOrder, (state, action) => {
		const data: ParticipationPages[] = Object.assign([], action.data);
		moveItemInArray(data, action.previousIndex, action.currentIndex);

		return {
			...state,
			selected: {
				...state.selected,
				pages: [...data]
			}
		};
	}),
	on(modelsActions.removeSelectedModelPage, (state, { index }) => {
		const array = [...(state.selected?.pages || [])];
		array.splice(index, 1);

		return {
			...state,
			selected: {
				...state.selected,
				pages: [...array]
			}
		};
	})
);

export const modelsFeature = createFeature({
	name: 'participation-models',
	reducer
});
