import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isEqual } from 'lodash';
import { catchError, map, mergeMap, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { textfieldOverlayActions } from './textfield-overlays.actions';
import { TextfieldOverlayService } from './textfield-overlays.service';
import { textfieldOverlayFeature } from './textfield-overlays.state';

// TODO:
// Replace with import { waitFor } from '@yuno/libs/shared/helpers';
// after https://github.com/nrwl/nx/pull/10414
const waitFor = (delay: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, delay));

export const onLoadTextfieldOverlayAction = createEffect(
	(
		store = inject(Store),
		actions$ = inject(Actions),
		service = inject(TextfieldOverlayService)
	) => {
		return actions$.pipe(
			ofType(textfieldOverlayActions.loadTextfieldOverlay),
			tap(action => {
				service.selectedOverlay = action.overlay || 'left';
			}),
			withLatestFrom(
				store.pipe(
					select(textfieldOverlayFeature.getTextfieldOverlay(service.selectedOverlay))
				),
				store.pipe(select(textfieldOverlayFeature.selectLanguage))
			),

			mergeMap(([action, latest, language]) => {
				// When the Textfield is the same ID and no Action.data is provided
				// we can safely keep the previous Textfield.
				if (latest?.textfield?._id === action.textfield && !action.data) {
					return of(
						textfieldOverlayActions.loadTextfieldOverlaySucces({
							overlay: action.overlay,
							textfield: latest.textfield,
							data: action.data
						})
					);
				}

				// Else we will fetch the new textfield data from the api
				return service
					.getData(action.appId, action.textfield, 'id', action.data, language)
					.pipe(
						map(textfield => {
							if (!textfield.data) {
								throw new Error('Textfield not found!');
							}

							// When the Textfields are the same, keep the previous textfield active
							if (latest?.textfield && isEqual(latest.textfield, textfield.data)) {
								return textfieldOverlayActions.loadTextfieldOverlaySucces({
									overlay: action.overlay,
									textfield: latest.textfield,
									data: action.data
								});
							}

							// Sets the new Textfield
							return textfieldOverlayActions.loadTextfieldOverlaySucces({
								overlay: action.overlay,
								textfield: textfield.data,
								data: action.data
							});
						}),
						take(1),
						catchError(error =>
							of(
								textfieldOverlayActions.loadTextfieldOverlayFailure({
									overlay: action.overlay,
									error
								})
							)
						)
					);
			})
		);
	},
	{ functional: true }
);

export const onCloseOverlayAction = createEffect(
	(actions$ = inject(Actions)) => {
		return actions$.pipe(
			ofType(textfieldOverlayActions.closeOverlay),
			switchMap(async action => {
				await waitFor(action.timeout || 0);
				return textfieldOverlayActions.closeOverlayType({ overlay: action.overlay });
			})
		);
	},
	{ functional: true }
);
