import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Template } from '@yuno/api/interface';

export const templatesActions = createActionGroup({
	source: 'Templates',
	events: {
		load: props<{ selector: string }>(),
		loadSuccess: props<{ data: Partial<Template>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: props<{ selector: string }>(),

		filter: props<{ selector: string; filter: string }>(),
		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Template> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		delete: props<{ _id: string; selector: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Template> }>(),
		updateSelectSuccess: props<{ data: Partial<Template> }>(),
		updateSelectFailure: props<{ error: Error }>()
	}
});
