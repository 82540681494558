import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import { OpenTextfieldOverlayTypes, Textfield } from '@yuno/api/interface';

import { textfieldOverlayActions } from './textfield-overlays.actions';

export type OverlayTypes = OpenTextfieldOverlayTypes;

export enum TextfieldOverlayStatus {
	PENDING = 'PENDING',
	VALIDATING = 'VALIDATING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export enum TextfieldOverlayState {
	VISIBLE = 'VISIBLE',
	HIDDEN = 'HIDDEN',
	DISABLED = 'DISABLED'
}

export interface TextfieldOverlayEntity {
	id: string;
	textfield: Textfield | undefined;
	status: TextfieldOverlayStatus | undefined;
	state: TextfieldOverlayState | undefined;
	feature: Feature | MapGeoJSONFeature | undefined;
	containers:
		| {
				[key: string]: {
					id: string;
					textfield: Textfield | undefined;
					status: TextfieldOverlayStatus;
					feature: Feature | MapGeoJSONFeature | undefined;
				};
		  }
		| undefined;
}

export interface TextfieldOverlaysState extends EntityState<TextfieldOverlayEntity> {
	selectedId: string | number | undefined; // which Overlays record has been selected
	language: string;
}

export const textfieldOverlaysAdapter: EntityAdapter<TextfieldOverlayEntity> =
	createEntityAdapter<TextfieldOverlayEntity>();

export const initialTextfieldOverlayState: TextfieldOverlaysState =
	textfieldOverlaysAdapter.getInitialState({ selectedId: undefined, language: 'nl' });

const reducer = createReducer(
	initialTextfieldOverlayState,
	on(textfieldOverlayActions.loadTextfieldOverlaySucces, (state, action) => {
		return textfieldOverlaysAdapter.setOne(
			{
				id: action.overlay,
				textfield: action.textfield,
				status: TextfieldOverlayStatus.SUCCESS,
				state: TextfieldOverlayState.VISIBLE,
				feature: action.data,
				containers: undefined
			},
			state
		);
	}),
	on(textfieldOverlayActions.loadTextfieldOverlayFailure, (state, action) => {
		return textfieldOverlaysAdapter.setOne(
			{
				id: action.overlay,
				textfield: undefined,
				status: TextfieldOverlayStatus.FAILED,
				state: TextfieldOverlayState.VISIBLE,
				feature: undefined,
				containers: undefined
			},
			state
		);
	}),
	on(textfieldOverlayActions.closeOverlayType, (state, action) => {
		return textfieldOverlaysAdapter.removeOne(action.overlay, state);
	}),
	on(textfieldOverlayActions.setLanguage, (state, { language }) => ({
		...state,
		language
	})),
	on(textfieldOverlayActions.closeAllOverlays, state => {
		return textfieldOverlaysAdapter.removeAll(state);
	})
);

export const textfieldOverlayFeature = createFeature({
	name: 'textfieldOverlays',
	reducer,
	extraSelectors: ({ selectTextfieldOverlaysState }) => ({
		getTextfieldOverlayStatus: (overlay: OverlayTypes) =>
			createSelector(selectTextfieldOverlaysState, state => state.entities[overlay]?.status),

		getTextfieldOverlayVisibilityState: (overlay: OverlayTypes) =>
			createSelector(selectTextfieldOverlaysState, state => state?.entities[overlay]?.state),

		getTextfieldOverlay: (overlay: OverlayTypes) =>
			createSelector(selectTextfieldOverlaysState, items => {
				return items.entities[overlay];
			})
	})
});
