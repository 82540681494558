import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'curly',
	standalone: true
})
export class CurlyPipe implements PipeTransform {
	transform(value: string | number): string {
		return `{{ ${value} }}`;
	}
}
