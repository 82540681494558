<router-outlet />

@if (data$ | async; as data) {
	<h1 class="font-bold">Marker</h1>
	<yuno-admin-table
		[pagination]="true"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [25, 50, 100],
			hidePageSize: false
		}"
		[selectable]="true"
		[filterable]="true"
		[data]="data.marker"
		[selectData]="data.changes"
		[buttons]="tableButtons"
		[columns]="tableColumns"
		(selected)="onSelect($event)"
		(selectAll)="onSelectAll($event)">
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-button-table (click)="onEdit('markers', row._id)">Edit</button>
</ng-template>
