import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[ifChanges]',
	standalone: true
})
export class IfChangesDirective {
	private currentValue: unknown;
	private hasView = false;

	constructor(
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<unknown>
	) {}

	@Input() set ifChanges(val: unknown) {
		if (!this.hasView) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		}

		if (val !== this.currentValue) {
			this.viewContainer.clear();
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.currentValue = val;
		}
	}
}
