import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest } from 'rxjs';

import { ChooseTemplateComponent, TemplatesFacade } from '@yuno/admin/features/templates';
import { YunoAdminButtonsModule, YunoAdminTableComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Template } from '@yuno/api/interface';

@Component({
	standalone: true,
	imports: [
		YunoCardModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		AsyncPipe,
		ChooseTemplateComponent
	],
	selector: 'yuno-admin-participate-template-view',
	templateUrl: './template-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipateTemplateViewComponent extends AppDataComponent implements OnInit {
	private readonly templateFacade = inject(TemplatesFacade);

	previewPills: string[] = ['No', 'Yes'];
	preview = 'Yes';

	data$ = combineLatest({
		templates: this.templateFacade.templates$
	});

	ngOnInit(): void {
		this.templateFacade.get('participate');
	}

	onSelect(row: Partial<Template>) {
		console.log(this.route);

		this.router.navigate(['template', row._id], {
			relativeTo: this.route,
			queryParamsHandling: 'merge'
		});
	}

	onTemplate() {
		console.log(this.route);
		this.router.navigate(['template', 'create'], {
			relativeTo: this.route,
			queryParamsHandling: 'merge'
		});
	}

	onDelete(row: Partial<Template>): void {
		if (!row._id) {
			return;
		}
		this.templateFacade.delete(row._id, 'participate');
	}
}
