import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-cluster',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, FormsModule, YunoFormsModule],
	templateUrl: './category-cluster.component.html',
	styleUrls: ['./category-cluster.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryClusterComponent {
	service = inject(CategoryEditorService);
	counterValue = 2;
}
