import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, combineLatest, map, tap } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { ThemeFacade } from '@yuno/admin/features/themes';
import { LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	AppUser,
	LanguageType,
	LanguagesArray,
	LanguagesDisplayArray,
	ParticipationCMS,
	ParticipationModel,
	ParticipationPages,
	SafeUser,
	Textfield
} from '@yuno/api/interface';

import { ParticipationModelFacade, ParticipationNotificationFacade } from '../../../data-access';

export interface ParticipationSettingsSelectItems {
	type: string;
	label: string;
	formType: string;
}

export interface ParticipationModelForm {
	id: FormControl<string>;
	_id?: FormControl<string | undefined>;
	theme?: FormControl<string | undefined>;
	notification?: FormControl<string | undefined>;
	notificationLanguage?: FormControl<string | undefined>;
	doubleOptIn?: FormControl<boolean>;
	style?: FormGroup<{
		sprite: FormControl<string | undefined>;
		spriteSelect: FormControl<string | undefined>;
	}>;
	lastpage?: FormGroup<{
		header: FormGroup<LanguageFormType>;
		text: FormGroup<LanguageFormType>;
	}>;
	active: FormControl<boolean>;
	liveUpdate: FormControl<boolean>;
	cms: FormGroup<ParticipationModelCMS>;
	reaction: FormGroup<{
		answerTitle: FormGroup<LanguageFormType>;
		reactionTitle: FormGroup<LanguageFormType>;
	}>;
	pages: FormArray<FormGroup<ParticipationModelPages>>;
	crm: FormGroup<{
		dialog: FormGroup<{
			active: FormControl<boolean>;
			project: FormControl<string>;
			client: FormControl<string>;
			user: FormControl<string>;
		}>;
	}>;
	users?: FormArray<FormGroup<ParticipationModelUser>>;
	textfield?: FormControl<string | undefined>;
}

export interface ParticipationModelCMS {
	listKeys: FormArray<FormControl<string>>;
	reaction: FormGroup<{
		left: FormArray<FormControl<string>>;
		right: FormArray<FormControl<string>>;
	}>;
}

export interface ParticipationModelUser {
	displayName: FormControl<string>;
	email: FormControl<string>;
}

export interface ParticipationModelPages {
	type: FormControl<'text' | 'textfield' | 'location' | 'form' | 'formsend'>;
	data: FormGroup<{
		header: FormGroup<LanguageFormType>;
		text: FormGroup<LanguageFormType>;
		content: FormGroup<LanguageFormType>;
		textMobile: FormGroup<LanguageFormType>;
		required: FormControl<boolean>;
		validationMessage: FormGroup<LanguageFormType>;
	}>;
	privacyStatement: FormGroup<{
		active: FormControl<boolean>;
		content: FormControl<string>;
		color: FormControl<string>;
	}>;
	textfield: FormGroup<{
		content: FormGroup<LanguageFormType>;
	}>;
	form: FormArray<FormGroup<ParticipationForm>>;
}

export interface ParticipationForm {
	controlType: FormControl<string>;
	type: FormControl<string>;
	key: FormControl<string>;
	label: FormGroup<LanguageFormType>;
	selectValues: FormArray<FormGroup<LanguageFormType>>;
	placeholder: FormGroup<LanguageFormType>;
	required: FormControl<boolean>;
	validationMessage: FormGroup<LanguageFormType>;
}

const tabList = [
	'Settings',
	'Reaction',
	'Style',
	'Steps',
	'Viewer',
	'Notifications',
	'Users',
	'CRM'
] as const;
export type TabOptions = (typeof tabList)[number];
const tabs: TabOptions[] = [...tabList];

@Injectable({
	providedIn: 'root'
})
export class ParticipationSettingsEditorService {
	readonly environment = inject(ENVIRONMENT);
	private readonly facade = inject(ParticipationModelFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly notificationFacade = inject(ParticipationNotificationFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly datasetFacade = inject(DatasetsFacade);

	submit = false;

	tabs = tabs;
	tabValue: TabOptions = 'Settings';

	originalData?: Partial<ParticipationModel>;

	form: FormGroup<ParticipationModelForm>;
	language: LanguageType;

	pageTypesSelect = ['textfield', 'location', 'form', 'formsend'];
	pageTypesDisplay = ['Textfield', 'Location', 'Form', 'Send'];

	themeSelect: string[] = [];
	themeDisplay: string[] = [];

	$textfieldValues: WritableSignal<{ [key: string]: string }> = signal({});

	notificationSelect: string[] = [];
	notificationDisplay: string[] = [];

	languageSelect = LanguagesArray;
	languageDisplay = LanguagesDisplayArray;

	inputFields: ParticipationSettingsSelectItems[] = [
		{ type: 'name', label: 'Name', formType: 'text' },
		{ type: 'email', label: 'Email', formType: 'email' },
		{ type: 'postalcode', label: 'Postalcode', formType: 'text' },
		{ type: 'involvement', label: 'Involvement', formType: 'select' },
		{ type: 'about', label: 'About', formType: 'select' },
		{ type: 'theme', label: 'Theme', formType: 'select' },
		{ type: 'content', label: 'Content', formType: 'textarea' }
	];
	customFields: ParticipationSettingsSelectItems[] = [
		{ type: 'paragraph', label: 'Paragraph', formType: 'paragraph' }
	];
	customInputFields: ParticipationSettingsSelectItems[] = [
		{ type: 'textarea', label: 'Text area', formType: 'textarea' },
		{ type: 'textfield', label: 'Text field', formType: 'text' },
		{ type: 'select', label: 'Select', formType: 'select' },
		{ type: 'checkbox', label: 'Checkbox', formType: 'checkbox' },
		{ type: 'toggle', label: 'Toggle', formType: 'toggle' }
	];
	protectedInputFieldKeys = this.inputFields.map(val => val.type);

	rawForm$ = new ReplaySubject<Partial<ParticipationModel>>(1);

	get pages(): FormArray<FormGroup<ParticipationModelPages>> {
		return this.form.get('pages') as FormArray<FormGroup<ParticipationModelPages>>;
	}

	get getListKeys(): FormArray<FormControl<string>> {
		return this.form.controls.cms.controls.listKeys as FormArray<FormControl<string>>;
	}

	get getLeftKeys() {
		return this.form.controls.cms.controls.reaction.controls.left as FormArray<
			FormControl<string>
		>;
	}

	get getRightKeys() {
		return this.form.controls.cms.controls.reaction.controls.right as FormArray<
			FormControl<string>
		>;
	}

	get getSprite(): FormControl<string | undefined> {
		return this.form.controls.style?.get('sprite') as FormControl<string | undefined>;
	}

	get getSpriteSelected(): FormControl<string | undefined> {
		return this.form.controls.style?.get('spriteSelect') as FormControl<string | undefined>;
	}

	get getUsers(): FormArray<FormGroup<ParticipationModelUser>> {
		return this.form.get('users') as FormArray<FormGroup<ParticipationModelUser>>;
	}

	/**
		Returns an array of string keys corresponding to the form fields of all Pages inside this Participation.

		This getter method uses the flatmap() method to create a flattend array of all the pages
		It then uses the reduce() function to iterate through the array of form data objects and extract the "key" property
		from each object if it exists. The extracted "key" values are added to an accumulator array and returned as the final output.

		@returns An array of string keys corresponding to the form fields of the all participation pages.
	 */
	get listOfKeys(): string[] {
		const objArray = this.pages.getRawValue();
		const keys = objArray
			.flatMap(obj => obj.form)
			.reduce((acc: string[], form) => {
				if (form && form.key) {
					acc.push(form.key);
				}
				return acc;
			}, []);

		return keys;
	}

	// All data needed as Observable
	data$ = combineLatest({
		data: this.facade.selected$.pipe(
			tap(data => {
				if (this.originalData || !data) {
					return;
				}
				this.originalData = data;

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.form.patchValue(this.originalData as any);

				this.createPages(data?.pages);
				this.createCms(data?.cms);
				this.createUsers(data?.users);
			})
		),
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data) {
					this.$textfieldValues.set({
						none: '',
						...this.convertTextfieldArrayToObject(data)
					});
				}
			})
		),
		users: this.appFacade.users$.pipe(
			map(users => {
				return (users ?? [])
					.filter(
						user =>
							user.role &&
							!['ATLAS', 'ATLASINTERNAL'].includes(user?.role) &&
							user?.mailing?.participation
					)
					.map((user: Partial<AppUser>) => {
						return {
							...user.user
						} as Partial<SafeUser>;
					});
			})
		),
		themes: this.themeFacade.themes$.pipe(
			tap(data => {
				if (!data.length) {
					return;
				}

				this.themeSelect = data
					.filter(theme => theme._id)
					.map(theme => theme._id) as string[];
				this.themeDisplay = data
					.filter(theme => theme.id)
					.map(theme => theme.id) as string[];
			})
		),
		notification: this.notificationFacade.data$.pipe(
			tap(data => {
				if (!data.length) {
					return;
				}

				this.notificationSelect = data.filter(d => d._id).map(d => d._id) as string[];
				this.notificationDisplay = data.filter(d => d.id).map(d => d.id) as string[];
			})
		)
	});

	init(): void {
		this.textfieldFacade.get();
		this.notificationFacade.get();
		this.themeFacade.get();
		this.appFacade.getAppUsers();

		this.createFormGroup();
	}

	createFormGroup(): void {
		this.form = new FormGroup<ParticipationModelForm>({
			_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			theme: new FormControl(undefined, { nonNullable: true }),
			notification: new FormControl(undefined, { nonNullable: true }),
			notificationLanguage: new FormControl(this.language, { nonNullable: true }),
			doubleOptIn: new FormControl(false, { nonNullable: true }),
			style: new FormGroup({
				sprite: new FormControl<string | undefined>(
					`${this.environment['yuno-cdn']}/sprites/public/participation-complete.svg`,
					{ nonNullable: true }
				),
				spriteSelect: new FormControl<string | undefined>(
					`${this.environment['yuno-cdn']}/sprites/public/participation-complete-active.svg`,
					{ nonNullable: true }
				)
			}),
			lastpage: new FormGroup({
				header: newLanguageFormGroup(),
				text: newLanguageFormGroup()
			}),
			active: new FormControl(true, { nonNullable: true }),
			liveUpdate: new FormControl(false, { nonNullable: true }),
			cms: new FormGroup<ParticipationModelCMS>({
				listKeys: new FormArray<FormControl<string>>([]),
				reaction: new FormGroup({
					left: new FormArray<FormControl<string>>([]),
					right: new FormArray<FormControl<string>>([])
				})
			}),
			reaction: new FormGroup({
				answerTitle: newLanguageFormGroup(),
				reactionTitle: newLanguageFormGroup()
			}),
			pages: new FormArray<FormGroup<ParticipationModelPages>>([]),
			crm: new FormGroup({
				dialog: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					project: new FormControl('', { nonNullable: true }),
					client: new FormControl('', { nonNullable: true }),
					user: new FormControl('', { nonNullable: true })
				})
			}),
			users: new FormArray<FormGroup<ParticipationModelUser>>([]),
			textfield: new FormControl(undefined, { nonNullable: true })
		});
	}

	// Adds all Controls to the CMS object and it's Array's
	createCms(cms?: ParticipationCMS): void {
		if (!cms) {
			return;
		}

		for (const key of cms.listKeys || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getListKeys.push(newControl);
		}

		for (const key of cms.reaction?.left || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getLeftKeys.push(newControl);
		}

		for (const key of cms.reaction?.right || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getRightKeys.push(newControl);
		}
	}

	// Creates all the Pages with all controls
	createPages(pages?: ParticipationPages[]): void {
		if (!pages) {
			return;
		}

		for (const page of pages) {
			const newPage = new FormGroup<ParticipationModelPages>({
				type: new FormControl('text', { nonNullable: true }),
				data: new FormGroup({
					header: newLanguageFormGroup(),
					text: newLanguageFormGroup(),
					content: newLanguageFormGroup(),
					textMobile: newLanguageFormGroup(),
					required: new FormControl(true, { nonNullable: true }),
					validationMessage: newLanguageFormGroup()
				}),
				privacyStatement: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					content: new FormControl('', { nonNullable: true }),
					color: new FormControl('#1775c7', { nonNullable: true })
				}),
				textfield: new FormGroup({
					content: newLanguageFormGroup()
				}),
				form: new FormArray<FormGroup<ParticipationForm>>(this.createPageFormdata(page))
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newPage.patchValue(page as any);
			this.pages.push(newPage);
		}
	}

	addNewPage(): void {
		const newPage = new FormGroup<ParticipationModelPages>({
			type: new FormControl('text', { nonNullable: true }),
			data: new FormGroup({
				header: newLanguageFormGroup(),
				text: newLanguageFormGroup(),
				content: newLanguageFormGroup(),
				textMobile: newLanguageFormGroup(),
				required: new FormControl(true, { nonNullable: true }),
				validationMessage: newLanguageFormGroup()
			}),
			privacyStatement: new FormGroup({
				active: new FormControl(false, { nonNullable: true }),
				content: new FormControl('', { nonNullable: true }),
				color: new FormControl('#1775c7', { nonNullable: true })
			}),
			textfield: new FormGroup({
				content: newLanguageFormGroup()
			}),
			form: new FormArray<FormGroup<ParticipationForm>>([])
		});

		this.pages.push(newPage);
	}

	// Creates a array with all FormGroups required
	// to populate the existing data
	createPageFormdata(page: ParticipationPages): FormGroup<ParticipationForm>[] {
		if (!page.form) {
			return [];
		}

		const arr: FormGroup<ParticipationForm>[] = [];
		for (const data of page.form) {
			const form = new FormGroup<ParticipationForm>({
				controlType: new FormControl('control', { nonNullable: true }),
				type: new FormControl('', { nonNullable: true }),
				key: new FormControl(
					{
						value: data.key,
						disabled: this.protectedInputFieldKeys.includes(data.key)
					},
					{ nonNullable: true }
				),
				label: newLanguageFormGroup(),
				selectValues: new FormArray<FormGroup<LanguageFormType>>([]),
				placeholder: newLanguageFormGroup(),
				required: new FormControl(false, { nonNullable: true }),
				validationMessage: newLanguageFormGroup()
			});

			// First Patch The Value
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			form.patchValue(data as any);

			// Force Paragraph to be required false
			if (data.type === 'paragraph') {
				form.patchValue({
					required: false
				});
			}

			// Now we can create the option keys
			if (data.selectValues) {
				for (const option of data.selectValues) {
					// const newControl = new FormControl(option, { nonNullable: true });
					const newControl = newLanguageFormGroup();
					newControl.patchValue(option as any);
					(form.get('selectValues') as FormArray).push(newControl);
				}
			}

			arr.push(form);
		}

		return arr;
	}

	// Add Item to PageArray
	addItemToPageArray(
		page: ParticipationModelPages,
		type: string,
		key: string,
		label: string
	): void {
		if (!page.form) {
			return;
		}

		const form = new FormGroup<ParticipationForm>({
			controlType: new FormControl('control', { nonNullable: true }),
			type: new FormControl(type, { nonNullable: true }),
			key: new FormControl(
				{ value: key, disabled: this.protectedInputFieldKeys.includes(key) },
				{ nonNullable: true }
			),
			label: newLanguageFormGroup(),
			selectValues: new FormArray<FormGroup<LanguageFormType>>([]),
			placeholder: newLanguageFormGroup(),
			required: new FormControl(false, { nonNullable: true }),
			validationMessage: newLanguageFormGroup()
		});

		// Create empty Select
		if (type === 'select') {
			// const newControl = new FormControl('', { nonNullable: true });
			const newControl = newLanguageFormGroup();
			(form.get('selectValues') as FormArray).push(newControl);
		}

		page.form.push(form);
	}

	getActivateParticipate(index: number): boolean {
		const page = this.pages.controls[index] as FormGroup<ParticipationModelPages>;
		if (!page) {
			return false;
		}

		return !!page.value?.privacyStatement?.active;
	}

	// Add Item to PageArray
	removeItemFromPageArra(page: ParticipationModelPages, index: number): void {
		page.form.removeAt(index);
	}

	createUsers(users?: SafeUser[]) {
		if (!users) {
			return;
		}

		for (const user of users) {
			const formGroup = new FormGroup<ParticipationModelUser>({
				displayName: new FormControl<string>(user.displayName || '', {
					nonNullable: true
				}),
				email: new FormControl<string>(user.email || '', {
					nonNullable: true
				})
			});
			this.getUsers.push(formGroup);
		}
	}

	selectUser(user: Partial<SafeUser>, checked: boolean) {
		if (checked) {
			// Check if the user already exists in the FormArray to prevent duplicates
			const existingUserIndex = this.getUsers.controls.findIndex(
				(control: FormGroup) => control.value.email === user.email
			);

			if (existingUserIndex === -1) {
				// Add new user as FormGroup
				const newUser = new FormGroup<ParticipationModelUser>({
					displayName: new FormControl<string>(user.displayName || '', {
						nonNullable: true
					}),
					email: new FormControl<string>(user.email || '', {
						nonNullable: true
					})
				});
				this.getUsers.push(newUser);
			}
		} else {
			// Remove the user from the FormArray based on email
			const userIndex = this.getUsers.controls.findIndex(
				(control: FormGroup) => control.value.email === user.email
			);

			if (userIndex !== -1) {
				this.getUsers.removeAt(userIndex);
			}
		}
	}

	// Example function to perform the conversion
	convertTextfieldArrayToObject(textfields: Partial<Textfield>[]): Record<string, string> {
		return textfields.reduce(
			(acc, textfield) => {
				if (textfield._id) {
					acc[textfield._id] = textfield.id as string;
				}
				return acc;
			},
			{} as Record<string, string>
		);
	}

	getParentRoute(ids: string[]): void {
		this.datasetFacade.getParentRoute(ids);
	}
}
