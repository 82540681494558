module.exports = function walk(nodes, cb, bubble) {
  var i, max, node, result;
  for (i = 0, max = nodes.length; i < max; i += 1) {
    node = nodes[i];
    if (!bubble) {
      result = cb(node, i, nodes);
    }
    if (result !== false && node.type === "function" && Array.isArray(node.nodes)) {
      walk(node.nodes, cb, bubble);
    }
    if (bubble) {
      cb(node, i, nodes);
    }
  }
};