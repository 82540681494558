<section class="grid grid-cols-1 gap-4">
	<yuno-forms-title>Email List</yuno-forms-title>
	<yuno-forms-span>
		Here you can add or remove users from the mailing list. Can't see an User that is added to
		the Project? Check the User Configuration to see if the Mailing toggle is turned on.
	</yuno-forms-span>
	@if (data$ | async; as data) {
		@if (users) {
			<div class="flex flex-col">
				<yuno-admin-table
					class="tabled-fixed"
					[selectable]="true"
					[draggable]="false"
					[sortable]="true"
					[pagination]="false"
					[customSorting]="customSorter"
					[defaultColumn]="'email'"
					[pageOptions]="{
						pageSize: 25,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="users"
					[itemKey]="'email'"
					[selectData]="data.changes"
					(selected)="onSelect($event)"
					(selectAll)="onSelectAll($event)"
					[columns]="columns">
				</yuno-admin-table>
			</div>
		}
	}
</section>
