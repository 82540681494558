<div class="grid grid-cols-1 gap-4">
	<yuno-forms-title>Standard input fields</yuno-forms-title>
	<yuno-forms-span>
		these values can only be added once, and can be used in the Styling options
	</yuno-forms-span>
	<div class="mt-4 grid grid-cols-2 gap-2">
		@for (item of inputFields; track item) {
			<yuno-select-field
				[display]="item.label | languageSelect: 'en'"
				[disabled]="compareInput(item.type)"
				(clicked)="onSelect(item)"></yuno-select-field>
		}
	</div>
	<yuno-forms-title class="mt-4">Custom input fields</yuno-forms-title>
	<yuno-forms-span>
		these values can be added multiple times, and cannot be used in the Styling options
	</yuno-forms-span>
	<div class="mt-4 grid grid-cols-2 gap-2">
		@for (item of customInputFields; track item) {
			<yuno-select-field
				[display]="item.label | languageSelect: 'en'"
				(clicked)="onSelectCustom(item)"></yuno-select-field>
		}
	</div>
</div>
