import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';

import { ParticipationModelForm } from '../../../settings-editor.service';

type svgType = 'default' | 'selected';

@Component({
	selector: 'yuno-admin-participation-svg-select-container',
	standalone: true,
	imports: [
		CommonModule,
		YunoFormsModule,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		YunoAdminButtonComponent
	],
	templateUrl: './svg-select-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgSelectContainerComponent {
	@Input() form: FormGroup<ParticipationModelForm>;

	@Input() selected: svgType;
	@Output() selectedChange = new EventEmitter<svgType>();

	@Input() type: svgType = 'default';
	@Input() src: string | undefined;

	select(): void {
		this.selected = this.type;
		this.selectedChange.emit(this.selected);
	}
}
