@if (form && data$ | async; as data) {
	<form [formGroup]="form">
		<section class="sprite-container mt-8" formGroupName="style">
			<!-- Different Sprites -->
			<yuno-admin-participation-svg-select-container
				[form]="form"
				type="default"
				[(selected)]="selectSprite"
				[src]="service.getSprite.value">
				Default Sprite
			</yuno-admin-participation-svg-select-container>
			<yuno-admin-participation-svg-select-container
				[form]="form"
				type="selected"
				[(selected)]="selectSprite"
				[src]="service.getSpriteSelected.value">
				Selected Sprite
			</yuno-admin-participation-svg-select-container>
			<!-- Sprite Manager -->
			<div class="sprite-manager flex flex-col gap-2">
				@if (selectSprite) {
					@if (data.cliendId && data.appId) {
						<yuno-admin-spritesheet-manager
							[disableCopy]="true"
							[clientId]="data.cliendId"
							[appId]="data.appId"
							(selectIcon)="selectIcon($event)"></yuno-admin-spritesheet-manager>
					}
				}
			</div>
		</section>
	</form>
}
