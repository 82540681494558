import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';

import { ParticipationModelEditorsDefaultComponent } from '../default.component';
import { SvgSelectContainerComponent } from './svg-select-container/svg-select-container.component';

@Component({
	selector: 'yuno-admin-participation-model-style',
	standalone: true,
	imports: [
		YunoFormsModule,
		YunoAdminButtonComponent,
		SpritesheetModule,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		SvgSelectContainerComponent,
		AsyncPipe
	],
	templateUrl: './style.component.html',
	styleUrls: ['./style.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorStyleComponent extends ParticipationModelEditorsDefaultComponent {
	private appFacade = inject(AppFacade);

	selectSprite: 'default' | 'selected' = 'default';

	data$ = combineLatest({
		cliendId: this.appFacade.clientId$,
		appId: this.appFacade.appId$
	});

	selectIcon(event: string): void {
		this.selectSprite === 'default'
			? this.service.getSprite.patchValue(event)
			: this.service.getSpriteSelected.patchValue(event);
	}
}
