import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminDeleteAdminButtonComponent
} from '@yuno/admin/ui';
import { YunoFormsModule, moveItemInFormArray, transferFormArrayItem } from '@yuno/angular/forms';

import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-model-viewer',
	standalone: true,
	imports: [
		YunoFormsModule,
		ReactiveFormsModule,
		DragDropModule,
		DragRowComponent,
		YunoAdminButtonComponent,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './viewer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorViewerComponent {
	@Input() form: FormGroup<ParticipationModelForm>;

	constructor(public service: ParticipationSettingsEditorService) {}

	/**
	 * Moves or adds a FormControl between two positions within a FormArray when a key is dropped onto it.
	 * When the key is dropped onto the same container, the item is moved within the container using the moveItemInFormArray function.
	 * When the key is dropped onto a different container, the item is added to the form at the specified index, if the event.previousContainer
	 * has a .data property, using a new FormControl initialized with the dropped key value.
	 *
	 * @param event The CdkDragDrop event object.
	 * @param form The FormArray on which to perform the move or add operation.
	 */
	async dropListKeys(event: CdkDragDrop<string[]>, form: FormArray): Promise<void> {
		if (event.previousContainer === event.container) {
			moveItemInFormArray(form, event.previousIndex, event.currentIndex);
		}

		// when the previousContainer has .data
		// we assume it is the list of all available keys
		// and add that key to the targetArr
		if (event.previousContainer.data) {
			const control = new FormControl<string>(
				event.previousContainer.data[event.previousIndex],
				{ nonNullable: true }
			);
			form.insert(event.currentIndex, control);
			return;
		}
	}

	/**
	 * Moves or transfers a FormGroup or FormControl between two FormArrays when a key is dropped onto it.
	 * When the key is dropped onto the same container, the item is moved within the container using the moveItemInFormArray function.
	 * When the key is dropped onto a different container, the item is either added to the targetArr if the event.previousContainer
	 * has a .data property, or transferred between the arr and targetArr using the transferFormArrayItem function.
	 *
	 * @param event The CdkDragDrop event object.
	 * @param arr The FormArray from which to transfer the item.
	 * @param targetArr The FormArray to which to transfer the item.
	 */
	async dropReactionKey(
		event: CdkDragDrop<string[]>,
		arr: FormArray,
		targetArr: FormArray
	): Promise<void> {
		// When it is the same container we move the item
		if (event.previousContainer === event.container) {
			moveItemInFormArray(targetArr, event.previousIndex, event.currentIndex);
			return;
		}

		// when the previousContainer has .data
		// we assume it is the list of all available keys
		// and add that key to the targetArr
		if (event.previousContainer.data) {
			const control = new FormControl<string>(
				event.previousContainer.data[event.previousIndex],
				{ nonNullable: true }
			);
			targetArr.insert(event.currentIndex, control);
			return;
		}

		// else we transfer between left and right
		transferFormArrayItem(arr, targetArr, event.previousIndex, event.currentIndex);
	}

	/**
	 * Removes an item from one of the FormArray instances (getListKeys, getLeftKeys, or getRightKeys) of the current component's Service
	 * based on the id attribute of the CdkDropList instance from which the item was dragged (listKeys, listLeft, or listRight, respectively).
	 *
	 * @param event The CdkDragDrop event object.
	 */
	async dropAvailable(event: CdkDragDrop<string[]>): Promise<void> {
		const id = event.previousContainer.element.nativeElement.getAttribute('id');

		switch (id) {
			case 'listKeys':
				this.service.getListKeys.removeAt(event.previousIndex);
				break;
			case 'listLeft':
				this.service.getLeftKeys.removeAt(event.previousIndex);
				break;
			case 'listRight':
				this.service.getRightKeys.removeAt(event.previousIndex);
				break;
		}
	}

	/**
	 * Removes an item from the FormArray instance getListKeys of the current component's Service.
	 * @param index The index of the item to be removed.
	 */
	removeListKey(index: number): void {
		this.service.getListKeys.removeAt(index);
	}

	/**
	 * Removes an item from the FormArray instance getLeftKeys of the current component's Service.
	 * @param index The index of the item to be removed.
	 */
	removeLeftKey(index: number): void {
		this.service.getLeftKeys.removeAt(index);
	}

	/**
	 * Removes an item from the FormArray instance getRightKeys of the current component's Service.
	 * @param index The index of the item to be removed.
	 */
	removeRightKey(index: number): void {
		this.service.getRightKeys.removeAt(index);
	}
}
