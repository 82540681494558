import { gql } from 'apollo-angular';

export const GET_CLIENT_APP_AND_CONFIG = gql`
	query getAppWithConfig($appId: String!) {
		clientByAppId(appId: $appId) {
			_id
			id
			url
		}
		appById(appId: $appId) {
			_id
			id
			url
			client {
				_id
				id
			}
			trackingId
			public
		}
		configByAppId(appId: $appId) {
			_id
			title
			customTitle
			path
			language
			languages
			epsg

			interface {
				mapOnly
				controlZoom
				navbarType
				embedType

				fullscreenButton
				fullscreenPopup
				embedFullscreenPopup
				buttonStyle

				help {
					mobile
					desktop
				}
			}

			help {
				textfields {
					mobile
					desktop
				}
			}
			colors {
				navbar {
					background
					font
					active
					activeFont
					underline
				}
				embedNavbar {
					background
					font
					active
					activeFont
					underline
				}
				states {
					background
					font
				}
				panoramas
				panoramasClusterCount
				additional
				button
				buttonFont
				textHeading
				textLink
				locationMarker
				measure
			}
		}
	}
`;

export const GET_APP_USERS = gql`
	query getAppUsers($appId: String!) {
		appUsers(appId: $appId) {
			id
			role
			mailing {
				participation
			}
			user {
				displayName
				email
			}
			variants {
				_id
				id
			}
		}
	}
`;
