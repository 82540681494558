@if (form) {
	<form [formGroup]="form">
		@if (language$ | async; as language) {
			<section class="mt-8 grid grid-cols-2 gap-2" formGroupName="reaction">
				<section formGroupName="reactionTitle">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								placeholder="Question"
								label="Reaction Title" />
						}
					}
				</section>
				<section formGroupName="answerTitle">
					@for (lang of languages; track lang) {
						@if (lang === language) {
							<yuno-forms-text
								class="col-span-1"
								[language]="lang"
								[formControlName]="lang"
								label="Answer Title"
								placeholder="Answer" />
						}
					}
				</section>
			</section>
		}

		<section class="mt-6 flex flex-col gap-4">
			<span class="text-sm font-semibold">Textfield</span>
			<yuno-admin-search-bar
				[debounceTime]="100"
				[minimumLength]="0"
				(searches)="applyFilter($event)" />

			<yuno-forms-select
				formControlName="textfield"
				[placeholder]="'none'"
				[selectValues]="$filteredKeys()"
				[display]="$filteredValues()" />

			<yuno-admin-annotation>
				<p>
					When using a Textfield you will be able to provide the Keys as options to
					display. Like this:
					<span>
						{{ 'data.content' | curly }}
						or
						{{ 'answer.content' | curly }}
					</span>
				</p>
				<p>
					Excluding these keys:
					<span class="font-semibold">postalcode, postcode, name, email</span>
				</p>
				<p>
					All Keys are selectable by using {{ 'data.key' | curly }} and the answer given
					is available using {{ 'answer.content' | curly }}
				</p>
			</yuno-admin-annotation>
		</section>
	</form>
}
