<yuno-edit-container [col]="6" [pos]="1" [span]="6">
	<ng-container actions>
		<button yuno-admin-button color="primary" (click)="addNewPageInput()" [add]="true">
			New input field
		</button>
	</ng-container>
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClosePage()"></button>
	</ng-container>
	<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2">
		<div edit-container-content>
			@if (form) {
				<form [formGroup]="form">
					<div class="grid gap-2" formArrayName="pages">
						@if (service.pages.controls[selectedPages].value; as pageData) {
							<div [formGroupName]="selectedPages">
								<h1>{{ pageData.data?.header | languageSelect: language }}</h1>
								<!-- When Location -->
								@if (currentType === 'location') {
									<div>
										<div formGroupName="data" class="grid gap-2">
											<span class="font-semibold">Textfield</span>
											<div
												class="rounded-md border-2 bg-white p-4"
												[innerHTML]="
													pageData.data?.text
														| languageSelect: language
														| safeHtml
												"></div>
											<span class="font-semibold">Mobile Textfield</span>
											<div
												class="rounded-md border-2 bg-white p-4"
												[innerHTML]="
													pageData.data?.textMobile
														| languageSelect: language
														| safeHtml
												"></div>
										</div>
									</div>
								}
								<!-- When Textfield -->
								@if (currentType === 'textfield') {
									<div>
										<div
											class="rounded-md border-2 bg-white p-4"
											[innerHTML]="
												pageData.textfield?.content
													| languageSelect: language
													| safeHtml
											"></div>
									</div>
								}
								<!-- When Form / Formsend -->
								@if (currentType.includes('form')) {
									<div>
										<div
											cdkDropList
											(cdkDropListDropped)="drop($event)"
											formGroupName="form"
											class="grid gap-2">
											@for (
												data of service.pages.controls[selectedPages]
													.controls.form.controls;
												track data;
												let i = $index
											) {
												<div cdkDrag cdkDragLockAxis="y">
													<div [formGroupName]="i">
														<yuno-card-draggable
															(click)="onEditControl(data)"
															[header]="false"
															[buttons]="buttons"
															(outputBtn)="
																$event.type === 'delete' &&
																	removeItem(i)
															">
															<ng-container popup>
																{{ data.getRawValue().key }}
															</ng-container>
															<ng-container content>
																@switch (
																	data.value.type &&
																	[
																		'textfield',
																		'paragraph'
																	].includes(data.value.type)
																) {
																	@case (true) {
																		<p
																			[innerHtml]="
																				data?.value?.label
																					| languageSelect
																						: language
																					| safeHtml
																			"></p>
																	}
																	@default {
																		<yuno-form-control-selector
																			formControlName="key"
																			class="pointer-events-none w-full"
																			[key]="data.value.type"
																			[label]="
																				data?.value?.label
																					| languageSelect
																						: language
																			"
																			[placeholder]="
																				data?.value
																					?.placeholder
																					| languageSelect
																						: language
																			"
																			[selectValues]="
																				selectValuesLanguage(
																					language,
																					data?.value
																						?.selectValues
																				)
																			"
																			[display]="
																				selectValuesLanguage(
																					language,
																					data?.value
																						?.selectValues
																				)
																			">
																		</yuno-form-control-selector>
																	}
																}
															</ng-container>
														</yuno-card-draggable>
													</div>
												</div>
											}
											@if (pageData.privacyStatement?.active) {
												<div
													class="overflow-hidden rounded-md bg-white p-4">
													<yuno-project-atlas-link
														[color]="
															pageData.privacyStatement?.color ||
															'#1775c7'
														">
														Privacy statement Project Atlas
													</yuno-project-atlas-link>
													<yuno-project-atlas-link
														[color]="
															pageData.privacyStatement?.color ||
															'#1775c7'
														">
														Privacy statement Project
														<span class="text-xs"
															>(*when available)</span
														>
													</yuno-project-atlas-link>
												</div>
											}
										</div>
									</div>
								}
							</div>
						}
					</div>
				</form>
			}
		</div>

		<!--  -->
		<div edit-container-content>
			@if (form) {
				<form [formGroup]="form">
					<!-- Header / Type editor -->
					<div formArrayName="pages">
						<div class="grid grid-cols-4 gap-2" [formGroupName]="selectedPages">
							<div formGroupName="data" class="col-span-3">
								<section formGroupName="header">
									@for (lang of languages; track lang) {
										@if (lang === language) {
											<yuno-forms-text
												[language]="lang"
												[formControlName]="lang"
												label="title"
												placeholder="Add title text here"></yuno-forms-text>
										}
									}
								</section>
							</div>
							<yuno-forms-select
								label="type"
								formControlName="type"
								[selectValues]="service.pageTypesSelect"
								[display]="service.pageTypesDisplay"></yuno-forms-select>
							<div formGroupName="privacyStatement" class="col-span-4">
								<yuno-forms-toggle
									formControlName="active"
									labelPos="side"
									label="Add privacy statements to this page">
								</yuno-forms-toggle>
							</div>
							@if (getActivateParticipate(selectedPages)) {
								<div
									formGroupName="privacyStatement"
									class="col-span-4 md:col-span-2">
									<yuno-forms-color
										formControlName="color"
										labelPos="side"
										label="Color of the privacy statement urls">
									</yuno-forms-color>
								</div>
							}
						</div>
						<yuno-forms-divider></yuno-forms-divider>
						<!-- Page specifics -->
						@if (service.pages.controls[selectedPages].value; as pageData) {
							<ng-container [formGroupName]="selectedPages">
								<!-- When Location -->
								@if (currentType === 'location') {
									<div>
										<div formGroupName="data" class="grid gap-2">
											<section formGroupName="text">
												@for (lang of languages; track lang) {
													@if (lang === language) {
														<yuno-forms-html-image-library
															[language]="lang"
															[formControlName]="lang"
															label="text"></yuno-forms-html-image-library>
													}
												}
											</section>
											<section formGroupName="textMobile">
												@for (lang of languages; track lang) {
													@if (lang === language) {
														<yuno-forms-html-image-library
															[language]="lang"
															[formControlName]="lang"
															label="mobile text"></yuno-forms-html-image-library>
													}
												}
											</section>
											<yuno-forms-toggle
												formControlName="required"
												label="required"></yuno-forms-toggle>
											<section formGroupName="validationMessage">
												@for (lang of languages; track lang) {
													@if (lang === language) {
														<yuno-forms-html-image-library
															[language]="lang"
															[formControlName]="lang"
															label="warning"></yuno-forms-html-image-library>
													}
												}
											</section>
										</div>
									</div>
								}
								<!-- When Textfield -->
								@if (currentType === 'textfield') {
									<div>
										<div formGroupName="textfield" class="grid gap-2">
											<section formGroupName="content">
												@for (lang of languages; track lang) {
													@if (lang === language) {
														<yuno-forms-html-image-library
															[language]="lang"
															[formControlName]="lang"
															label="text"></yuno-forms-html-image-library>
													}
												}
											</section>
										</div>
									</div>
								}
							</ng-container>
						}
						<!--
			  When Form / Formsend
			  we can add new Input Fields
			  -->
						@if (currentType.includes('form') && !editInput) {
							<div>
								<yuno-forms-title>Input fields</yuno-forms-title>
								<yuno-forms-span>What would you like to add?</yuno-forms-span>
								<div class="mt-6 grid grid-cols-2 gap-2">
									<div class="grid gap-1">
										<yuno-forms-span class="font-semibold">
											Fields
										</yuno-forms-span>
										@for (item of service.customFields; track item) {
											<yuno-select-field
												[display]="item.label ? item.label : ''"
												[disabled]="compareInput(item.type)"
												(clicked)="
													onAddInputField(item)
												"></yuno-select-field>
										}
									</div>
									<div class="grid gap-1"></div>
									<div class="grid gap-1">
										<yuno-forms-span class="font-semibold">
											Standard input fields
										</yuno-forms-span>
										@for (item of service.inputFields; track item) {
											<yuno-select-field
												[display]="item.label ? item.label : ''"
												[disabled]="compareInput(item.type)"
												(clicked)="
													onAddInputField(item)
												"></yuno-select-field>
										}
									</div>
									<div class="grid content-start gap-1">
										<yuno-forms-span class="font-semibold">
											Custom input fields
										</yuno-forms-span>
										@for (item of service.customInputFields; track item) {
											<yuno-select-field
												[display]="item.label ? item.label : ''"
												(clicked)="
													onAddInputField(item)
												"></yuno-select-field>
										}
									</div>
								</div>
							</div>
						}
						<!--
			  FormEdit Input
			  -->
						@if (editInput) {
							<yuno-admin-participation-steps-page-input-editor
								[language]="language"
								[data]="
									editInput
								"></yuno-admin-participation-steps-page-input-editor>
						}
					</div>
				</form>
			}
		</div>
	</div>
</yuno-edit-container>
