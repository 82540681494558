@if (data$ | async; as data) {
	<yuno-edit-container
		[popup]="isPopup"
		[col]="4"
		[pos]="1"
		[span]="4"
		[tabs]="['Settings', 'Inputs', 'Styling', 'Cluster']"
		(outputTab)="onSwitchTab($event.key)">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>

		<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2">
			<div edit-container-content [hasTabs]="true">
				@if (tabValue === 'Settings') {
					<yuno-admin-category-settings></yuno-admin-category-settings>
				}
				@if (tabValue === 'Inputs') {
					<yuno-admin-list-category-inputs
						[language]="data.language"
						(openInputEditor)="inputList = false; inputEditor = true"
						(openInputList)="inputList = true; inputEditor = false">
					</yuno-admin-list-category-inputs>
				}
				@if (tabValue === 'Styling') {
					<yuno-admin-list-category-style
						(openStyleEditor)="styleEditor = true; rulesEditor = false"
						(openRulesEditor)="styleEditor = false; rulesEditor = true">
					</yuno-admin-list-category-style>
				}
				@if (tabValue === 'Cluster') {
					<yuno-admin-list-category-cluster></yuno-admin-list-category-cluster>
				}
			</div>
			<div edit-container-content>
				@if (tabValue === 'Inputs') {
					@if (inputList) {
						<yuno-admin-category-input-editor></yuno-admin-category-input-editor>
					}
					@if (inputEditor) {
						<yuno-admin-category-input-field-editor
							[language]="data.language"></yuno-admin-category-input-field-editor>
					}
				}
				@if (tabValue === 'Styling') {
					<yuno-admin-category-styling></yuno-admin-category-styling>
					@if (styleEditor) {
						<yuno-admin-category-style-editor></yuno-admin-category-style-editor>
					}
					@if (rulesEditor) {
						<yuno-admin-category-styling-rules></yuno-admin-category-styling-rules>
					}
				}
				@if (tabValue === 'Cluster') {
					<yuno-admin-category-cluster></yuno-admin-category-cluster>
				}
			</div>
		</div>
	</yuno-edit-container>
}
