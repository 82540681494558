import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { combineLatest, map, take, withLatestFrom } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	YunoAdminButtonComponent,
	YunoAdminUiSelectorButtonComponent,
	YunoAdminUiSelectorComponent,
	YunoAdminUiSelectorDropdownComponent,
	YunoAdminUiSelectorDropdownItemComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';

import { ParticipationFacade, ParticipationModelFacade } from '../../../../../data-access';

@Component({
	selector: 'yuno-admin-participation-viewer-download',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		RouterLink,
		YunoAdminButtonComponent,
		YunoAdminUiSelectorButtonComponent,
		YunoAdminUiSelectorComponent,
		YunoAdminUiSelectorDropdownComponent,
		YunoAdminUiSelectorDropdownItemComponent,
		CdkConnectedOverlay,
		CdkOverlayOrigin
	],
	template: `
		<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
			<defs>
				<symbol
					id="shield-icon"
					viewBox="0 0 24 24"
					fill="none"
					stroke-width="1.5"
					stroke="currentColor">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
				</symbol>
			</defs>
		</svg>

		@if (minimalAppRole(userRoles.READER)) {
			@if (data$ | async; as data) {
				<div class="flex w-full flex-row flex-wrap justify-end gap-4">
					<yuno-admin-ui-selector-button
						(click)="toggle()"
						#activeContent
						cdkOverlayOrigin
						#trigger="cdkOverlayOrigin">
						<svg
							viewBox="0 0 24 24"
							class="mr-1 h-5 w-5"
							stroke-width="1.5"
							stroke="currentColor">
							<use href="#download-icon" />
						</svg>
						Download
					</yuno-admin-ui-selector-button>

					<!-- Dropdown menu -->
					<ng-template
						cdkConnectedOverlay
						[cdkConnectedOverlayOrigin]="trigger"
						[cdkConnectedOverlayPositions]="position"
						[cdkConnectedOverlayOpen]="$isOpen()"
						[cdkConnectedOverlayHasBackdrop]="true"
						[cdkConnectedOverlayBackdropClass]="'opacity-0'"
						(backdropClick)="close()">
						@if (data$ | async; as data) {
							@if (data.jsonUrl; as url) {
								<div
									id="selector-dropdown"
									(click)="close()"
									class="mb-1 overflow-hidden rounded-lg border border-yuno-blue-gray-400 bg-yuno-gray-600 px-1 shadow">
									<div class="flex flex-row gap-4">
										@if (minimalAppRole(userRoles.PUBLISHER)) {
											<ul
												class="flex flex-col gap-2 px-3 py-3 text-sm text-yuno-gray-100">
												<li>
													<span class="font-bold">
														With privacy data:
													</span>
												</li>
												<li>
													<a
														[href]="url.geojsonPrivate.href"
														[download]="url.geojsonPrivate.fileName"
														class="text-yuno-gray-100">
														<svg class="-mt-1 inline size-4">
															<use href="#shield-icon"></use>
														</svg>
														GeoJSON
													</a>
												</li>
												<li>
													<a
														[href]="url.csvPrivate.href"
														[download]="url.csvPrivate.fileName"
														class="text-yuno-gray-100">
														<svg class="-mt-1 inline size-4">
															<use href="#shield-icon"></use>
														</svg>
														CSV
													</a>
												</li>
												<li>
													<a
														[href]="url.xlsxPrivate.href"
														[download]="url.xlsxPrivate.fileName"
														class="text-yuno-gray-100">
														<svg class="-mt-1 inline size-4">
															<use href="#shield-icon"></use>
														</svg>
														Excel
													</a>
												</li>
											</ul>
										}
										<ul
											class="flex flex-col gap-2 px-3 py-3 text-sm text-yuno-gray-100">
											<li><span class="font-bold">Anonymous:</span></li>
											<li>
												<a
													[href]="url.geojson.href"
													[download]="url.geojson.fileName"
													class="text-yuno-gray-100">
													GeoJSON
												</a>
											</li>
											<li>
												<a
													[href]="url.csv.href"
													[download]="url.csv.fileName"
													class="text-yuno-gray-100">
													CSV
												</a>
											</li>
											<li>
												<a
													[href]="url.xlsx.href"
													[download]="url.xlsx.fileName"
													class="text-yuno-gray-100">
													Excel
												</a>
											</li>
										</ul>
									</div>
								</div>
							}
						}
					</ng-template>
				</div>
			}
		}
	`,
	styleUrls: ['./download.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminParticipationDownloadComponent extends AppDataComponent implements OnInit {
	private facade = inject(ParticipationFacade);
	private modelFacade = inject(ParticipationModelFacade);
	private appFacade = inject(AppFacade);

	private _id: string;

	$isOpen = signal<boolean>(false);

	position: ConnectedPosition[] = [
		{
			originX: 'end',
			originY: 'top',
			overlayX: 'end',
			overlayY: 'bottom'
		}
	];

	data$ = combineLatest({
		jsonUrl: this.modelFacade.selected$.pipe(
			withLatestFrom(this.appFacade.app$),
			map(([model, app]) => {
				if (!app) {
					return {
						geojson: {
							href: '',
							fileName: ''
						},
						geojsonPrivate: {
							href: '',
							fileName: ''
						},
						csv: {
							href: '',
							fileName: ''
						},
						csvPrivate: {
							href: '',
							fileName: ''
						},
						xlsx: {
							href: '',
							fileName: ''
						},
						xlsxPrivate: {
							href: '',
							fileName: ''
						}
					};
				}

				const fileName = `${app.id}_participate_${model?.id || 'default'}`;
				const fileNameGeoJSON = `${fileName}.geojson`;
				const fileNameCSV = `${fileName}.csv`;
				const fileNameXLSX = `${fileName}.xlsx`;

				return {
					geojson: {
						href: `/api/participate/post/${app._id}/${this._id}/${fileNameGeoJSON}`,
						fileName: fileNameGeoJSON
					},
					geojsonPrivate: {
						href: `/api/participate/post/${app._id}/${this._id}/private/${fileName}.geojson`,
						fileName: fileNameGeoJSON
					},
					csv: {
						href: `/api/participate/post/${app._id}/${this._id}/${fileName}.csv`,
						fileName: fileNameCSV
					},
					csvPrivate: {
						href: `/api/participate/post/${app._id}/${this._id}/private/${fileName}.csv`,
						fileName: fileNameCSV
					},
					xlsx: {
						href: `/api/participate/post/${app._id}/${this._id}/${fileName}.xlsx`,
						fileName: fileNameXLSX
					},
					xlsxPrivate: {
						href: `/api/participate/post/${app._id}/${this._id}/private/${fileName}.xlsx`,
						fileName: fileNameXLSX
					}
				};
			})
		)
	});

	ngOnInit(): void {
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			if (!id) {
				return;
			}

			this._id = id;
			this.facade.get(id);
			this.modelFacade.select(id);
		});
	}

	toggle(): void {
		this.$isOpen.set(!this.$isOpen());
	}

	close(): void {
		this.$isOpen.set(false);
	}
}
