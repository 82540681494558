@if (language$ | async; as lang) {
	@if (selectedPageEditor) {
		<yuno-admin-participation-steps-page-editor
			class="fixed left-0 top-0"
			[form]="form"
			[selectedPages]="selectedPages"
			[language]="lang"
			(closePage)="onClosePage()"
			(savePage)="onSavePage()"></yuno-admin-participation-steps-page-editor>
	}
	@if (form) {
		<form [formGroup]="form">
			<div class="flex items-center gap-4">
				<yuno-forms-title>Steps</yuno-forms-title>
				<button yuno-admin-button [add]="true" (click)="onAdd()">Add step</button>
			</div>
			@if (data$ | async; as data) {
				@if (data.pages) {
					<div
						class="mt-6 grid grid-cols-1 gap-1"
						cdkDropList
						[cdkDropListData]="data.pages"
						(cdkDropListDropped)="drop($event, data.pages)">
						@for (page of data.pages; track page; let i = $index) {
							<yuno-admin-drag-row cdkDrag cdkDragLockAxis="y" [keepButtons]="true">
								<div
									class="flex flex-1 flex-row items-center justify-between"
									title>
									<span>{{ page.data?.header | languageSelect: language }} </span>
									<span class="text-sm"> {{ page.type }} </span>
								</div>
								<ng-container buttons>
									<button yuno-admin-edit-button (click)="onSelect(i)">
										edit
									</button>
									<button yuno-admin-delete-admin-button (click)="onRemove(i)">
										delete
									</button>
								</ng-container>
							</yuno-admin-drag-row>
						}
					</div>
				}
			}
		</form>
	}
}
