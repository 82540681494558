import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import { Textfield } from '@yuno/api/interface';

import { OverlayTypes } from './textfield-overlays.state';

export const textfieldOverlayActions = createActionGroup({
	source: 'textfieldOverlay',
	events: {
		loadTextfieldOverlay: props<{
			overlay: OverlayTypes;
			textfield: string;
			appId: string;
			data?: Feature | MapGeoJSONFeature;
		}>(),
		loadTextfieldOverlaySucces: props<{
			overlay: OverlayTypes;
			textfield: Textfield;
			data?: Feature | MapGeoJSONFeature;
		}>(),
		loadTextfieldOverlayFailure: props<{ overlay: OverlayTypes; error: Error }>(),
		closeOverlay: props<{ overlay: OverlayTypes; timeout: number }>(),
		closeOverlayType: props<{ overlay: OverlayTypes }>(),
		closeAllOverlays: emptyProps(),
		setLanguage: props<{ language: string }>()
	}
});
