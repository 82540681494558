import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DragRowComponent, YunoAdminButtonComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import {
	ParticipationModelForm,
	ParticipationSettingsEditorService
} from '../../settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-crm',
	standalone: true,
	imports: [
		YunoFormsModule,
		ReactiveFormsModule,
		DragDropModule,
		YunoAdminButtonComponent,
		DragRowComponent
	],
	templateUrl: './crm.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelCRMComponent {
	service = inject(ParticipationSettingsEditorService);
	@Input() form: FormGroup<ParticipationModelForm>;
}
