import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminEditButtonComponent
} from '@yuno/admin/ui';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { LanguagePipe } from '@yuno/angular/pipes';
import { MarkerCategoryInputs } from '@yuno/api/interface';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-list-category-inputs',
	standalone: true,
	imports: [
		CommonModule,
		YunoFormsModule,
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		DragDropModule,
		DragRowComponent,
		LanguagePipe,
		YunoAdminEditButtonComponent,
		YunoAdminDeleteAdminButtonComponent
	],
	templateUrl: './list-category-inputs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCategoryInputsComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	private categoryFacade = inject(MarkerCategoriesFacade);
	service = inject(CategoryEditorService);

	@Output() openInputEditor = new EventEmitter<boolean>();
	@Output() openInputList = new EventEmitter<boolean>();
	@Input() language: string | undefined = 'nl';

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	async drop(event: CdkDragDrop<Partial<MarkerCategoryInputs>[]>): Promise<void> {
		moveItemInFormArray(this.service.inputs, event.previousIndex, event.currentIndex);
	}

	onAddInput() {
		this.openInputList.next(true);
	}

	onSelectInput(index: number) {
		this.categoryFacade.selectInput(index);
		this.openInputEditor.next(true);
	}

	onRemoveInput(index: number) {
		this.service.inputs.removeAt(index);
	}
}
