import { gql } from 'apollo-angular';

import { Template } from '@yuno/api/interface';

export const GET_TEMPLATES_BY_SELECTOR = gql`
	query getTemplatesBySelector($selector: String!) {
		templates(selector: $selector) {
			_id
			id
			img
			selector
			options
			tags
		}
	}
`;

export const GET_TEMPLATE_BY_ID = gql`
	query getTemplateById($_id: String!) {
		selectedTemplate(_id: $_id) {
			_id
			id
			img
			selector
			options
			tags
		}
	}
`;

export const FILTER_TEMPLATES = gql`
	query filterTemplates($selector: String!, $filter: String!) {
		filterTemplates(selector: $selector, filter: $filter) {
			_id
			id
			img
			selector
			options
			tags
		}
	}
`;

export type TemplatesQuery = {
	templates: Partial<Template>[];
};

export type SelectTemplateQuery = {
	selectedTemplate: Partial<Template>;
};

export type FilterTemplatesQuery = {
	filterTemplates: Partial<Template>[];
};
