@if (toggle$ | async) {
	<yuno-admin-events-forms
		[group]="service.formEvents"
		[array]="active === 'onClick' ? service.onClickEvents : service.onMouseMoveEvents"
		[arrayName]="active">
	</yuno-admin-events-forms>
}
@if (service.form) {
	<form [formGroup]="service.form" class="grid grid-cols-1 gap-4">
		<yuno-forms-title>Basic Settings</yuno-forms-title>
		<div class="grid grid-cols-2 gap-4">
			<yuno-forms-toggle formControlName="public" label="Public"></yuno-forms-toggle>
			<yuno-forms-toggle
				formControlName="intern"
				label="Intern (not visible for clients)"></yuno-forms-toggle>
		</div>
		<div class="grid grid-cols-6 gap-4">
			<yuno-forms-text
				class="col-span-4"
				formControlName="id"
				label="Category name"></yuno-forms-text>
			<yuno-forms-number
				class="col-span-2"
				formControlName="order"
				label="CMS list order"></yuno-forms-number>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<yuno-forms-title>CMS viewer app Settings (optional)</yuno-forms-title>
		<div class="grid grid-cols-2 gap-4">
			<yuno-forms-select
				formControlName="fence"
				[selectValues]="service.fenceSelectValues"
				[display]="service.fenceSelectDisplay"
				label="Start fence"></yuno-forms-select>
			<yuno-forms-select
				formControlName="mapStyle"
				[selectValues]="service.styleSelectValues"
				[display]="service.styleSelectDisplay"
				label="Map style"></yuno-forms-select>
			<yuno-forms-select
				formControlName="theme"
				[placeholder]="'none'"
				[selectValues]="service.themeSelectValues"
				[display]="service.themeSelectDisplay"
				label="Theme"></yuno-forms-select>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<div class="grid grid-cols-1 gap-4">
			<yuno-forms-title>Events</yuno-forms-title>
			<div class="flex flex-row items-start justify-start gap-4">
				@if ({ length: service.onClickEvents.controls.length || 0 }; as data) {
					<button yuno-admin-button (click)="onToggleEvents('onClick')">
						Click Events ({{ data.length }})
					</button>
				}
				@if ({ length: service.onMouseMoveEvents.controls.length || 0 }; as data) {
					<button yuno-admin-button (click)="onToggleEvents('onMouseMove')">
						Hover Events ({{ data.length }})
					</button>
				}
			</div>
			<yuno-forms-toggle
				formControlName="customEvents"
				label="allow custom events"></yuno-forms-toggle>
		</div>
	</form>
}
