import { inject } from '@angular/core';
import { ResolveFn, Routes } from '@angular/router';

import { FencesEditorComponent } from '@yuno/admin/features/fences';
import { LayerEditorComponent } from '@yuno/admin/features/layers';
import { MarkerEditorComponent } from '@yuno/admin/features/markers/feature/editor/marker-editor.component';
import { PanoramaEditorComponent } from '@yuno/admin/features/panoramas';
import { ParticipationModelFacade } from '@yuno/admin/features/participation';
import { ParticipationSettingsEditorComponent } from '@yuno/admin/features/participation/feature/editors/settings/settings-editor.component';
import { CategoryEditorComponent } from '@yuno/admin/features/place-markers/feature/settings/category/category-editor.component';

import { DatasetListviewFencesComponent } from '../listviews/fences/fences.component';
import { DatasetListviewGeoPhotosComponent } from '../listviews/geophotos/geo-photos.component';
import { DatasetListviewLayersComponent } from '../listviews/layers/layers.component';
import { DatasetListviewMarkerCategoriesComponent } from '../listviews/marker-categories/marker-categories.component';
import { DatasetListviewMarkersComponent } from '../listviews/markers/markers.component';
import { DatasetListviewObjectsComponent } from '../listviews/objects/objects.component';
import { DatasetListviewPanoramasComponent } from '../listviews/panoramas/panoramas.component';
import { DatasetListviewParticipatesComponent } from '../listviews/participates/participates.component';
import { DatasetListviewShapesComponent } from '../listviews/shapes/shapes.component';
import { StateSettingsEditorComponent } from '../state-settings-editor/state-settings-editor.component';

const getParticipateResolver: ResolveFn<void> = route => {
	const modelFacade = inject(ParticipationModelFacade);
	const id = route.paramMap.get('id');

	if (!id) {
		return;
	}

	modelFacade.select(id as string);
};

const clearParticipateResolver: ResolveFn<void> = () => {
	const modelFacade = inject(ParticipationModelFacade);
	modelFacade.clearSelected();
};

export const DatasetChildRoutes: Routes = [
	{
		path: 'fences',
		loadComponent: () => DatasetListviewFencesComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => FencesEditorComponent
			}
		]
	},
	{
		path: 'layers',
		loadComponent: () => DatasetListviewLayersComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => LayerEditorComponent
			}
		]
	},
	{
		path: 'markers',
		loadComponent: () => DatasetListviewMarkersComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => MarkerEditorComponent
			}
		]
	},
	{
		path: 'markerCategories',
		loadComponent: () => DatasetListviewMarkerCategoriesComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => CategoryEditorComponent
			}
		]
	},
	{
		path: 'panoramas',
		loadComponent: () => DatasetListviewPanoramasComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => PanoramaEditorComponent
			}
		]
	},
	{ path: 'objects', loadComponent: () => DatasetListviewObjectsComponent },
	{
		path: 'participates',
		loadComponent: () => DatasetListviewParticipatesComponent,
		children: [
			{
				path: ':id',
				resolve: [getParticipateResolver],
				canDeactivate: [clearParticipateResolver],
				data: {
					popup: true
				},
				loadComponent: () => ParticipationSettingsEditorComponent
			}
		]
	},
	{ path: 'photoCategories', loadComponent: () => DatasetListviewGeoPhotosComponent },
	{
		path: 'shapes',
		loadComponent: () => DatasetListviewShapesComponent,
		children: [
			{
				path: ':id',
				data: { popup: true },
				loadComponent: () => FencesEditorComponent
			}
		]
	},
	{ path: ':index/state', loadComponent: () => StateSettingsEditorComponent },
	{ path: '**', redirectTo: 'fences' }
];
