@if (data$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="false"
		[sortable]="true"
		[pagination]="false"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [10, 25, 50, 100],
			hidePageSize: false
		}"
		[defaultColumn]="preview === 'No' ? 'id' : 'img'"
		[data]="data.templates"
		[buttons]="tableButtons"
		[customTemplate]="tableImage"
		[columns]="[
			{
				key: 'img',
				label: 'Image',
				type: 'custom',
				hidden: preview === 'No',
				sticky: true,
				maxWidth: 17,
				sortable: false
			},
			{ key: 'id', label: 'Name' },
			{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
		]"
		(clicked)="onSelect($event)">
		<div class="flex gap-2">
			@if (minimalRole(roles.SUPERADMIN)) {
				<button yuno-admin-add-button (click)="onTemplate()">New Template</button>
			}
		</div>
	</yuno-admin-table>
	<ng-template #tableImage let-row>
		<div
			class="bg-stripes-gray my-2 flex aspect-video w-48 items-center justify-center overflow-hidden rounded md:w-60">
			@if (row.img; as img) {
				<img
					class="max-h-full object-cover"
					[src]="img"
					loading="lazy"
					[alt]="row.data?.id" />
			}
		</div>
	</ng-template>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
