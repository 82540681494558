import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ParticipationModel, ParticipationPages } from '@yuno/api/interface';

export const modelsActions = createActionGroup({
	source: 'Participation Models',
	events: {
		create: emptyProps(),
		createSuccess: props<{ id: string }>(),
		createFailure: props<{ error: Error }>(),
		createTemplate: props<{ _id: string; appId: string }>(),
		createTemplateSuccess: props<{ id: string }>(),

		load: emptyProps(),
		loadSuccess: props<{ data: Partial<ParticipationModel>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		restoreOriginal: emptyProps(),
		selectSuccess: props<{ data: Partial<ParticipationModel> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<ParticipationModel> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<ParticipationModel> }>(),
		updateSelectSuccess: props<{ data: Partial<ParticipationModel> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		changesSelectedModelPagesOrder: props<{
			data: ParticipationPages[];
			previousIndex: number;
			currentIndex: number;
		}>(),
		removeSelectedModelPage: props<{ index: number }>(),
		reset: emptyProps()
	}
});
