import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-list-category-cluster',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule],
	templateUrl: './list-category-cluster.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCategoryClusterComponent {
	service = inject(CategoryEditorService);
}
