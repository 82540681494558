@if (service.form) {
	<form [formGroup]="service.form" class="grid grid-cols-1 gap-4">
		<div class="mb-4 flex">
			<h2>Styles</h2>
			<div class="flex-1"></div>
			<button yuno-admin-button (click)="onAddStyle()" [add]="true">Add Style</button>
		</div>
		<div class="mb-4 flex flex-col gap-1">
			@for (style of service.styles.controls; track style; let i = $index) {
				<div
					class="flex items-center justify-start gap-2 rounded-md border border-solid border-yuno-blue-gray-400 bg-yuno-blue-gray-100 px-4 text-yuno-blue-gray-700 hover:bg-yuno-blue-gray-200"
					(mouseenter)="hover = i"
					(mouseleave)="hover = null">
					<div class="flex-1 select-none truncate py-4">
						{{ style.value.id }}
					</div>
					<div class="flex">
						<button yuno-admin-button-table (click)="onSelectStyle(i)">Edit</button>
						<button yuno-admin-button-table (click)="onRemoveStyle(i)" color="danger">
							Delete
						</button>
					</div>
				</div>
			}
		</div>
		<div formGroupName="layout">
			<yuno-forms-select
				[display]="service.stylesKeys"
				[selectValues]="service.stylesKeys"
				label="Default Style"
				formControlName="fallback"></yuno-forms-select>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<yuno-forms-title>Styling Rules</yuno-forms-title>
		<yuno-forms-span>
			Define the rules that determine which style is used for a marker.
		</yuno-forms-span>
		<div formGroupName="layout" class="mt-4 grid grid-cols-1 gap-4">
			<yuno-forms-select
				[display]="service.styleRuleSelectDisplay"
				[selectValues]="service.styleRuleSelectValues"
				label="input field"
				formControlName="filter"
				(changes)="service.resetLayoutOptions()"></yuno-forms-select>
			<yuno-forms-span
				class="rounded-md border border-red-200 bg-red-100 px-2 font-semibold text-red-500">
				Changing this value clears all defined rules.
			</yuno-forms-span>
		</div>
		<div class="my-4 flex">
			<div class="flex-1"></div>
			<button yuno-admin-button (click)="onSetRules()">Set Rules</button>
		</div>
	</form>
}
