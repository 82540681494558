import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';

import { AppFacade } from '@yuno/admin/features/apps';
import { PanoramasFacade } from '@yuno/admin/features/panoramas';
import { StatesFacade } from '@yuno/admin/features/states/data-access/+state/states.facade';
import { KrpanoInstance, PanoramaComponent, View } from '@yuno/angular/krpano';
import { Panorama } from '@yuno/api/interface';

import { environment } from '../../../../../../../../../apps/admin/src/environments/environment';
import { PanoramaEditorService } from '../panorama-editor.service';
import { CommonModule } from '@angular/common';
import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { ToRadiansPipe } from '@yuno/angular/pipes';

@Component({
	standalone: true,
	selector: 'yuno-admin-pano-viewer',
	templateUrl: './pano-viewer.component.html',
	styleUrls: ['./pano-viewer.component.scss'],
	imports: [
		CommonModule,
		PanoramaComponent,
		YunoAdminButtonComponent,
		ToRadiansPipe
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoViewerComponent implements OnInit {
	private readonly appFacade = inject(AppFacade);
	private readonly panoFacade = inject(PanoramasFacade);
	private readonly statesFacade = inject(StatesFacade);
	readonly service = inject(PanoramaEditorService);

	@Input() hideBtn = true;
	yawOffset = 0;
	yawOffsetObservable: Subject<number> = new Subject<number>();
	offset: { yaw: number } = { yaw: 0 };

	resetPanoView = false;
	showPano = true;
	currentView: View;

	activeState = new Subject<string>();
	xml: string;

	data$ = combineLatest({
		panorama: this.panoFacade.selectedPanorama$,
		next: this.panoFacade.next$.pipe(startWith(null)),
		prev: this.panoFacade.prev$.pipe(startWith(null)),
		appId: this.appFacade.appId$,
		loaded: this.panoFacade.selectedLoaded$,
		states: this.statesFacade.states$.pipe(
			tap(states => {
				if (states && Array.isArray(states)) {
					this.activeState.next(states[0]?.state as string);
				}
			})
		),
		activeState: this.activeState.asObservable()
	}).pipe(
		tap(({ appId, panorama, loaded, activeState }) => {
			// When we close the panorama
			if (!loaded) {
				this.setKrpanoInstance(null);
				return;
			}

			this.generateXMLUrl(appId, panorama, activeState);
		})
	);

	@Output() view = new EventEmitter<View>();
	@Output() pano = new EventEmitter<string>();

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.yawOffsetObservable.pipe(debounceTime(400), distinctUntilChanged()).subscribe(e => {
			this.offset = { yaw: e };
		});
	}

	generateXMLUrl(appId?: string, panorama?: Panorama, state?: string | null): void {
		if (!appId) {
			return;
		}

		if (
			!panorama ||
			!panorama.url?.client ||
			!panorama.url?.project ||
			!panorama.url?.preview
		) {
			return;
		}

		this.xml = state
			? `${environment['yuno-api']}/panorama/${appId}/${state}/${panorama._id}.xml`
			: `${environment['yuno-api']}/panorama/${appId}/${panorama._id}.xml`;
	}

	setKrpanoInstance(krpano: KrpanoInstance | null): void {
		this.service.setKrpanoInstance(krpano);
	}

	onSetRotation(event: View) {
		this.currentView = event;
	}

	// number
	onOffsetChange(event: Event): void {
		const value = Number((event.target as HTMLInputElement).value);
		this.yawOffsetObservable.next(value);
	}

	onApplyView() {
		this.view.next(this.currentView);
	}

	changePano(_id?: string | null) {
		if (_id) {
			this.pano.next(_id);
		}
	}

	changeState(event: Event) {
		const value = (event.target as HTMLInputElement).value;
		this.activeState.next(value);
	}
}
