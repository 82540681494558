import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, take, tap } from 'rxjs';

import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { PanoramasFacade } from '@yuno/admin/features/panoramas/data-access';
import { StatesFacade } from '@yuno/admin/features/states/data-access/+state/states.facade';
import { TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { View } from '@yuno/angular/krpano';
import { Panorama, PanoramaMap, PanoramaView } from '@yuno/api/interface';

import { precisionRound, toRadians } from '../../../../../../../../apps/admin/src/helpers';
import { PanoMapComponent } from './pano-map/pano-map.component';
import { PanoViewerComponent } from './pano-viewer/pano-viewer.component';
import { PanoramaEditorService } from './panorama-editor.service';

export type panoTabOptions =
	| 'Settings'
	| 'Map Options'
	| 'Server Options'
	| 'Panorama Options'
	| 'Text Options';

@Component({
	standalone: true,
	selector: 'yuno-admin-panorama-editor',
	templateUrl: './panorama-editor.component.html',
	styleUrls: ['./panorama-editor.component.scss'],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoFormsModule,
		PanoViewerComponent,
		PanoMapComponent,
		YunoAdminButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoAdminCloseButtonComponent,
		EditContainerContentDirective,
		UserRightsMessageComponent,
		EditContainerComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoramaEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class.fixed') isPopup = false;

	private readonly panoFacade = inject(PanoramasFacade);
	private readonly stateFacade = inject(StatesFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(PanoramaEditorService);

	originalData: Partial<Panorama> | null;
	url: string;
	tabs = ['Settings', 'Map Options', 'Server Options', 'Panorama Options', 'Text Options'];

	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	private targetRoute: string[] | undefined;

	data$ = combineLatest({
		panorama: this.panoFacade.selectedPanorama$.pipe(
			tap(data => {
				if (!this.originalData) {
					if (!data) {
						return;
					}

					this.originalData = data as Panorama;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);

					this.editContainerBreadcrumbsService.addRoute('data', data?.id);
					const states = this.originalData?.textfield?.states;
					if (states && states.length >= 1) {
						this.service.addStates(states);
						this.service.states.patchValue(states);
					}
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		states: this.stateFacade.states$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.stateValues = data.map(object => object._id) as string[];
					this.service.stateDisplay = data.map(object => object.state) as string[];
				}
			})
		),
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.textfieldValues = data.map(object => object._id) as string[];
					this.service.textfieldDisplay = data.map(object => object.id) as string[];
				}
			})
		),
		parent: this.datasetFacade.parent$
	});

	tabValue: panoTabOptions = 'Settings';

	ngOnInit(): void {
		this.textfieldFacade.get();
		this.stateFacade.get();
		this.service.createFormGroup();
		this.onChanges();
		this.onRouting();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.panoFacade.select(id);
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.panoFacade.updateSelect(this.service.form.getRawValue() as Panorama);
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});

		if (this.isPopup) {
			this.route.paramMap.pipe(take(1)).subscribe(data => {
				const targetRouteArray = this.route?.parent?.snapshot.pathFromRoot
					.map(s => s.url)
					.reduce((a, e) => {
						return a.concat(e);
					})
					.map(s => s.path);
				this.targetRoute = targetRouteArray?.slice(0, -1);
			});
		}
	}

	onSave() {
		this.panoFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		if (this.isPopup) {
			redirectTo(this.route, this.router, [], this.targetRoute);
		} else {
			redirectTo(this.route, this.router);
		}
		this.panoFacade.clearSelect();
	}

	onSwitchTab(key: string) {
		this.tabValue = key as panoTabOptions;
	}

	onChangePano(id: string) {
		if (id) {
			this.originalData = null;
			this.cdr.markForCheck();
			this.panoFacade.select(id);
		}
	}

	onUpdateView(data: View) {
		if (!this.minimalAppRole(this.userRoles.EDITOR)) {
			return;
		}

		const map = {
			rotation: precisionRound(data.hlookat, 2)
		} as PanoramaMap;
		const view = {
			fov: toRadians(data.fov),
			yaw: toRadians(data.hlookat),
			pitch: toRadians(data.vlookat)
		} as PanoramaView;

		this.service.map.patchValue(map);
		this.service.view.patchValue(view);
	}

	ngOnDestroy(): void {
		this.editContainerBreadcrumbsService.removeRouteName(this.originalData?.id);
	}
}
