@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-8">
		<section>
			<yuno-forms-title>CRM</yuno-forms-title>
			<yuno-forms-span> Here you can activate CRM options.</yuno-forms-span>
		</section>
		<section formGroupName="crm">
			<yuno-forms-title>Dialog</yuno-forms-title>
			<yuno-forms-span>
				This will push all incoming Participations to the Dialog CRM.
			</yuno-forms-span>
			<div formGroupName="dialog" class="grid grid-cols-1 gap-4">
				<yuno-forms-toggle
					formControlName="active"
					label="Active"
					labelPos="side"></yuno-forms-toggle>
				<yuno-forms-text
					formControlName="project"
					label="X-Project"
					placeholder="Add a project name">
				</yuno-forms-text>
				<yuno-forms-text
					formControlName="client"
					label="X-CustomerApiKey"
					placeholder="Add a customer api key">
				</yuno-forms-text>
				<yuno-forms-text
					formControlName="user"
					label="X-ApiKey"
					placeholder="Add a user api key">
				</yuno-forms-text>
			</div>
		</section>
	</form>
}

<ng-template #emptyList>
	<div
		class="flex h-15 select-none items-center rounded-md border border-gray-300 bg-gray-200 pl-4 text-gray-500">
		No keys added.
	</div>
</ng-template>
