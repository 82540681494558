import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { ChooseTemplateComponent, TemplatesFacade } from '@yuno/admin/features/templates';
import { YunoAdminButtonsModule, YunoAdminTableComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Template } from '@yuno/api/interface';

import { LayersRoutingModule } from '../internal-view/layers-routing.module';

@Component({
	standalone: true,
	imports: [
		YunoCardModule,
		YunoAdminTableComponent,
		LayersRoutingModule,
		YunoAdminButtonsModule,
		AsyncPipe,
		ChooseTemplateComponent
	],
	selector: 'yuno-admin-layer-template-view',
	templateUrl: './template-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerTemplateViewComponent extends AppDataComponent implements OnInit {
	private readonly templateFacade = inject(TemplatesFacade);

	previewPills: string[] = ['No', 'Yes'];
	preview = 'Yes';

	data$ = combineLatest({
		templates: this.templateFacade.templates$.pipe(
			map(data => {
				return data.map(template => {
					const newTemp = Object.assign({}, template);
					const layerOptions = template.options as {
						layers: string[];
						layerType: string;
					};
					if (layerOptions.layerType) {
						newTemp.id = `${layerOptions.layerType} - ${template.id}`;
					}
					return newTemp;
				});
			})
		)
	});

	ngOnInit(): void {
		this.templateFacade.get('layer');
	}

	onSelect(row: Partial<Template>) {
		this.router.navigate(['template', row._id], {
			relativeTo: this.route
		});
	}

	onTemplate() {
		this.router.navigate(['template', 'create'], {
			relativeTo: this.route
		});
	}

	onDelete(row: Partial<Template>): void {
		if (!row._id) {
			return;
		}
		this.templateFacade.delete(row._id, 'layer');
	}
}
