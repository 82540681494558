@if (data) {
	<form [formGroup]="data">
		<div class="grid gap-2">
			@switch (data.value.type && ['textfield', 'paragraph'].includes(data.value.type)) {
				@case (true) {
					<yuno-forms-text
						formControlName="key"
						label="Key"
						placeholder="this is the name that will be stored in the database"></yuno-forms-text>
					<section formGroupName="label">
						@for (lang of languages; track lang) {
							@if (lang === language) {
								<yuno-forms-html-image-library
									[language]="lang"
									[formControlName]="lang"
									label="Text"
									placeholder="paragraph textfield"></yuno-forms-html-image-library>
							}
						}
					</section>
				}
				@default {
					<yuno-forms-text
						formControlName="key"
						label="Key"
						placeholder="this is the name that will be stored in the database"></yuno-forms-text>

					<section formGroupName="label">
						@for (lang of languages; track lang) {
							@if (lang === language) {
								<yuno-forms-text
									[language]="lang"
									[formControlName]="lang"
									label="Label"
									placeholder="label"></yuno-forms-text>
							}
						}
					</section>
					<section formGroupName="placeholder">
						@for (lang of languages; track lang) {
							@if (lang === language) {
								<yuno-forms-text
									[language]="lang"
									[formControlName]="lang"
									label="Place holder text"
									placeholder="add a placeholder text"></yuno-forms-text>
							}
						}
					</section>

					<yuno-forms-divider></yuno-forms-divider>
					<!-- selectValues -->
					@if (data.value.type === 'select') {
						<div
							formArrayName="selectValues"
							class="my-2 grid w-full grid-cols-1 gap-1">
							@for (alias of selectValues.controls; track alias; let i = $index) {
								<div
									class="group flex w-full flex-row items-center justify-start gap-2 rounded-md border border-gray-400 bg-gray-300 p-2">
									<section [formGroupName]="i" class="flex-1">
										@for (lang of languages; track lang) {
											@if (lang === language) {
												<yuno-forms-text
													class="flex-1"
													[language]="lang"
													[formControlName]="lang"
													placeholder="add a option to choose from"></yuno-forms-text>
											}
										}
									</section>
									<div class="flex justify-center group-first:hidden">
										<button
											yuno-admin-button
											color="danger"
											(click)="removeItemAt(i)">
											remove
										</button>
									</div>
								</div>
							}
						</div>
						<button yuno-admin-button color="primary" (click)="addItem()" [add]="true">
							Add Option
						</button>
						<yuno-forms-divider></yuno-forms-divider>
					}
					<yuno-forms-toggle
						formControlName="required"
						label="Required"
						labelPos="side"></yuno-forms-toggle>

					<section formGroupName="validationMessage">
						@for (lang of languages; track lang) {
							@if (lang === language) {
								<yuno-forms-text
									[language]="lang"
									[formControlName]="lang"
									label="validation message"
									placeholder="validationMessage"></yuno-forms-text>
							}
						}
					</section>
				}
			}
		</div>
	</form>
}
