var minus = "-".charCodeAt(0);
var plus = "+".charCodeAt(0);
var dot = ".".charCodeAt(0);
var exp = "e".charCodeAt(0);
var EXP = "E".charCodeAt(0);

// Check if three code points would start a number
// https://www.w3.org/TR/css-syntax-3/#starts-with-a-number
function likeNumber(value) {
  var code = value.charCodeAt(0);
  var nextCode;
  if (code === plus || code === minus) {
    nextCode = value.charCodeAt(1);
    if (nextCode >= 48 && nextCode <= 57) {
      return true;
    }
    var nextNextCode = value.charCodeAt(2);
    if (nextCode === dot && nextNextCode >= 48 && nextNextCode <= 57) {
      return true;
    }
    return false;
  }
  if (code === dot) {
    nextCode = value.charCodeAt(1);
    if (nextCode >= 48 && nextCode <= 57) {
      return true;
    }
    return false;
  }
  if (code >= 48 && code <= 57) {
    return true;
  }
  return false;
}

// Consume a number
// https://www.w3.org/TR/css-syntax-3/#consume-number
module.exports = function (value) {
  var pos = 0;
  var length = value.length;
  var code;
  var nextCode;
  var nextNextCode;
  if (length === 0 || !likeNumber(value)) {
    return false;
  }
  code = value.charCodeAt(pos);
  if (code === plus || code === minus) {
    pos++;
  }
  while (pos < length) {
    code = value.charCodeAt(pos);
    if (code < 48 || code > 57) {
      break;
    }
    pos += 1;
  }
  code = value.charCodeAt(pos);
  nextCode = value.charCodeAt(pos + 1);
  if (code === dot && nextCode >= 48 && nextCode <= 57) {
    pos += 2;
    while (pos < length) {
      code = value.charCodeAt(pos);
      if (code < 48 || code > 57) {
        break;
      }
      pos += 1;
    }
  }
  code = value.charCodeAt(pos);
  nextCode = value.charCodeAt(pos + 1);
  nextNextCode = value.charCodeAt(pos + 2);
  if ((code === exp || code === EXP) && (nextCode >= 48 && nextCode <= 57 || (nextCode === plus || nextCode === minus) && nextNextCode >= 48 && nextNextCode <= 57)) {
    pos += nextCode === plus || nextCode === minus ? 3 : 2;
    while (pos < length) {
      code = value.charCodeAt(pos);
      if (code < 48 || code > 57) {
        break;
      }
      pos += 1;
    }
  }
  return {
    number: value.slice(0, pos),
    unit: value.slice(pos)
  };
};