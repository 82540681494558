import { gql } from 'apollo-angular';

import { ParticipationModel } from '@yuno/api/interface';

export const CREATE_PARTICIPATION_MODEL = gql`
	mutation createParticipationModel($appId: String!) {
		createParticipationModel(appId: $appId) {
			_id
		}
	}
`;

export const GET_PARTICIPATION_MODELS = gql`
	query getParticipationModels($appId: String!) {
		getParticipationModels(appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export const GET_PARTICIPATION_MODEL = gql`
	query getParticipationModel($appId: String!, $id: String!) {
		getParticipationModel(appId: $appId, id: $id) {
			_id
			appId
			id
			theme
			textfield
			notification
			notificationLanguage
			style {
				sprite
				spriteSelect
			}
			updatedAt
			active
			liveUpdate
			doubleOptIn
			cms {
				listKeys
				reaction {
					left
					right
				}
			}
			lastpage {
				header
				text
			}
			reaction {
				answerTitle
				reactionTitle
			}
			pages {
				type
				data {
					header
					text
					content
					textMobile
					required
					validationMessage
				}
				privacyStatement {
					active
					content
					color
				}
				textfield {
					content
				}
				form {
					controlType
					type
					key
					label
					selectValues
					placeholder
					required
					validationMessage
				}
			}
			crm {
				dialog {
					active
					project
					client
					user
				}
			}
			users {
				displayName
				email
			}
		}
	}
`;

export const SAVE_PARTICIPATION_MODEL = gql`
	mutation saveParticipationModel($data: ParticipationModelInput!, $appId: String!) {
		saveParticipationModel(data: $data, appId: $appId) {
			_id
			id
			appId
			theme
			textfield
			notification
			notificationLanguage
			style {
				sprite
				spriteSelect
			}
			updatedAt
			active
			liveUpdate
			cms {
				listKeys
				reaction {
					left
					right
				}
			}
			lastpage {
				header
				text
			}
			reaction {
				answerTitle
				reactionTitle
			}
			pages {
				type
				data {
					header
					text
					content
					textMobile
					required
					validationMessage
				}
				privacyStatement {
					active
					content
					color
				}
				textfield {
					content
				}
				form {
					controlType
					type
					key
					label
					selectValues
					placeholder
					required
					validationMessage
				}
			}
			crm {
				dialog {
					active
					project
					client
					user
				}
			}
			users {
				displayName
				email
			}
		}
	}
`;

export const DELETE_PARTICIPATION_MODEL = gql`
	mutation deleteParticipationModelById($_id: String!, $appId: String!) {
		deleteParticipationModelById(_id: $_id, appId: $appId)
	}
`;

export const DUPLICATE_PARTICIPATION_MODEL = gql`
	mutation duplicateParticipationModelById($_id: String!, $appId: String!) {
		duplicateParticipationModelById(_id: $_id, appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export const CREATE_PARTICIPATION_MODEL_FROM_TEMPLATE = gql`
	mutation createParticipationModelFromTemplate($_id: String!, $appId: String!) {
		createParticipationModelFromTemplate(_id: $_id, appId: $appId) {
			_id
		}
	}
`;

export type CreateMutation = {
	createParticipationModel: {
		_id: string;
	};
};

export type GetQuery = {
	getParticipationModels: Partial<ParticipationModel>[];
};

export type SelectQuery = {
	getParticipationModel: Partial<ParticipationModel>;
};

export type SaveMutation = {
	saveParticipationModel: ParticipationModel;
};

export type DuplicateMutation = {
	duplicateParticipationModelById: ParticipationModel;
};

export type DeleteMutation = {
	deleteParticipationModelById: string;
};

export type CreateTemplateMutation = {
	createParticipationModelFromTemplate: {
		_id: string;
	};
};
