import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	Renderer2
} from '@angular/core';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';

import {
	EmbedTextfieldObj,
	OpenTextfieldOverlayTypes,
	Textfield,
	UiColors
} from '@yuno/api/interface';

import { TextfieldOverlayFacade } from './_state/textfield-overlays';

// TODO:
// Replace with import { waitFor } from '@yuno/libs/shared/helpers';
// after https://github.com/nrwl/nx/pull/10414
const waitFor = (delay: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, delay));

@Component({
	selector: 'yuno-project-atlas-textfield-overlay-defaults',
	template: ``,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class BasicOverlayComponent implements AfterViewInit {
	private _data: Textfield;
	private _language: string | null;
	private _colors: UiColors | undefined;
	private _embed: EmbedTextfieldObj;

	@Input() defaultOpenState = true;
	@Input() feature?: Feature | MapGeoJSONFeature;

	@Input() set zIndex(num: number) {
		this.renderer.setStyle(this.el.nativeElement, '--overlay-zindex', num || 30, 2);
	}

	@Input() set data(data: Textfield) {
		this._data = data;
	}

	get data(): Textfield {
		return this._data;
	}

	@Input() set language(language: string | null) {
		this._language = language;
	}
	get language(): string | null {
		return this._language;
	}

	@Input() set colors(colors: UiColors | undefined) {
		this._colors = colors;
	}

	get colors(): UiColors | undefined {
		return this._colors;
	}

	@Input() set embed(action: EmbedTextfieldObj) {
		this._embed = action;
	}
	get embed(): EmbedTextfieldObj {
		return this._embed;
	}

	@Input() set toggle(bool: boolean) {
		this.status = bool ? 'active' : 'deactive';
	}

	@Output() closing = new EventEmitter<void>();

	@HostBinding('class') status: 'active' | 'deactive';
	animationTime = 450;

	constructor(
		private el: ElementRef,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		public facade: TextfieldOverlayFacade
	) {}

	async ngAfterViewInit(): Promise<void> {
		if (!this.defaultOpenState) {
			return;
		}

		await waitFor(0);
		this.status = 'active';

		this.cdr.markForCheck();
	}

	async startClosing(overlay: OpenTextfieldOverlayTypes, timer: number): Promise<void> {
		this.status = 'deactive';
		this.facade.closeOverlay(overlay, timer);

		await waitFor(timer - 10);
		this.closing.emit();

		this.cdr.markForCheck();
	}
}
