@if (data$ | async; as data) {
	<div
		(click)="onBackgroundClick()"
		class="flex h-screen w-screen flex-col items-center justify-center"
		[ngClass]="{ 'pointer-events-auto bg-yuno-blue-gray-700/80 backdrop-blur-xs': backdrop }">
		<div
			(click)="$event.stopPropagation()"
			class="pointer-events-auto relative z-50 grid w-[528px] gap-4 rounded-lg bg-white p-8 md:w-auto">
			<h1>Choose a template</h1>
			<span
			>You always have the option to change and tweak the settings of the created
				{{ selector }}.</span
			>
			<section class="max-h-[640px] overflow-y-auto">
				@if (selector === 'textfield') {
					<div class="grid grid-cols-2 gap-4 md:grid-cols-3">
						@for (preset of presets; track preset) {
							<div
								(click)="onSelectPreset(preset._id)"
								class="non-select h-64 w-56 rounded-md bg-yuno-blue-gray-500 p-6 hover:cursor-pointer">
								<h5 class="font-medium text-white">Preset</h5>
								<h4 class="truncate">{{ preset.id }}</h4>
							</div>
						}
						@for (item of data?.templates; track item) {
							<yuno-admin-template-item
								[img]="item.img"
								(clicked)="onSelect(item.options)"></yuno-admin-template-item>
						}
					</div>
				}
				@if (selector === 'layer') {
					@if (confirmLayers) {
						<form [formGroup]="form" class="max-w-[640px] mb-4 grid grid-cols-1 p-1 gap-4">
							<yuno-admin-annotation type="info">
								<yuno-forms-span>
									The following template of your choice have multiple layers.
									Please choose a name to help keep your layers organized. This name
									will apply to all layers in the selected template.
								</yuno-forms-span>
							</yuno-admin-annotation>
							<!--							<section class="flex flex-col gap-4 col-span-1 p-1">-->
							<yuno-forms-text
								(changes)="layerName = $event"
								formControlName="name"
								placeholder="This name will help organize and identify all layers in the selected template"
								label="Name for your layers"></yuno-forms-text>
							<div class="flex items-end justify-end">
								<button yuno-admin-save-button (click)="onSelectLayers()">Create</button>
							</div>
							<!--</section>-->
						</form>
					}
				}
				@if (!confirmLayers && selector !== 'textfield') {
					<section class="grid grid-cols-1 gap-4">
						<yuno-admin-template-table
							[templates]="data?.templates"
							[selector]="selector"
							(clicked)="onSelect($event.options)"
							(filter)="applyFilter($event)">
						</yuno-admin-template-table>
					</section>
				}
			</section>
		</div>
	</div>
}
