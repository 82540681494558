import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalstorageService } from '@yuno/angular/services';

import { AuthFacade } from '../+state/auth.facade';
import { AccessTokenStorageService } from '../services/access-token-storage.service';
import { AuthService } from '../services/auth.service';
import { TOKEN_KEY } from './../+state/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
	private readonly authFacade = inject(AuthFacade);
	private readonly authService = inject(AuthService);
	private readonly localStorage = inject(LocalstorageService);
	private readonly accessTokenStorage = inject(AccessTokenStorageService);
	private isRefreshing = false;

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const jwt = this.localStorage.getItem(TOKEN_KEY);
		let cloned = req.clone({});

		if (jwt) {
			cloned = req.clone({
				setHeaders: {
					Authorization: 'Bearer ' + jwt
				}
			});
		}

		return next.handle(cloned).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(req, next);
				}

				return throwError(() => error);
			})
		);
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;

			return this.authService.refreshAuth().pipe(
				switchMap(auth => {
					this.isRefreshing = false;
					this.accessTokenStorage.saveAccessToken(auth.accessToken);

					const cloned = req.clone({
						setHeaders: {
							Authorization: 'Bearer ' + auth.accessToken
						}
					});

					return next.handle(cloned);
				}),
				catchError(error => {
					this.isRefreshing = false;

					// TODO: FIX THIS ONLY WHEN TOKEN IS INJECTED
					// this.authFacade.logout();
					return throwError(() => error);
				})
			);
		}

		return next.handle(req);
	}
}
