import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_DATASET,
	DeleteDatasetMutation
} from '@yuno/admin/features/datasets/utils/graphql/deleteDatasets';
import {
	DatasetsQuery,
	GET_DATASETS_BY_APPID,
	GET_DATASET_BY_ID,
	SelectDatasetQuery
} from '@yuno/admin/features/datasets/utils/graphql/getDatasets';
import {
	DUPLICATE_DATASET,
	DuplicateDatasetMutation,
	SAVE_DATASET,
	SAVE_DATASET_FROM_TEMPLATE,
	SaveDatasetFromTemplateMutation,
	SaveDatasetMutation
} from '@yuno/admin/features/datasets/utils/graphql/saveDatasets';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { datasetsActions } from './datasets.actions';
import { datasetsFeature } from './datasets.state';
import { themesFeature } from '@yuno/admin/features/themes';

export const loadDatasets = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(datasetsActions.load, datasetsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<DatasetsQuery>({
						query: GET_DATASETS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.datasets) {
								throw new Error('no datasets found');
							}
							return datasetsActions.loadSuccess({
								data: data.data.datasets
							});
						}),
						take(1),
						catchError(error => of(datasetsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectDatasets = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(datasetsActions.select),
			exhaustMap(dataset =>
				graphql
					.query<SelectDatasetQuery>({
						query: GET_DATASET_BY_ID,
						variables: {
							_id: dataset._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedDataset) {
								message.sendToast(`Error selecting Dataset!`, 'error');
								throw new Error('no Dataset with that id found');
							}

							return datasetsActions.selectSuccess({
								data: data.data.selectedDataset
							});
						}),
						take(1),
						catchError(error => of(datasetsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedDataset = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Dataset!`, 'error');
					throw new Error('no Dataset found');
				}
				return datasetsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(datasetsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveDataset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(datasetsActions.save),
			withLatestFrom(
				store.pipe(select(datasetsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveDatasetMutation>({
						mutation: SAVE_DATASET,
						variables: {
							dataset: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveDataset) {
								throw new Error('error saving Dataset to the database');
							}
							store.dispatch(
								datasetsActions.updateSelect({
									data: data.data.saveDataset
								})
							);
							return datasetsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(datasetsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateDataset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(datasetsActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([dataset, appId]) =>
				graphql
					.mutate<DuplicateDatasetMutation>({
						mutation: DUPLICATE_DATASET,
						variables: {
							_id: dataset._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateDataset) {
								throw new Error('error duplicating Dataset to the database');
							}
							store.dispatch(datasetsActions.reload());
							return datasetsActions.duplicateSuccess({
								data: data.data.duplicateDataset
							});
						}),
						take(1),
						catchError(error => of(datasetsActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteDataset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(datasetsActions.delete),
			switchMap(dataset =>
				graphql
					.mutate<DeleteDatasetMutation>({
						mutation: DELETE_DATASET,
						variables: {
							_id: dataset._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteDataset) {
								throw new Error('error deleting Dataset from the database');
							}
							store.dispatch(datasetsActions.reload());
							return datasetsActions.deleteSuccess({
								_id: dataset._id
							});
						}),
						take(1),
						catchError(error => of(datasetsActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveDatasetFromTemplate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(datasetsActions.saveFromTemplate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([dataset, appId]) =>
				graphql
					.mutate<SaveDatasetFromTemplateMutation>({
						mutation: SAVE_DATASET_FROM_TEMPLATE,
						variables: {
							_id: dataset.id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveDatasetFromTemplate) {
								throw new Error('error duplicating Dataset to the database');
							}
							store.dispatch(datasetsActions.reload());
							return datasetsActions.duplicateSuccess({
								data: data.data.saveDatasetFromTemplate
							});
						}),
						take(1),
						catchError(error => of(datasetsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveDatasetSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Dataset successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveDatsetFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Dataset!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateDatasetSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Dataset successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateDatasetFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Dataset!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteDatasetSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Dataset successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteDatasetFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(datasetsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Dataset!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const returnParentRoutes = createEffect(
	(actions$ = inject(Actions), store = inject(Store)) =>
		actions$.pipe(
			ofType(datasetsActions.getParentRoutes),
			withLatestFrom(
				store.pipe(select(themesFeature.selectThemes)),
				store.pipe(select(datasetsFeature.selectDatasets)),
			),
			map(([parent, themes, datasets]) => {
				let themeName = '';
				let datasetName = '';

				for (const _id of parent.ids) {
					const foundTheme = themes.find(theme => theme._id === _id);
					const foundDataset = datasets.find(dataset => dataset._id === _id);

					if (foundTheme) {
						themeName = foundTheme.id as string;
					}

					if (foundDataset) {
						datasetName = foundDataset.id as string;
					}
				}

				const parentRoute: string[] = [];
				if (themeName) {
					parentRoute.push(themeName);
				}
				if (datasetName) {
					parentRoute.push(datasetName);
				}

				return datasetsActions.getParentRouteSuccess({
					data: parentRoute
				});
			})
		),
	{  functional: true }
);
