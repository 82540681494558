import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { Router } from '@angular/router';

import { DatasetEditorService } from '../dataset-editor.service';

@Component({
	selector: 'yuno-admin-dataset-listview-basic',
	standalone: true,
	template: ``
})
export class DatasetListviewBasicComponent {
	readonly destroyRef = inject(DestroyRef);
	readonly router = inject(Router);
	readonly cdr = inject(ChangeDetectorRef);
	readonly service = inject(DatasetEditorService);

	onEdit(route: string, id?: string, routeName?: string) {
		if (!id) {
			return;
		}

		this.router.navigate([route, id], { relativeTo: this.service.route });
	}
}
