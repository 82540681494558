<form [formGroup]="service.configForm" class="grid grid-cols-1 gap-4">
	<yuno-forms-title class="col-span-full">Help</yuno-forms-title>

	<section formGroupName="help">
		<yuno-forms-toggle formControlName="active" label="Active"></yuno-forms-toggle>
	</section>

	<section [formGroup]="service.helpTextfields" class="grid grid-cols-2 gap-4">
		<yuno-forms-select
			formControlName="desktop"
			label="Desktop"
			[selectValues]="service.defaultHelpTextSelect"
			[display]="service.defaultHelpTextDisplay"></yuno-forms-select>
		<yuno-forms-select
			formControlName="mobile"
			label="Mobile"
			[selectValues]="service.defaultHelpMobileSelect"
			[display]="service.defaultHelpMobileDisplay"></yuno-forms-select>
	</section>
</form>
