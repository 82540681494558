import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, take } from 'rxjs';

import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { ParticipationModel } from '@yuno/api/interface';

import { ParticipationModelFacade } from '../../../data-access';
import { ParticipationModelCRMComponent } from './components/crm/crm.component';
import { ParticipationModelEditorGeneralComponent } from './components/general/general.component';
import { ParticipationModelEditorReactionComponent } from './components/reaction/reaction.component';
import { ParticipationModelEditorStepsComponent } from './components/steps/steps.component';
import { ParticipationModelEditorStyleComponent } from './components/style/style.component';
import { ParticipationModelEditorUsersComponent } from './components/users/users.component';
import { ParticipationModelEditorViewerComponent } from './components/viewer/viewer.component';
import { ParticipationSettingsEditorService, TabOptions } from './settings-editor.service';

@Component({
	selector: 'yuno-admin-participation-settings',
	standalone: true,
	imports: [
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		ParticipationModelEditorGeneralComponent,
		ParticipationModelEditorReactionComponent,
		ParticipationModelEditorStepsComponent,
		ParticipationModelEditorUsersComponent,
		ParticipationModelEditorViewerComponent,
		ParticipationModelEditorStyleComponent,
		ParticipationModelCRMComponent,
		AsyncPipe,
		EditContainerContentDirective
	],
	providers: [ParticipationSettingsEditorService],
	templateUrl: './settings-editor.component.html',
	styleUrls: ['./settings-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationSettingsEditorComponent
	extends AppDataComponent
	implements OnInit, OnDestroy
{
	readonly service = inject(ParticipationSettingsEditorService);
	private readonly facade = inject(ParticipationModelFacade);

	private readonly destroyRef = inject(DestroyRef);

	@HostBinding('style.fixed') isPopup = false;

	@HostBinding('style.zIndex') get zIndex() {
		return this.isPopup ? 201 : 150;
	}

	data$ = this.service.data$;

	ngOnInit() {
		this.service.init();

		this.onChanges();
		this.onRouting();

		if (this.service.tabValue === 'Settings') {
			this.languageSelector();
		}
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => {
				this.facade.updateSelected(this.service.form.getRawValue() as ParticipationModel);
				this.service.rawForm$.next(this.service.form.getRawValue() as ParticipationModel);
				if (this.isPopup) {
					this.service.getParentRoute(this.allRouteParams);
				}
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});
	}

	onSave() {
		this.facade.save();
	}

	onClose(): void {
		redirectTo(this.route, this.router);
		this.facade.restoreOriginal();
	}

	onSwitchTab(key: string) {
		this.service.tabValue = key as TabOptions;

		if (this.service.tabValue === 'Settings') {
			this.languageSelector();
		} else {
			this.languageService.destroyLanguageSelector();
		}
	}

	ngOnDestroy() {
		this.editContainerBreadcrumbsService.removeRouteName(this.service.originalData?.id);
		this.languageService.destroyLanguageSelector();
	}
}
