import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, tap } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { PlaceMarkerAlignmentSelectorComponent } from '../../../../alignment-selector/alignment-selector.component';
import { CategoryEditorService } from '../../../category-editor.service';

@Component({
	selector: 'yuno-admin-category-style-editor',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		PlaceMarkerAlignmentSelectorComponent,
		YunoFormsModule,
		SpritesheetModule,
		AsyncPipe
	],
	templateUrl: './category-style-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryStyleEditorComponent extends AppDataComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);

	private readonly categoryFacade = inject(MarkerCategoriesFacade);

	service = inject(CategoryEditorService);

	enabled = false;
	data$ = combineLatest({
		selected: this.categoryFacade.selectedStyle$.pipe(
			tap(() => {
				this.enabled = false;

				// updates the form to use the correct index
				setTimeout(() => {
					this.enabled = true;
					this.cdr.detectChanges();
				}, 0);
			})
		)
	});

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	getIcon(index: number): string | null {
		return this.service.styles.controls[index].controls.style?.get('icon')?.value || null;
	}

	setIcon(icon: string, index: number): void {
		this.service.styles.controls[index].controls.style?.get('icon')?.patchValue(icon);
	}
}
