import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';

import { Fence, Marker, MarkerCategory, MarkerCategoryCluster } from '@yuno/api/interface';

export const markerCategoriesActions = createActionGroup({
	source: 'MarkerCategories',
	events: {
		create: emptyProps(),
		createSuccess: props<{ id: string }>(),
		createFailure: props<{ error: Error }>(),
		createTemplate: props<{ _id: string }>(),
		createTemplateSuccess: props<{ id: string }>(),

		load: emptyProps(),
		loadSuccess: props<{ data: Partial<MarkerCategory>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<MarkerCategory> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<MarkerCategory> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<MarkerCategory> }>(),
		updateSelectSuccess: props<{ data: Partial<MarkerCategory> }>(),
		updateSelectFailure: props<{ error: Error }>(),
		updateCluster: props<{ data: Partial<MarkerCategoryCluster> }>(),

		selectInput: props<{ index: number }>(),
		selectStyle: props<{ index: number }>(),

		loadMarkers: props<{ category: string }>(),
		loadMarkersSuccess: props<{ markers: Partial<Marker>[]; fences: Partial<Fence>[] }>(),
		loadMarkersFailure: props<{ error: Error }>(),
		reloadMarkers: props<{ category: string }>(),

		loadMapStyle: props<{ style: string }>(),
		loadMapStyleSuccess: props<{ data: StyleSpecification }>(),
		loadMapStyleFailure: props<{ error: Error }>(),

		selectMarker: props<{ _id: string }>(),
		clearMarker: emptyProps(),
		selectMarkerSuccess: props<{ data: Partial<Marker> }>(),
		selectMarkerFailure: props<{ error: Error }>(),
		updateMarker: props<{ data: Partial<Marker> }>(),

		saveMarker: props<{ fences?: { [key: string]: Fence } }>(),
		saveMarkerSuccess: props<{ data: Partial<Marker> }>(),
		saveMarkerFailure: props<{ error: Error }>(),

		saveFence: emptyProps(),
		saveFenceSuccess: emptyProps(),
		saveFenceFailure: props<{ error: Error }>(),

		deleteMarker: props<{ _id: string }>(),
		deleteMarkerSuccess: props<{ _id: string }>(),
		deleteMarkerFailure: props<{ error: Error }>()
	}
});
