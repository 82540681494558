module.exports = {
  "0": "22",
  "1": "23",
  "2": "24",
  "3": "25",
  "4": "26",
  "5": "115",
  "6": "116",
  "7": "117",
  "8": "118",
  "9": "119",
  A: "10",
  B: "11",
  C: "12",
  D: "7",
  E: "8",
  F: "9",
  G: "15",
  H: "80",
  I: "131",
  J: "4",
  K: "6",
  L: "13",
  M: "14",
  N: "16",
  O: "17",
  P: "18",
  Q: "79",
  R: "81",
  S: "83",
  T: "84",
  U: "85",
  V: "86",
  W: "87",
  X: "88",
  Y: "89",
  Z: "90",
  a: "91",
  b: "92",
  c: "93",
  d: "94",
  e: "95",
  f: "96",
  g: "97",
  h: "98",
  i: "99",
  j: "100",
  k: "101",
  l: "102",
  m: "103",
  n: "104",
  o: "105",
  p: "106",
  q: "107",
  r: "108",
  s: "109",
  t: "110",
  u: "111",
  v: "112",
  w: "113",
  x: "114",
  y: "20",
  z: "21",
  AB: "120",
  BB: "121",
  CB: "122",
  DB: "123",
  EB: "124",
  FB: "125",
  GB: "126",
  HB: "127",
  IB: "128",
  JB: "129",
  KB: "130",
  LB: "5",
  MB: "19",
  NB: "27",
  OB: "28",
  PB: "29",
  QB: "30",
  RB: "31",
  SB: "32",
  TB: "33",
  UB: "34",
  VB: "35",
  WB: "36",
  XB: "37",
  YB: "38",
  ZB: "39",
  aB: "40",
  bB: "41",
  cB: "42",
  dB: "43",
  eB: "44",
  fB: "45",
  gB: "46",
  hB: "47",
  iB: "48",
  jB: "49",
  kB: "50",
  lB: "51",
  mB: "52",
  nB: "53",
  oB: "54",
  pB: "55",
  qB: "56",
  rB: "57",
  sB: "58",
  tB: "60",
  uB: "62",
  vB: "63",
  wB: "64",
  xB: "65",
  yB: "66",
  zB: "67",
  "0B": "68",
  "1B": "69",
  "2B": "70",
  "3B": "71",
  "4B": "72",
  "5B": "73",
  "6B": "74",
  "7B": "75",
  "8B": "76",
  "9B": "77",
  AC: "78",
  BC: "132",
  CC: "11.1",
  DC: "12.1",
  EC: "15.5",
  FC: "16.0",
  GC: "17.0",
  HC: "18.0",
  IC: "3",
  JC: "59",
  KC: "61",
  LC: "82",
  MC: "133",
  NC: "134",
  OC: "3.2",
  PC: "10.1",
  QC: "15.2-15.3",
  RC: "15.4",
  SC: "16.1",
  TC: "16.2",
  UC: "16.3",
  VC: "16.4",
  WC: "16.5",
  XC: "17.1",
  YC: "17.2",
  ZC: "17.3",
  aC: "17.4",
  bC: "17.5",
  cC: "18.1",
  dC: "18.2",
  eC: "11.5",
  fC: "4.2-4.3",
  gC: "5.5",
  hC: "2",
  iC: "135",
  jC: "136",
  kC: "3.5",
  lC: "3.6",
  mC: "3.1",
  nC: "5.1",
  oC: "6.1",
  pC: "7.1",
  qC: "9.1",
  rC: "13.1",
  sC: "14.1",
  tC: "15.1",
  uC: "15.6",
  vC: "16.6",
  wC: "17.6",
  xC: "TP",
  yC: "9.5-9.6",
  zC: "10.0-10.1",
  "0C": "10.5",
  "1C": "10.6",
  "2C": "11.6",
  "3C": "4.0-4.1",
  "4C": "5.0-5.1",
  "5C": "6.0-6.1",
  "6C": "7.0-7.1",
  "7C": "8.1-8.4",
  "8C": "9.0-9.2",
  "9C": "9.3",
  AD: "10.0-10.2",
  BD: "10.3",
  CD: "11.0-11.2",
  DD: "11.3-11.4",
  ED: "12.0-12.1",
  FD: "12.2-12.5",
  GD: "13.0-13.1",
  HD: "13.2",
  ID: "13.3",
  JD: "13.4-13.7",
  KD: "14.0-14.4",
  LD: "14.5-14.8",
  MD: "15.0-15.1",
  ND: "15.6-15.8",
  OD: "16.6-16.7",
  PD: "17.6-17.7",
  QD: "all",
  RD: "2.1",
  SD: "2.2",
  TD: "2.3",
  UD: "4.1",
  VD: "4.4",
  WD: "4.4.3-4.4.4",
  XD: "5.0-5.4",
  YD: "6.2-6.4",
  ZD: "7.2-7.4",
  aD: "8.2",
  bD: "9.2",
  cD: "11.1-11.2",
  dD: "12.0",
  eD: "13.0",
  fD: "14.0",
  gD: "15.0",
  hD: "19.0",
  iD: "14.9",
  jD: "13.52",
  kD: "2.5",
  lD: "3.0-3.1"
};