{
	"name": "yuno",
	"version": "7.8.22",
	"license": "MIT",
	"private": true,
	"homepage": "//xkp-visual-engineers.github.io/yuno-project-atlas",
	"scripts": {
		"ng": "nx",
		"start": "nx serve",
		"merge-main": "git merge origin/main",
		"build:azure": "npx nx affected -t lint build --exclude '*,!tag:scope:azure' --configuration=production --maxParallel=3",
		"build:all": "NODE_OPTIONS=--max-old-space-size=8192 npx nx run-many --all",
		"build:docker": "NODE_OPTIONS=--max-old-space-size=8192 npx nx run-many --target=docker-build --all --no-cloud",
		"build:docker:azure": "NODE_OPTIONS=--max-old-space-size=8192 npx nx run-many --target=docker-build --exclude '*,!tag:scope:azure' --no-cloud",
		"build:docker:dev": "NODE_OPTIONS=--max-old-space-size=8192 npx nx run-many --target=docker-build --configuration=dev --all --no-cloud",
		"build:docker:nodes": "NODE_OPTIONS=--max-old-space-size=4096 npx nx run-many --target=docker-build --all --parallel=false --no-cloud",
		"build:docker:dev:nodes": "NODE_OPTIONS=--max-old-space-size=4096 npx nx run-many --target=docker-build  --configuration=dev --all --parallel=false --no-cloud",
		"affected:test": "nx affected:test",
		"remove-node-modules": "rm -rf node_modules",
		"clean-local-branches": "git branch | grep -v \"main\" | xargs git branch -D",
		"test": "nx format:write && yarn nx run-many --target=lint && nx affected:test",
		"test:all": "nx format:write --all && yarn nx run-many --all --target=lint && nx affected:test",
		"test:skip": "nx run-many --all --target=test --skip-nx-cache",
		"test:build": "nx format:write --all && yarn nx affected --target=lint --parallel=3 && yarn nx affected --target=test --parallel=3 --ci --code-coverage && yarn nx affected --target=build --parallel=3",
		"test:affected": "nx affected:test",
		"format": "yarn nx-cloud record -- yarn prettier --write . && yarn nx-cloud record -- yarn prettier --check .",
		"lint": "nx run-many --all --target=lint",
		"dev:yuno": "nx serve yuno --parallel",
		"dev:admin": "nx serve admin --parallel",
		"dev:portal": "nx serve admin --parallel",
		"dev:panorama": "nx serve panorama-viewer --parallel",
		"dev:project-atlas": "nx serve project-atlas --parallel",
		"dev:api": "NODE_ENV=development nx serve api --parallel",
		"dev:main-api": "NODE_ENV=development nx serve api --parallel",
		"dev:tileserver": "NODE_ENV=development nx serve tileserver --parallel",
		"dev:tileserver-generator": "NODE_ENV=development nx serve tileserver-generator --parallel",
		"dev:sprites-generator": "NODE_ENV=development nx serve sprites-generator --parallel",
		"dev:storage-api": "NODE_ENV=development nx serve storage-api --parallel",
		"build": "yarn nx run project-atlas:build:production && yarn nx run admin:build:production && yarn nx run api:build:production",
		"build:dev": "yarn nx run project-atlas:build:dev && yarn nx run admin:build:dev && yarn nx run api:build:dev",
		"build:project-atlas": "yarn nx run project-atlas:build:prod --stats-json",
		"build:project-atlas:dev": "yarn nx run project-atlas:build:dev",
		"build:project-atlas:prod": "yarn nx run project-atlas:build:production",
		"build:panorama:dev": "yarn nx run api:panorama:dev",
		"build:panorama-viewer": "yarn nx run panorama-viewer:build:production",
		"build:panorama-viewer:docker": "yarn nx run panorama-viewer:docker-build --no-cloud",
		"build:panorama-viewer:docker:dev": "yarn nx run panorama-viewer:docker-build --configuration=dev --no-cloud",
		"build:panorama-viewer:docker:production": "yarn nx run panorama-viewer:docker-build --configuration=prod --no-cloud",
		"build:yuno": "yarn nx run yuno:build:prod --stats-json",
		"build:yuno:dev": "yarn nx run yuno:build:dev",
		"build:yuno:prod": "yarn nx run yuno:build:production",
		"build:admin": "yarn nx run admin:build:prod  --stats-json",
		"build:admin:dev": "yarn nx run admin:build:dev",
		"build:admin:prod": "yarn nx run admin:build:production",
		"build:tiles-generator-api": "yarn nx run tileserver-generator:build:production",
		"build:tiles-generator-api:docker": "yarn nx run tileserver-generator:docker-build --no-cloud",
		"build:tiles-generator-api:docker:dev": "yarn nx run tileserver-generator:docker-build --configuration=dev --no-cloud",
		"build:tiles-generator-api:docker:production": "yarn nx run tileserver-generator:docker-build --configuration=prod --no-cloud",
		"build:sprites-generator": "yarn nx run sprites-generator:build:production",
		"build:sprites-generator:docker": "yarn nx run sprites-generator:docker-build --no-cloud",
		"build:sprites-generator:docker:dev": "yarn nx run sprites-generator:docker-build --configuration=dev --no-cloud",
		"build:sprites-generator:docker:production": "yarn nx run sprites-generator:docker-build --configuration=prod --no-cloud",
		"build:tileserver-api": "yarn nx run tileserver:build:production",
		"build:tileserver-api:docker": "yarn nx run tileserver:docker-build --no-cloud",
		"build:tileserver-api:docker:dev": "yarn nx run tileserver:docker-build --configuration=dev --no-cloud",
		"build:tileserver-api:docker:production": "yarn nx run tileserver:docker-build --configuration=prod --no-cloud",
		"build:storage-api": "yarn nx run storage-api:build:production",
		"build:storage-api:docker": "yarn nx run storage-api:docker-build --no-cloud",
		"build:storage-api:docker:dev": "yarn nx run storage-api:docker-build --configuration=dev --no-cloud",
		"build:storage-api:docker:production": "yarn nx run storage-api:docker-build --configuration=prod --no-cloud",
		"build:main-api": "yarn nx run api:build:production",
		"build:main-api:dev": "yarn nx run api:build:dev",
		"build:main-api:docker": "yarn nx run api:docker-build --no-cloud",
		"build:main-api:docker:dev": "yarn nx run api:docker-build --configuration=dev --no-cloud",
		"build:main-api:docker:production": "yarn nx run api:docker-build --configuration=prod  --no-cloud",
		"serve:api": "NODE_ENV=production node ./dist/apps/api/main.js",
		"storybook": "nx run storybook:storybook",
		"build-storybook": "nx run storybook:build-storybook --skip-nx-cache",
		"analyze:yuno": "cd ./dist/apps/yuno && npx esbuild-analyzer --metafile=stats.json",
		"analyze:admin": "webpack-bundle-analyzer dist/apps/admin/stats.json",
		"analyze:project-atlas": "webpack-bundle-analyzer dist/apps/project-atlas/stats.json",
		"generate:ngx-graphql": "ng run angular-graphql:generate",
		"upgrade-packages": "npx taze"
	},
	"dependencies": {
		"@angular/animations": "18.2.12",
		"@angular/cdk": "18.2.12",
		"@angular/common": "18.2.12",
		"@angular/compiler": "18.2.12",
		"@angular/core": "18.2.12",
		"@angular/elements": "18.2.12",
		"@angular/forms": "18.2.12",
		"@angular/material": "18.2.12",
		"@angular/platform-browser": "18.2.12",
		"@angular/platform-browser-dynamic": "18.2.12",
		"@angular/platform-server": "18.2.12",
		"@angular/router": "18.2.12",
		"@angular/service-worker": "18.2.12",
		"@angular/ssr": "18.2.12",
		"@apollo/client": "^3.9.5",
		"@apollo/server": "^4.10.0",
		"@azure/identity": "^4.0.1",
		"@azure/keyvault-secrets": "^4.8.0",
		"@mapbox/mapbox-gl-draw": "^1.4.3",
		"@mapbox/mbtiles": "^0.12.1",
		"@mapbox/tilelive": "^6.1.1",
		"@maplibre/maplibre-gl-style-spec": "^20.3.1",
		"@maplibre/ngx-maplibre-gl": "^18.1.1",
		"@nestjs/apollo": "^12.1.0",
		"@nestjs/axios": "^3.0.2",
		"@nestjs/common": "^10.3.3",
		"@nestjs/config": "^3.2.0",
		"@nestjs/core": "^10.3.3",
		"@nestjs/graphql": "^12.1.1",
		"@nestjs/mongoose": "^10.0.4",
		"@nestjs/passport": "^10.0.3",
		"@nestjs/platform-express": "^10.3.3",
		"@nestjs/swagger": "^7.3.0",
		"@nestjs/terminus": "^10.2.3",
		"@nestjs/throttler": "^5.1.2",
		"@ngneat/hot-toast": "^6.1.0",
		"@ngneat/overview": "^5.1.1",
		"@ngneat/transloco": "6.0.4",
		"@ngrx/component-store": "18.0.2",
		"@ngrx/effects": "18.0.2",
		"@ngrx/entity": "18.0.2",
		"@ngrx/operators": "^18.0.0",
		"@ngrx/router-store": "18.0.2",
		"@ngrx/signals": "^17.2.0",
		"@ngrx/store": "18.0.2",
		"@nx/angular": "20.1.1",
		"@panzoom/panzoom": "^4.5.1",
		"@placemarkio/geo-viewport": "^1.0.2",
		"@pnext/three-loader": "0.2.9",
		"@storybook/addon-interactions": "^8.3.1",
		"@tailwindcss/aspect-ratio": "^0.4.2",
		"@tinymce/tinymce-angular": "7.0.0",
		"@tmcw/togeojson": "^5.8.1",
		"@turf/area": "^6.5.0",
		"@turf/bbox": "^6.5.0",
		"@turf/bbox-polygon": "^6.5.0",
		"@turf/bearing": "^6.5.0",
		"@turf/boolean-equal": "^6.5.0",
		"@turf/boolean-point-in-polygon": "^6.5.0",
		"@turf/buffer": "^6.5.0",
		"@turf/center": "^6.5.0",
		"@turf/center-of-mass": "^6.5.0",
		"@turf/distance": "^6.5.0",
		"@turf/helpers": "^6.5.0",
		"@turf/length": "^6.5.0",
		"@turf/line-to-polygon": "^6.5.0",
		"apollo-angular": "^6.0.0",
		"archiver": "^6.0.2",
		"argon2": "^0.31.2",
		"autoprefixer": "10.4.16",
		"axios": "^1.6.7",
		"bson": "^6.4.0",
		"class-transformer": "^0.5.1",
		"class-validator": "^0.14.1",
		"color": "^4.2.3",
		"connect-mongo": "^5.1.0",
		"convert-excel-to-json": "^1.7.0",
		"cookie-parser": "^1.4.7",
		"date-fns": "^2.30.0",
		"dot-object": "^2.1.4",
		"email-validator": "^2.0.4",
		"exifreader": "4.23.5",
		"express": "~4.18.3",
		"express-session": "^1.18.0",
		"fs-extra": "^11.2.0",
		"googleapis": "^129.0.0",
		"graphql": "^16.8.1",
		"graphql-type-json": "^0.3.2",
		"gridfs-stream": "^1.1.1",
		"jest-canvas-mock": "^2.5.2",
		"json-2-csv": "^5.5.4",
		"jsonwebtoken": "^9.0.2",
		"juice": "^10.0.0",
		"lodash": "^4.17.21",
		"mapbox-gl-draw-circle": "^1.1.2",
		"mapbox-gl-draw-rectangle-mode": "^1.0.4",
		"mapbox-gl-draw-scale-rotate": "^1.1.0",
		"maplibre-gl": "^4.7.0",
		"meshline": "^3.2.0",
		"mime-types": "^2.1.35",
		"modern-normalize": "^2.0.0",
		"monaco-editor": "^0.44.0",
		"mongo-gridfs": "^1.1.0",
		"mongodb": "^6.4.0",
		"mongoose": "8.0.3",
		"mongoose-autopopulate": "^1.1.0",
		"mongoose-unique-validator": "^5.0.0",
		"multer": "1.4.5-lts.1",
		"multer-gridfs-storage": "meme8383/multer-gridfs-storage",
		"nestjs-i18n": "^10.4.9",
		"ngx-color-picker": "^16.0.0",
		"ngx-cookie-service": "^17.1.0",
		"ngx-infinite-scroll": "^17.0.0",
		"ngx-monaco-editor-v2": "17.0.1",
		"nodemailer": "^6.9.11",
		"nunjucks": "^3.2.4",
		"nunjucks-date": "^1.5.0",
		"passport": "^0.7.0",
		"passport-google-oauth20": "^2.0.0",
		"passport-jwt": "^4.0.1",
		"passport-local": "^1.0.0",
		"pmtiles": "^3.0.7",
		"potree-loader": "^1.10.4",
		"proj4": "^2.10.0",
		"psl": "^1.9.0",
		"qrcode": "^1.5.3",
		"reflect-metadata": "^0.1.14",
		"resize-observer-polyfill": "^1.5.1",
		"rxjs": "~7.8.1",
		"sanitize-html": "2.11.0",
		"shapefile": "xkp-visual-engineers/shapefile",
		"sharp": "0.33.0",
		"skott": "^0.35.2",
		"slug": "^9.1.0",
		"slugify": "^1.6.6",
		"supercluster": "8.0.1",
		"swagger-ui-dist": "^5.11.8",
		"swagger-ui-express": "^5.0.0",
		"three": "0.139.2",
		"three-stdlib": "2.25.0",
		"tsconfig-paths": "^4.2.0",
		"tslib": "^2.6.2",
		"uuid": "^9.0.1",
		"xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
		"xmlbuilder2": "^3.1.1",
		"zone.js": "0.14.3"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "18.2.12",
		"@angular-devkit/core": "18.2.12",
		"@angular-devkit/schematics": "18.2.12",
		"@angular-eslint/eslint-plugin": "18.3.1",
		"@angular-eslint/eslint-plugin-template": "18.3.1",
		"@angular-eslint/template-parser": "18.3.1",
		"@angular/cli": "~18.2.0",
		"@angular/compiler-cli": "18.2.12",
		"@angular/language-service": "18.2.12",
		"@chromatic-com/storybook": "^1",
		"@nestjs/schematics": "^10.1.1",
		"@nestjs/testing": "^10.3.3",
		"@ngneat/transloco-scoped-libs": "^5.0.3",
		"@ngrx/schematics": "18.0.2",
		"@ngrx/signals": "18.0.2",
		"@ngrx/store-devtools": "18.0.2",
		"@nx/esbuild": "20.1.1",
		"@nx/eslint": "20.1.1",
		"@nx/eslint-plugin": "20.1.1",
		"@nx/jest": "20.1.1",
		"@nx/js": "20.1.1",
		"@nx/nest": "20.1.1",
		"@nx/node": "20.1.1",
		"@nx/storybook": "20.1.1",
		"@nx/web": "20.1.1",
		"@nx/webpack": "20.1.1",
		"@nx/workspace": "20.1.1",
		"@schematics/angular": "18.2.12",
		"@storybook/addon-a11y": "^8.3.1",
		"@storybook/addon-essentials": "^8.3.1",
		"@storybook/addon-mdx-gfm": "^8.3.1",
		"@storybook/addon-postcss": "2.0.0",
		"@storybook/angular": "^8.3.1",
		"@storybook/core-server": "^8.3.1",
		"@swc-node/register": "1.9.2",
		"@swc/core": "1.5.7",
		"@swc/helpers": "0.5.13",
		"@tailwindcss/forms": "^0.5.7",
		"@tailwindcss/typography": "^0.5.10",
		"@testing-library/jest-dom": "6.1.5",
		"@testing-library/jest-native": "5.4.3",
		"@trivago/prettier-plugin-sort-imports": "^4.3.0",
		"@types/archiver": "^6.0.2",
		"@types/color": "^3.0.6",
		"@types/convert-excel-to-json": "^1.7.4",
		"@types/cookie-parser": "^1.4.7",
		"@types/dot-object": "^2.1.6",
		"@types/express": "4.17.21",
		"@types/express-session": "^1.18.0",
		"@types/fs-extra": "^11.0.4",
		"@types/geojson": "^7946.0.14",
		"@types/gridfs-stream": "^0.5.39",
		"@types/jest": "29.5.13",
		"@types/jsonwebtoken": "^9.0.6",
		"@types/lodash": "^4.14.202",
		"@types/mapbox__mapbox-gl-draw": "^1.4.6",
		"@types/mapbox__point-geometry": "^0.1.4",
		"@types/mime-types": "^2.1.4",
		"@types/mongoose-unique-validator": "^1.0.9",
		"@types/multer": "1.4.11",
		"@types/node": "18.19.11",
		"@types/nodemailer": "^6.4.14",
		"@types/nunjucks": "^3.2.6",
		"@types/nunjucks-date": "^0.0.10",
		"@types/passport": "^1.0.16",
		"@types/passport-google-oauth20": "^2.0.14",
		"@types/passport-jwt": "^3.0.13",
		"@types/passport-local": "^1.0.38",
		"@types/proj4": "^2.5.5",
		"@types/psl": "^1.1.3",
		"@types/qrcode": "^1.5.5",
		"@types/sanitize-html": "^2.11.0",
		"@types/shapefile": "^0.6.4",
		"@types/slug": "^5.0.7",
		"@types/supercluster": "7.1.3",
		"@types/three": "0.135.0",
		"@types/uuid": "^9.0.8",
		"@typescript-eslint/eslint-plugin": "7.18.0",
		"@typescript-eslint/parser": "7.18.0",
		"@typescript-eslint/utils": "^7.16.0",
		"esbuild": "0.19.9",
		"eslint": "8.57.0",
		"eslint-config-prettier": "9.1.0",
		"eslint-plugin-cypress": "^3.0.2",
		"eslint-plugin-import": "2.29.1",
		"eslint-plugin-storybook": "^0.8.0",
		"jasmine-marbles": "~0.9.2",
		"jest": "29.7.0",
		"jest-circus": "^29.7.0",
		"jest-environment-jsdom": "29.7.0",
		"jest-environment-node": "^29.7.0",
		"jest-preset-angular": "14.1.0",
		"jsdom-worker": "^0.3.0",
		"jsonc-eslint-parser": "^2.4.0",
		"ng-packagr": "18.2.1",
		"nx": "20.1.1",
		"postcss": "^8.4.35",
		"postcss-import": "15.1.0",
		"postcss-preset-env": "9.3.0",
		"postcss-url": "10.1.3",
		"prettier": "^3.2.5",
		"prettier-plugin-tailwindcss": "^0.5.14",
		"storybook": "^8.3.1",
		"storybook-addon-angular-router": "^1.10.0",
		"storybook-addon-paddings": "^6.0.2",
		"storybook-dark-mode": "^4.0.2",
		"tailwindcss": "^3.4.1",
		"ts-jest": "29.1.1",
		"ts-node": "10.9.2",
		"typescript": "5.5.4",
		"webpack": "5",
		"webpack-cli": "^5.1.4",
		"webpack-node-externals": "^3.0.0"
	},
	"config": {
		"mongodbMemoryServer": {
			"debug": "0"
		}
	},
	"browser": {
		"fs": false,
		"os": false,
		"path": false
	},
	"engines": {
		"yarn": ">=4.1.1",
		"node": ">=20.0.0"
	},
	"packageManager": "yarn@4.2.2"
}
