@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-2">
		<yuno-forms-title>Viewer Settings</yuno-forms-title>
		<yuno-forms-span>
			When no selection is made, the lowress World map will be loaded
		</yuno-forms-span>
		<yuno-forms-select
			class="mb-4"
			formControlName="theme"
			[selectValues]="service.themeSelect"
			[display]="service.themeDisplay"
			label="Theme">
		</yuno-forms-select>
		<yuno-forms-span> The data seen inside the Participate Viewer </yuno-forms-span>
		<yuno-forms-span> Drag and Drop between "All keys" and List/Reaction View </yuno-forms-span>
		<span class="text-base font-semibold"> List </span>
		<yuno-forms-span> Add the keys you want to show inside the List View </yuno-forms-span>
		<div
			class="mt-2 grid grid-cols-1 gap-1 rounded-md bg-white p-2"
			id="listKeys"
			#listKeys="cdkDropList"
			cdkDropList
			[cdkDropListConnectedTo]="[formList]"
			(cdkDropListDropped)="dropListKeys($event, service.getListKeys)">
			@if (service.getListKeys.value.length) {
				@for (key of service.getListKeys.value; track key; let i = $index) {
					<yuno-admin-drag-row cdkDrag [keepButtons]="true">
						<span title> {{ key }} </span>
						<ng-container buttons>
							<button yuno-admin-delete-admin-button (click)="removeListKey(i)">
								Delete
							</button>
						</ng-container>
					</yuno-admin-drag-row>
				}
			} @else {
				<div
					class="flex h-15 select-none items-center rounded-md border border-gray-300 bg-gray-200 pl-4 text-gray-500">
					No keys added.
				</div>
			}
		</div>
		<span class="mt-8 text-base font-semibold"> Reaction View </span>
		<yuno-forms-span> Add the keys you want to show inside the Reaction </yuno-forms-span>
		<div class="grid grid-cols-2 gap-2 rounded-md bg-white p-2">
			<div
				id="listLeft"
				class="flex flex-col gap-1"
				#listLeft="cdkDropList"
				cdkDropList
				[cdkDropListConnectedTo]="[formList, listRight]"
				(cdkDropListDropped)="
					dropReactionKey($event, service.getRightKeys, service.getLeftKeys)
				">
				@if (service.getLeftKeys.value.length) {
					@for (key of service.getLeftKeys.value; track key; let i = $index) {
						<yuno-admin-drag-row cdkDrag [keepButtons]="true">
							<span title> {{ key }} </span>
							<ng-container buttons>
								<button yuno-admin-delete-admin-button (click)="removeLeftKey(i)">
									Delete
								</button>
							</ng-container>
						</yuno-admin-drag-row>
					}
				} @else {
					<div
						class="flex h-15 select-none items-center rounded-md border border-gray-300 bg-gray-200 pl-4 text-gray-500">
						No keys added.
					</div>
				}
			</div>
			<div
				class="flex flex-col gap-1"
				id="listRight"
				#listRight="cdkDropList"
				cdkDropList
				[cdkDropListConnectedTo]="[formList, listLeft]"
				(cdkDropListDropped)="
					dropReactionKey($event, service.getLeftKeys, service.getRightKeys)
				">
				@if (service.getRightKeys.value.length) {
					@for (key of service.getRightKeys.value; track key; let i = $index) {
						<yuno-admin-drag-row cdkDrag [keepButtons]="true">
							<span title> {{ key }} </span>
							<ng-container buttons>
								<button yuno-admin-delete-admin-button (click)="removeRightKey(i)">
									Delete
								</button>
							</ng-container>
						</yuno-admin-drag-row>
					}
				} @else {
					<div
						class="flex h-15 select-none items-center rounded-md border border-gray-300 bg-gray-200 pl-4 text-gray-500">
						No keys added.
					</div>
				}
			</div>
		</div>
		<span class="mt-8 text-base font-semibold"> All Keys </span>
		<yuno-forms-span>Drop in this list to remove it from another list</yuno-forms-span>
		<div
			class="grid grid-cols-1 gap-1 rounded-md bg-white p-2"
			#formList="cdkDropList"
			cdkDropList
			[cdkDropListData]="service.listOfKeys"
			[cdkDropListConnectedTo]="[listKeys, listLeft, listRight]"
			(cdkDropListDropped)="dropAvailable($event)">
			@for (key of service.listOfKeys; track key; let i = $index) {
				<yuno-admin-drag-row cdkDrag>
					<span title> {{ key }} </span>
				</yuno-admin-drag-row>
			}
		</div>
	</form>
}
