@if (form) {
	<form [formGroup]="form" class="h-full">
		<div
			formGroupName="style"
			class="flex h-full flex-col justify-center gap-2 rounded-md border-2 border-transparent p-2"
			[ngClass]="{
				'border-dotted border-yuno-gray-200 bg-yuno-blue-hover': type === selected
			}">
			<yuno-forms-span class="col-span-2 text-sm font-semibold">
				<ng-content></ng-content>
			</yuno-forms-span>
			<div
				class="flex flex-1 flex-row items-center justify-center rounded-md border-2 border-dotted border-yuno-gray-200 p-4">
				@if (src) {
					<yuno-svg [src]="src"></yuno-svg>
				}
			</div>
			<yuno-forms-text
				class="col-span-1"
				[formControlName]="
					type === 'default' ? 'sprite' : 'spriteSelect'
				"></yuno-forms-text>
			<button yuno-admin-button (click)="select()">Select</button>
		</div>
	</form>
}
