import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { combineLatest, map, startWith } from 'rxjs';

import { ParticipationModelFacade } from '@yuno/admin/features/participation';
import {
	TableColumnDisplay,
	TableRow,
	TableSelectionOutput,
	TableSelectionOutputNew,
	YunoAdminButtonTableComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';

import { DatasetListviewBasicComponent } from '../basic-dataset-list-component.component';

@Component({
	selector: 'yuno-admin-dataset-listview-participates',
	standalone: true,
	imports: [YunoAdminTableComponent, AsyncPipe, YunoAdminButtonTableComponent, RouterOutlet],
	templateUrl: './participates.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetListviewParticipatesComponent
	extends DatasetListviewBasicComponent
	implements OnInit
{
	private readonly facade = inject(ParticipationModelFacade);

	// Combine observables to create a data stream for the component.
	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			takeUntilDestroyed(this.destroyRef),
			map(data => data?.data?.participates?.map(data => data.participate as TableRow) || [])
		),
		// provide a clean set of data to the table
		participates: this.facade.data$.pipe(startWith(null))
	});

	// Initialize table column configuration.
	tableColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Participates' }];

	// Angular lifecycle hook, called when the component is initialized.
	ngOnInit(): void {
		this.facade.get();
	}

	// Event handler for selecting all items in the table.
	onSelectAll(e: TableSelectionOutput) {
		this.service.selectAll(
			'Participates',
			e.selection.map(data => ({ participate: data }))
		);
	}

	/*
		Triggers when selecting or deselecting a row in the table
		when adding an item, also adds an event key to the object
	*/
	onSelect(e: TableSelectionOutputNew): void {
		if (!e.checked) {
			this.service.remove('Participates', 'participate', e.row);
			return;
		}

		this.service.add('Participates', { participate: e.row });
	}
}
