import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Feature } from 'geojson';
import { MapGeoJSONFeature } from 'maplibre-gl';
import { Observable } from 'rxjs';

import { API_URL_KEY } from '@yuno/angular/api';
import { ApiData, Textfield } from '@yuno/api/interface';

import { OverlayTypes } from './textfield-overlays.state';

@Injectable({
	providedIn: 'root'
})
export class TextfieldOverlayService {
	selectedOverlay: OverlayTypes;

	constructor(
		private http: HttpClient,
		@Inject(API_URL_KEY) public apiUrl: string
	) {}

	getData(
		appId: string,
		textfield: string,
		by?: 'name' | 'id',
		feature?: Feature | MapGeoJSONFeature,
		language?: string
	): Observable<ApiData<Textfield>> {
		return this.http.post<ApiData<Textfield>>(
			`${this.apiUrl}/textfield/${appId}/${textfield}`,
			{
				properties: feature,
				by: by || 'id',
				language
			}
		);
	}
}
