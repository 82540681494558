@if (selected$ | async; as data) {
	@if (service.form) {
		<form [formGroup]="service.form">
			@if (update && data) {
				<div formArrayName="inputs">
					<div [formGroupName]="data.index || 0">
						@switch (isCustom(data.index || 0)) {
							@case (true) {
								@if (isCustom(data.index || 0)) {
									<div formGroupName="custom" class="grid grid-cols-1 gap-4">
										<yuno-forms-text
											formControlName="type"
											label="Form type"></yuno-forms-text>
										<yuno-forms-text
											formControlName="key"
											label="Form key"></yuno-forms-text>
										<yuno-forms-span
											class="rounded-md border border-red-200 bg-red-100 px-2 font-semibold text-red-500">
											This value should be unique to a single Input Field.
											When it's not, only 1 of the values will be saved to the
											database.
											<br />
											Do NOT change this when when data has been submitted.
											Doing so, will result in DATA loss!
										</yuno-forms-span>
										<section formGroupName="label">
											@for (lang of languages; track lang) {
												@if (lang === language) {
													<yuno-forms-text
														[language]="lang"
														[formControlName]="lang"
														label="Display name"></yuno-forms-text>
												}
											}
										</section>
										<yuno-forms-toggle
											formControlName="listView"
											label="Visible in List"></yuno-forms-toggle>
										<section formGroupName="placeholder">
											@for (lang of languages; track lang) {
												@if (lang === language) {
													<yuno-forms-text
														[language]="lang"
														[formControlName]="lang"
														label="Placeholder name"></yuno-forms-text>
												}
											}
										</section>
										@if (hasCustomOptions(data.index)) {
											<div
												class="flex justify-between"
												formGroupName="options">
												<yuno-forms-toggle
													formControlName="linkText"
													label="Link Text to Area"></yuno-forms-toggle>
												<yuno-forms-toggle
													formControlName="showArea"
													label="Show Area on Map"></yuno-forms-toggle>
											</div>
										}
										<yuno-forms-toggle
											formControlName="required"
											label="Required"></yuno-forms-toggle>
									</div>
								}
							}
							@case (false) {
								<div formGroupName="preset" class="grid grid-cols-1 gap-4">
									<yuno-forms-text
										formControlName="type"
										label="Form type"></yuno-forms-text>
									<section formGroupName="label">
										@for (lang of languages; track lang) {
											@if (lang === language) {
												<yuno-forms-text
													[language]="lang"
													[formControlName]="lang"
													label="Display name"></yuno-forms-text>
											}
										}
									</section>
									<yuno-forms-toggle
										formControlName="listView"
										label="Visible in List"></yuno-forms-toggle>
									<section formGroupName="placeholder">
										@for (lang of languages; track lang) {
											@if (lang === language) {
												<yuno-forms-text
													[language]="lang"
													[formControlName]="lang"
													label="Placeholder name"></yuno-forms-text>
											}
										}
									</section>
									<yuno-forms-toggle
										formControlName="required"
										label="Required"></yuno-forms-toggle>

									@if (hasOptions(data.index)) {
										<div formGroupName="options">
											<div class="flex flex-row items-center justify-between">
												<yuno-forms-title>Options</yuno-forms-title>
												<button
													yuno-admin-button
													color="primary"
													(click)="addOption(data.index)"
													[add]="true">
													Add Option
												</button>
											</div>
											<div
												class="mt-4 grid grid-cols-1 gap-1"
												formArrayName="list"
												cdkDropList
												(cdkDropListDropped)="drop($event, data.index)">
												@for (
													input of getInputOptions(data.index).controls;
													track input;
													let i = $index
												) {
													<div cdkDrag>
														<yuno-admin-drag-row [disableHeight]="true">
															<div
																class="flex flex-row items-center gap-4"
																title>
																<div
																	[formArrayName]="i"
																	class="grid w-full grid-cols-1 items-center justify-center gap-2">
																	<yuno-forms-text
																		label="Value"
																		formControlName="value"
																		class="flex-1"></yuno-forms-text>

																	<!-- LANGUAGE -->
																	<section
																		formGroupName="display">
																		@for (
																			lang of languages;
																			track lang
																		) {
																			@if (
																				lang === language
																			) {
																				<yuno-forms-text
																					[formControlName]="
																						lang
																					"
																					[language]="
																						lang
																					"
																					class="flex-1"
																					label="Display name"></yuno-forms-text>
																			}
																		}
																	</section>
																</div>
																<button
																	yuno-admin-delete-admin-button
																	(click)="
																		onRemove(data.index, i)
																	"></button>
															</div>
														</yuno-admin-drag-row>
													</div>
												}
											</div>
										</div>
									}
								</div>
							}
							@default {
								<p>Something went wrong!</p>
							}
						}
					</div>
				</div>
			}
		</form>
	}
}
