module.exports = {
  1: 'ls',
  // WHATWG Living Standard
  2: 'rec',
  // W3C Recommendation
  3: 'pr',
  // W3C Proposed Recommendation
  4: 'cr',
  // W3C Candidate Recommendation
  5: 'wd',
  // W3C Working Draft
  6: 'other',
  // Non-W3C, but reputable
  7: 'unoff' // Unofficial, Editor's Draft or W3C "Note"
};