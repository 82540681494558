import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Layer } from '@yuno/api/interface';

import { layersActions } from './layers.actions';
import { layersFeature } from './layers.state';

@Injectable()
export class LayersFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(layersFeature.selectLoaded));
	layers$ = this.store.pipe(select(layersFeature.selectLayers));
	selectedLayer$ = this.store.pipe(select(layersFeature.selectSelected));
	selectedSource$ = this.store.pipe(select(layersFeature.selectSelectedSource));
	selectedLoaded$ = this.store.pipe(select(layersFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(layersFeature.selectStatus));

	get() {
		this.store.dispatch(layersActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			this.clearSelect();
			return;
		}
		this.store.dispatch(layersActions.select({ _id }));
	}

	selectSource(id: string) {
		this.store.dispatch(layersActions.selectSource({ id }));
	}

	updateSelect(data: Partial<Layer>) {
		this.store.dispatch(layersActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(layersActions.clearSelect());
	}

	clearSource() {
		this.store.dispatch(layersActions.clearSource());
	}

	save() {
		this.store.dispatch(layersActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(layersActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(layersActions.delete({ _id }));
	}

	saveFromTemplate(id: string, name: string) {
		this.store.dispatch(layersActions.saveFromTemplate({ id, name }));
	}

}
