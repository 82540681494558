<div class="flex flex-col gap-4">
	<div class="flex">
		<h2>Input Fields</h2>
		<div class="flex-1"></div>
		<button yuno-admin-button (click)="onAddInput()" [add]="true">Add Input Field</button>
	</div>
	<yuno-forms-span>
		Set the input fields to be added to each marker in this category. Drag the labels to
		determine the order in which the input fields are displayed when placing a new marker.
	</yuno-forms-span>
	<yuno-forms-span> Items marked with "*" are standard input fields </yuno-forms-span>

	@if (service.form) {
		<form [formGroup]="service.form" class="mt-4">
			<div class="grid grid-cols-1 gap-1" cdkDropList (cdkDropListDropped)="drop($event)">
				@for (input of service.inputs.controls; track input; let i = $index) {
					<div cdkDrag cdkDragLockAxis="y">
						<yuno-admin-drag-row (click)="onSelectInput(i)" [keepButtons]="true">
							<div title>
								<div class="flex flex-row justify-between gap-4">
									<span class="whitespace-nowrap">
										@if (input.controls.custom?.get('label')?.value; as label) {
											{{ label | languageSelect: language }}
										}
										@if (input.controls.preset?.get('label')?.value; as label) {
											{{ label | languageSelect: language }}
										}
									</span>
									<span class="pr-2 text-sm italic">
										@if (input.controls.custom?.get('key')?.value; as key) {
											{{ key }}
										}
										@if (
											input.controls.preset?.get('type')?.getRawValue();
											as key
										) {
											{{ key }}*
										}
									</span>
								</div>
							</div>
							<ng-container buttons>
								<button yuno-admin-edit-button (click)="onSelectInput(i)">
									Edit
								</button>
								<button yuno-admin-delete-admin-button (click)="onRemoveInput(i)">
									Delete
								</button>
							</ng-container>
						</yuno-admin-drag-row>
					</div>
				}
			</div>
		</form>
	}
</div>
