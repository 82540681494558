@if (service.form) {
	<form [formGroup]="service.form" class="grid grid-cols-1 gap-4">
		<yuno-forms-title>Marker Clustering</yuno-forms-title>
		<yuno-forms-span>
			Define the marker clustering style to be used for this category.
		</yuno-forms-span>
		<div formGroupName="cluster" class="mt-4">
			<yuno-forms-toggle formControlName="active" label="Active"></yuno-forms-toggle>
		</div>
	</form>
}
