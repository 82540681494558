import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	AnnotationType,
	CustomSortingFunction,
	TableColumnDisplay,
	TableRow,
	TableSelectionOutputNew,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { YunoFormsSpanComponent, YunoFormsTitleComponent } from '@yuno/angular/forms/components';
import { AppUser, SafeUser } from '@yuno/api/interface';

import { ParticipationModelEditorsDefaultComponent } from '../default.component';

@Component({
	selector: 'yuno-admin-participation-model-users',
	standalone: true,
	imports: [AsyncPipe, YunoAdminTableComponent, YunoFormsTitleComponent, YunoFormsSpanComponent],
	templateUrl: './users.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorUsersComponent extends ParticipationModelEditorsDefaultComponent {
	@Input() users: Partial<SafeUser>[] = [];

	data$ = combineLatest({
		changes: this.service.rawForm$.pipe(
			startWith(undefined),
			// takeUntilDestroyed(this.destroyRef),
			map(data => data?.users?.map(data => data as TableRow) || [])
		)
	});

	columns: TableColumnDisplay[] = [
		{ key: 'email', label: 'Email', maxWidth: 40 },
		{ key: 'displayName', label: 'Name' }
	];

	customSorter: CustomSortingFunction = (item, property) => {
		switch (property) {
			case 'user.displayName':
				return item.user.displayName;
			case 'user.email':
				return item.user.email;
			case 'mailing.participation':
				return item.mailing.participation;
			default:
				return item[property];
		}
	};

	onSelect(e: TableSelectionOutputNew) {
		const user = e.row as Partial<SafeUser>;
		this.service.selectUser(user, e.checked);
	}

	onSelectAll(e: TableSelectionOutputNew) {
		const users: Partial<SafeUser>[] = e.selection as Partial<SafeUser>[];
		for (const user of users) {
			this.service.selectUser(user, !e.checked);
		}
	}
}
