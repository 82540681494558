import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CustomSource, Layer, Source } from '@yuno/api/interface';

export const layersActions = createActionGroup({
	source: 'Layers',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Layer>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Layer> }>(),
		selectFailure: props<{ error: Error }>(),

		selectSource: props<{ id: string }>(),
		selectCustomSource: props<{ name: string }>(),
		clearSource: emptyProps(),
		selectSourceSuccess: props<{ data: Partial<Source | CustomSource> }>(),
		selectSourceFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Layer> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Layer> }>(),
		updateSelectSuccess: props<{ data: Partial<Layer> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		saveFromTemplate: props<{ id: string; name: string }>(),
	}
});
