<router-outlet />

@if (data$ | async; as data) {
	<h1 class="font-bold">Shapes</h1>

	<yuno-admin-table
		#table
		[pagination]="true"
		[pageOptions]="{
			pageSize: 50,
			pageSizeOptions: [25, 50, 100],
			hidePageSize: false
		}"
		[selectable]="true"
		[filterable]="true"
		[data]="data.fences"
		[selectData]="data.changes"
		[columns]="tableColumns"
		(selected)="onSelect($event)"
		(selectAll)="onSelectAll($event)"
		[buttons]="tableButtons"
		[buttonEllipsis]="true"
		[disableAutoCloseButtons]="true">
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-button-table (click)="onEdit('shapes', row._id); closeButtonPopup()">
		Edit
	</button>
	@if (shapeOptionsData(row); as options) {
		@if (dataShapesForm(row)) {
			<form [formGroup]="dataShapesForm(row)" class="flex flex-row gap-4 pt-4">
				<yuno-forms-checkbox formControlName="canMove" label="Move"></yuno-forms-checkbox>
				<yuno-forms-checkbox formControlName="canScale" label="Scale"></yuno-forms-checkbox>
				<yuno-forms-checkbox
					formControlName="canRotate"
					label="Rotate"></yuno-forms-checkbox>
			</form>
		}
		@if (!options.enabled) {
			<span class="text-sm"><i>options disabled on non-selected rows</i></span>
		}
	}
</ng-template>
