module.exports = {
  A: {
    A: {
      K: 0,
      D: 0,
      E: 0.0417486,
      F: 0.0417486,
      A: 0,
      B: 0.445318,
      gC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "gC", "K", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      gC: 962323200,
      K: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "5": 0.003647,
      "6": 0.003647,
      "7": 0.007294,
      "8": 0.003647,
      "9": 0.010941,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0.003647,
      P: 0.047411,
      Q: 0,
      H: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0.014588,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0.003647,
      r: 0.007294,
      s: 0.061999,
      t: 0.007294,
      u: 0.007294,
      v: 0.003647,
      w: 0.007294,
      x: 0.010941,
      AB: 0.032823,
      BB: 0.014588,
      CB: 0.018235,
      DB: 0.010941,
      EB: 0.018235,
      FB: 0.025529,
      GB: 0.061999,
      HB: 0.193291,
      IB: 3.1656,
      JB: 1.29104,
      KB: 0.003647,
      I: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "G", "N", "O", "P", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "I", "", "", ""],
    E: "Edge",
    F: {
      "5": 1689897600,
      "6": 1692576000,
      "7": 1694649600,
      "8": 1697155200,
      "9": 1698969600,
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      G: 1491868800,
      N: 1508198400,
      O: 1525046400,
      P: 1542067200,
      Q: 1579046400,
      H: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      n: 1659657600,
      o: 1661990400,
      p: 1664755200,
      q: 1666915200,
      r: 1670198400,
      s: 1673481600,
      t: 1675900800,
      u: 1678665600,
      v: 1680825600,
      w: 1683158400,
      x: 1685664000,
      AB: 1701993600,
      BB: 1706227200,
      CB: 1708732800,
      DB: 1711152000,
      EB: 1713398400,
      FB: 1715990400,
      GB: 1718841600,
      HB: 1721865600,
      IB: 1724371200,
      JB: 1726704000,
      KB: 1729123200,
      I: 1731542400
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      G: "ms",
      N: "ms",
      O: "ms",
      P: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0.335524,
      "6": 0,
      "7": 0.003647,
      "8": 0.076587,
      "9": 0,
      hC: 0,
      IC: 0,
      J: 0.003647,
      LB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0.014588,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      MB: 0,
      y: 0,
      z: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0.007294,
      eB: 0.007294,
      fB: 0.003647,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0.040117,
      nB: 0,
      oB: 0,
      pB: 0.003647,
      qB: 0.014588,
      rB: 0,
      sB: 0,
      JC: 0.003647,
      tB: 0,
      KC: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0,
      "9B": 0,
      AC: 0.010941,
      Q: 0,
      H: 0,
      R: 0,
      LC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.007294,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0.003647,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.003647,
      m: 0.007294,
      n: 0,
      o: 0.003647,
      p: 0,
      q: 0,
      r: 0,
      s: 0.003647,
      t: 0,
      u: 0,
      v: 0,
      w: 0.007294,
      x: 0,
      AB: 0.007294,
      BB: 0.003647,
      CB: 0.003647,
      DB: 0.003647,
      EB: 0.003647,
      FB: 0.014588,
      GB: 0.010941,
      HB: 0.021882,
      IB: 0.03647,
      JB: 0.299054,
      KB: 1.30198,
      I: 0.007294,
      BC: 0,
      MC: 0,
      NC: 0,
      iC: 0,
      jC: 0,
      kC: 0,
      lC: 0
    },
    B: "moz",
    C: ["hC", "IC", "kC", "lC", "J", "LB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "MB", "y", "z", "0", "1", "2", "3", "4", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "JC", "tB", "KC", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "Q", "H", "R", "LC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "I", "BC", "MC", "NC", "iC", "jC"],
    E: "Firefox",
    F: {
      "0": 1368489600,
      "1": 1372118400,
      "2": 1375747200,
      "3": 1379376000,
      "4": 1386633600,
      "5": 1688428800,
      "6": 1690848000,
      "7": 1693267200,
      "8": 1695686400,
      "9": 1698105600,
      hC: 1161648000,
      IC: 1213660800,
      kC: 1246320000,
      lC: 1264032000,
      J: 1300752000,
      LB: 1308614400,
      K: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      G: 1342483200,
      N: 1346112000,
      O: 1349740800,
      P: 1353628800,
      MB: 1357603200,
      y: 1361232000,
      z: 1364860800,
      NB: 1391472000,
      OB: 1395100800,
      PB: 1398729600,
      QB: 1402358400,
      RB: 1405987200,
      SB: 1409616000,
      TB: 1413244800,
      UB: 1417392000,
      VB: 1421107200,
      WB: 1424736000,
      XB: 1428278400,
      YB: 1431475200,
      ZB: 1435881600,
      aB: 1439251200,
      bB: 1442880000,
      cB: 1446508800,
      dB: 1450137600,
      eB: 1453852800,
      fB: 1457395200,
      gB: 1461628800,
      hB: 1465257600,
      iB: 1470096000,
      jB: 1474329600,
      kB: 1479168000,
      lB: 1485216000,
      mB: 1488844800,
      nB: 1492560000,
      oB: 1497312000,
      pB: 1502150400,
      qB: 1506556800,
      rB: 1510617600,
      sB: 1516665600,
      JC: 1520985600,
      tB: 1525824000,
      KC: 1529971200,
      uB: 1536105600,
      vB: 1540252800,
      wB: 1544486400,
      xB: 1548720000,
      yB: 1552953600,
      zB: 1558396800,
      "0B": 1562630400,
      "1B": 1567468800,
      "2B": 1571788800,
      "3B": 1575331200,
      "4B": 1578355200,
      "5B": 1581379200,
      "6B": 1583798400,
      "7B": 1586304000,
      "8B": 1588636800,
      "9B": 1591056000,
      AC: 1593475200,
      Q: 1595894400,
      H: 1598313600,
      R: 1600732800,
      LC: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      n: 1661212800,
      o: 1663632000,
      p: 1666051200,
      q: 1668470400,
      r: 1670889600,
      s: 1673913600,
      t: 1676332800,
      u: 1678752000,
      v: 1681171200,
      w: 1683590400,
      x: 1686009600,
      AB: 1700524800,
      BB: 1702944000,
      CB: 1705968000,
      DB: 1708387200,
      EB: 1710806400,
      FB: 1713225600,
      GB: 1715644800,
      HB: 1718064000,
      IB: 1720483200,
      JB: 1722902400,
      KB: 1725321600,
      I: 1727740800,
      BC: 1730160000,
      MC: 1732579200,
      NC: null,
      iC: null,
      jC: null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0.025529,
      "6": 0.156821,
      "7": 0.098469,
      "8": 0.07294,
      "9": 0.058352,
      J: 0,
      LB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      MB: 0,
      y: 0,
      z: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0.010941,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0.003647,
      iB: 0.018235,
      jB: 0.018235,
      kB: 0.007294,
      lB: 0.003647,
      mB: 0.003647,
      nB: 0.007294,
      oB: 0,
      pB: 0,
      qB: 0.021882,
      rB: 0,
      sB: 0.003647,
      JC: 0,
      tB: 0,
      KC: 0.003647,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0.021882,
      zB: 0.003647,
      "0B": 0,
      "1B": 0.076587,
      "2B": 0.007294,
      "3B": 0,
      "4B": 0,
      "5B": 0.007294,
      "6B": 0.007294,
      "7B": 0.007294,
      "8B": 0.007294,
      "9B": 0.018235,
      AC: 0.014588,
      Q: 0.098469,
      H: 0.010941,
      R: 0.018235,
      S: 0.032823,
      T: 0.007294,
      U: 0.007294,
      V: 0.025529,
      W: 0.069293,
      X: 0.014588,
      Y: 0.010941,
      Z: 0.010941,
      a: 0.058352,
      b: 0.021882,
      c: 0.014588,
      d: 0.047411,
      e: 0.007294,
      f: 0.007294,
      g: 0.014588,
      h: 0.040117,
      i: 0.025529,
      j: 0.029176,
      k: 0.018235,
      l: 0.014588,
      m: 0.131292,
      n: 0.040117,
      o: 0.014588,
      p: 0.025529,
      q: 0.029176,
      r: 0.043764,
      s: 1.36033,
      t: 0.021882,
      u: 0.040117,
      v: 0.043764,
      w: 0.091175,
      x: 0.091175,
      AB: 0.094822,
      BB: 0.098469,
      CB: 0.10941,
      DB: 0.142233,
      EB: 0.262584,
      FB: 0.258937,
      GB: 0.419405,
      HB: 1.15975,
      IB: 12.4691,
      JB: 4.05546,
      KB: 0.014588,
      I: 0.003647,
      BC: 0,
      MC: 0,
      NC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "J", "LB", "K", "D", "E", "F", "A", "B", "C", "L", "M", "G", "N", "O", "P", "MB", "y", "z", "0", "1", "2", "3", "4", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "JC", "tB", "KC", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "Q", "H", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "I", "BC", "MC", "NC"],
    E: "Chrome",
    F: {
      "0": 1343692800,
      "1": 1348531200,
      "2": 1352246400,
      "3": 1357862400,
      "4": 1361404800,
      "5": 1689724800,
      "6": 1692057600,
      "7": 1694476800,
      "8": 1696896000,
      "9": 1698710400,
      J: 1264377600,
      LB: 1274745600,
      K: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      G: 1316131200,
      N: 1319500800,
      O: 1323734400,
      P: 1328659200,
      MB: 1332892800,
      y: 1337040000,
      z: 1340668800,
      NB: 1364428800,
      OB: 1369094400,
      PB: 1374105600,
      QB: 1376956800,
      RB: 1384214400,
      SB: 1389657600,
      TB: 1392940800,
      UB: 1397001600,
      VB: 1400544000,
      WB: 1405468800,
      XB: 1409011200,
      YB: 1412640000,
      ZB: 1416268800,
      aB: 1421798400,
      bB: 1425513600,
      cB: 1429401600,
      dB: 1432080000,
      eB: 1437523200,
      fB: 1441152000,
      gB: 1444780800,
      hB: 1449014400,
      iB: 1453248000,
      jB: 1456963200,
      kB: 1460592000,
      lB: 1464134400,
      mB: 1469059200,
      nB: 1472601600,
      oB: 1476230400,
      pB: 1480550400,
      qB: 1485302400,
      rB: 1489017600,
      sB: 1492560000,
      JC: 1496707200,
      tB: 1500940800,
      KC: 1504569600,
      uB: 1508198400,
      vB: 1512518400,
      wB: 1516752000,
      xB: 1520294400,
      yB: 1523923200,
      zB: 1527552000,
      "0B": 1532390400,
      "1B": 1536019200,
      "2B": 1539648000,
      "3B": 1543968000,
      "4B": 1548720000,
      "5B": 1552348800,
      "6B": 1555977600,
      "7B": 1559606400,
      "8B": 1564444800,
      "9B": 1568073600,
      AC: 1571702400,
      Q: 1575936000,
      H: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      n: 1659398400,
      o: 1661817600,
      p: 1664236800,
      q: 1666656000,
      r: 1669680000,
      s: 1673308800,
      t: 1675728000,
      u: 1678147200,
      v: 1680566400,
      w: 1682985600,
      x: 1685404800,
      AB: 1701993600,
      BB: 1705968000,
      CB: 1708387200,
      DB: 1710806400,
      EB: 1713225600,
      FB: 1715644800,
      GB: 1718064000,
      HB: 1721174400,
      IB: 1724112000,
      JB: 1726531200,
      KB: 1728950400,
      I: 1731369600,
      BC: null,
      MC: null,
      NC: null
    }
  },
  E: {
    A: {
      J: 0,
      LB: 0,
      K: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0.003647,
      M: 0.025529,
      G: 0.007294,
      mC: 0,
      OC: 0,
      nC: 0,
      oC: 0,
      pC: 0,
      qC: 0,
      PC: 0,
      CC: 0.003647,
      DC: 0.007294,
      rC: 0.058352,
      sC: 0.076587,
      tC: 0.021882,
      QC: 0.007294,
      RC: 0.018235,
      EC: 0.029176,
      uC: 0.21882,
      FC: 0.029176,
      SC: 0.03647,
      TC: 0.029176,
      UC: 0.069293,
      VC: 0.021882,
      WC: 0.040117,
      vC: 0.273525,
      GC: 0.018235,
      XC: 0.03647,
      YC: 0.03647,
      ZC: 0.043764,
      aC: 0.10941,
      bC: 0.309995,
      wC: 1.53903,
      HC: 0.149527,
      cC: 0.007294,
      dC: 0,
      xC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "mC", "OC", "J", "LB", "nC", "K", "oC", "D", "pC", "E", "F", "qC", "A", "PC", "B", "CC", "C", "DC", "L", "rC", "M", "sC", "G", "tC", "QC", "RC", "EC", "uC", "FC", "SC", "TC", "UC", "VC", "WC", "vC", "GC", "XC", "YC", "ZC", "aC", "bC", "wC", "HC", "cC", "dC", "xC", ""],
    E: "Safari",
    F: {
      mC: 1205798400,
      OC: 1226534400,
      J: 1244419200,
      LB: 1275868800,
      nC: 1311120000,
      K: 1343174400,
      oC: 1382400000,
      D: 1382400000,
      pC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      qC: 1458518400,
      A: 1474329600,
      PC: 1490572800,
      B: 1505779200,
      CC: 1522281600,
      C: 1537142400,
      DC: 1553472000,
      L: 1568851200,
      rC: 1585008000,
      M: 1600214400,
      sC: 1619395200,
      G: 1632096000,
      tC: 1635292800,
      QC: 1639353600,
      RC: 1647216000,
      EC: 1652745600,
      uC: 1658275200,
      FC: 1662940800,
      SC: 1666569600,
      TC: 1670889600,
      UC: 1674432000,
      VC: 1679875200,
      WC: 1684368000,
      vC: 1690156800,
      GC: 1695686400,
      XC: 1698192000,
      YC: 1702252800,
      ZC: 1705881600,
      aC: 1709596800,
      bC: 1715558400,
      wC: 1722211200,
      HC: 1726444800,
      cC: 1730073600,
      dC: null,
      xC: null
    }
  },
  F: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      F: 0,
      B: 0,
      C: 0,
      G: 0,
      N: 0,
      O: 0,
      P: 0,
      MB: 0,
      y: 0,
      z: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0.003647,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0.014588,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0,
      "9B": 0,
      AC: 0,
      Q: 0,
      H: 0,
      R: 0,
      LC: 0,
      S: 0,
      T: 0.018235,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.03647,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.029176,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0.018235,
      t: 0,
      u: 0.007294,
      v: 0.495992,
      w: 0.382935,
      x: 0.003647,
      yC: 0,
      zC: 0,
      "0C": 0,
      "1C": 0,
      CC: 0,
      eC: 0,
      "2C": 0,
      DC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "yC", "zC", "0C", "1C", "B", "CC", "eC", "2C", "C", "DC", "G", "N", "O", "P", "MB", "y", "z", "0", "1", "2", "3", "4", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "Q", "H", "R", "LC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "", "", ""],
    E: "Opera",
    F: {
      "0": 1401753600,
      "1": 1405987200,
      "2": 1409616000,
      "3": 1413331200,
      "4": 1417132800,
      F: 1150761600,
      yC: 1223424000,
      zC: 1251763200,
      "0C": 1267488000,
      "1C": 1277942400,
      B: 1292457600,
      CC: 1302566400,
      eC: 1309219200,
      "2C": 1323129600,
      C: 1323129600,
      DC: 1352073600,
      G: 1372723200,
      N: 1377561600,
      O: 1381104000,
      P: 1386288000,
      MB: 1390867200,
      y: 1393891200,
      z: 1399334400,
      NB: 1422316800,
      OB: 1425945600,
      PB: 1430179200,
      QB: 1433808000,
      RB: 1438646400,
      SB: 1442448000,
      TB: 1445904000,
      UB: 1449100800,
      VB: 1454371200,
      WB: 1457308800,
      XB: 1462320000,
      YB: 1465344000,
      ZB: 1470096000,
      aB: 1474329600,
      bB: 1477267200,
      cB: 1481587200,
      dB: 1486425600,
      eB: 1490054400,
      fB: 1494374400,
      gB: 1498003200,
      hB: 1502236800,
      iB: 1506470400,
      jB: 1510099200,
      kB: 1515024000,
      lB: 1517961600,
      mB: 1521676800,
      nB: 1525910400,
      oB: 1530144000,
      pB: 1534982400,
      qB: 1537833600,
      rB: 1543363200,
      sB: 1548201600,
      tB: 1554768000,
      uB: 1561593600,
      vB: 1566259200,
      wB: 1570406400,
      xB: 1573689600,
      yB: 1578441600,
      zB: 1583971200,
      "0B": 1587513600,
      "1B": 1592956800,
      "2B": 1595894400,
      "3B": 1600128000,
      "4B": 1603238400,
      "5B": 1613520000,
      "6B": 1612224000,
      "7B": 1616544000,
      "8B": 1619568000,
      "9B": 1623715200,
      AC: 1627948800,
      Q: 1631577600,
      H: 1633392000,
      R: 1635984000,
      LC: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800,
      n: 1699920000,
      o: 1699920000,
      p: 1702944000,
      q: 1707264000,
      r: 1710115200,
      s: 1711497600,
      t: 1716336000,
      u: 1719273600,
      v: 1721088000,
      w: 1724284800,
      x: 1727222400
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      yC: "o",
      zC: "o",
      "0C": "o",
      "1C": "o",
      CC: "o",
      eC: "o",
      "2C": "o",
      DC: "o"
    }
  },
  G: {
    A: {
      E: 0,
      OC: 0,
      "3C": 0,
      fC: 0.00292873,
      "4C": 0.00146437,
      "5C": 0.0087862,
      "6C": 0.0102506,
      "7C": 0,
      "8C": 0.0102506,
      "9C": 0.0248942,
      AD: 0.00732183,
      BD: 0.0424666,
      CD: 0.156687,
      DD: 0.0117149,
      ED: 0.00732183,
      FD: 0.177188,
      GD: 0.00292873,
      HD: 0.0556459,
      ID: 0.00732183,
      JD: 0.0292873,
      KD: 0.102506,
      LD: 0.0893264,
      MD: 0.0512528,
      QC: 0.0512528,
      RC: 0.0571103,
      EC: 0.0702896,
      ND: 0.69411,
      FC: 0.139115,
      SC: 0.276765,
      TC: 0.139115,
      UC: 0.234299,
      VC: 0.0497885,
      WC: 0.0981126,
      OD: 0.834689,
      GC: 0.0702896,
      XC: 0.111292,
      YC: 0.101041,
      ZC: 0.144972,
      aC: 0.31191,
      bC: 1.48926,
      PD: 7.91637,
      HC: 0.998698,
      cC: 0.0453954,
      dC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "OC", "3C", "fC", "4C", "5C", "6C", "E", "7C", "8C", "9C", "AD", "BD", "CD", "DD", "ED", "FD", "GD", "HD", "ID", "JD", "KD", "LD", "MD", "QC", "RC", "EC", "ND", "FC", "SC", "TC", "UC", "VC", "WC", "OD", "GC", "XC", "YC", "ZC", "aC", "bC", "PD", "HC", "cC", "dC", "", ""],
    E: "Safari on iOS",
    F: {
      OC: 1270252800,
      "3C": 1283904000,
      fC: 1299628800,
      "4C": 1331078400,
      "5C": 1359331200,
      "6C": 1394409600,
      E: 1410912000,
      "7C": 1413763200,
      "8C": 1442361600,
      "9C": 1458518400,
      AD: 1473724800,
      BD: 1490572800,
      CD: 1505779200,
      DD: 1522281600,
      ED: 1537142400,
      FD: 1553472000,
      GD: 1568851200,
      HD: 1572220800,
      ID: 1580169600,
      JD: 1585008000,
      KD: 1600214400,
      LD: 1619395200,
      MD: 1632096000,
      QC: 1639353600,
      RC: 1647216000,
      EC: 1652659200,
      ND: 1658275200,
      FC: 1662940800,
      SC: 1666569600,
      TC: 1670889600,
      UC: 1674432000,
      VC: 1679875200,
      WC: 1684368000,
      OD: 1690156800,
      GC: 1694995200,
      XC: 1698192000,
      YC: 1702252800,
      ZC: 1705881600,
      aC: 1709596800,
      bC: 1715558400,
      PD: 1722211200,
      HC: 1726444800,
      cC: 1730073600,
      dC: null
    }
  },
  H: {
    A: {
      QD: 0.05
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "QD", "", "", ""],
    E: "Opera Mini",
    F: {
      QD: 1426464000
    }
  },
  I: {
    A: {
      IC: 0,
      J: 0,
      I: 0.266132,
      RD: 0,
      SD: 0,
      TD: 0,
      UD: 0.0000266826,
      fC: 0.00010673,
      VD: 0,
      WD: 0.000426922
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "RD", "SD", "TD", "IC", "J", "UD", "fC", "VD", "WD", "I", "", "", ""],
    E: "Android Browser",
    F: {
      RD: 1256515200,
      SD: 1274313600,
      TD: 1291593600,
      IC: 1298332800,
      J: 1318896000,
      UD: 1341792000,
      fC: 1374624000,
      VD: 1386547200,
      WD: 1401667200,
      I: 1731369600
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      H: 1.15072,
      CC: 0,
      eC: 0,
      DC: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "CC", "eC", "C", "DC", "H", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      CC: 1314835200,
      eC: 1318291200,
      C: 1330300800,
      DC: 1349740800,
      H: 1709769600
    },
    D: {
      H: "webkit"
    }
  },
  L: {
    A: {
      I: 43.6637
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "", "", ""],
    E: "Chrome for Android",
    F: {
      I: 1731369600
    }
  },
  M: {
    A: {
      BC: 0.343062
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "BC", "", "", ""],
    E: "Firefox for Android",
    F: {
      BC: 1730160000
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      EC: 1.23248
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "EC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      EC: 1710115200
    },
    D: {
      EC: "webkit"
    }
  },
  P: {
    A: {
      "0": 0.0434355,
      "1": 0.0542944,
      "2": 0.0651532,
      "3": 0.206319,
      "4": 1.86773,
      J: 0.086871,
      y: 0.0108589,
      z: 0.0434355,
      XD: 0.0108589,
      YD: 0.0108589,
      ZD: 0.0325766,
      aD: 0,
      bD: 0,
      PC: 0,
      cD: 0,
      dD: 0,
      eD: 0,
      fD: 0,
      gD: 0,
      FC: 0,
      GC: 0.0217177,
      HC: 0,
      hD: 0.0108589
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "XD", "YD", "ZD", "aD", "bD", "PC", "cD", "dD", "eD", "fD", "gD", "FC", "GC", "HC", "hD", "y", "z", "0", "1", "2", "3", "4", "", "", ""],
    E: "Samsung Internet",
    F: {
      "0": 1689292800,
      "1": 1697587200,
      "2": 1711497600,
      "3": 1715126400,
      "4": 1717718400,
      J: 1461024000,
      XD: 1481846400,
      YD: 1509408000,
      ZD: 1528329600,
      aD: 1546128000,
      bD: 1554163200,
      PC: 1567900800,
      cD: 1582588800,
      dD: 1593475200,
      eD: 1605657600,
      fD: 1618531200,
      gD: 1629072000,
      FC: 1640736000,
      GC: 1651708800,
      HC: 1659657600,
      hD: 1667260800,
      y: 1677369600,
      z: 1684454400
    }
  },
  Q: {
    A: {
      iD: 0.273179
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "iD", "", "", ""],
    E: "QQ Browser",
    F: {
      iD: 1710288000
    }
  },
  R: {
    A: {
      jD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "jD", "", "", ""],
    E: "Baidu Browser",
    F: {
      jD: 1710201600
    }
  },
  S: {
    A: {
      kD: 0.031765,
      lD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "kD", "lD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      kD: 1527811200,
      lD: 1631664000
    }
  }
};