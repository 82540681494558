import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { YunoAdminButtonComponent } from 'libs/admin/ui/src/lib/button';

import { AppAtlasUiSettingsComponent } from '@yuno/admin/features/app-configuration';
import { AuthFacade } from '@yuno/angular-auth';
import { UserRoles, YunoUserRoles, minimalUserRole, minimalYunoRole } from '@yuno/api/interface';

import { CardButtonDisplay, CardButtonOutput } from '../types';

interface ContainerTabDisplay {
	key: string;
	isActive: boolean;
}

@Component({
	selector: 'yuno-card',
	standalone: true,
	imports: [CommonModule, RouterLink, YunoAdminButtonComponent, AppAtlasUiSettingsComponent],
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCardComponent implements OnInit {
	private readonly route = inject(ActivatedRoute);
	private readonly authFacade = inject(AuthFacade);

	private _hoverMode = false;
	private _header = true;
	private _footer = true;
	private _fullHeight = false;
	private _buttons: CardButtonDisplay[] = [];
	private _tabs: string[] = [];
	private _tabAccess = true;

	user$ = this.authFacade.user$;
	appRole$ = this.authFacade.appRole$;

	hover = false;
	opacity = 0.93;

	containerTabs: ContainerTabDisplay[] = [];
	activeTab = 0;
	@Output() outputTab = new EventEmitter<ContainerTabDisplay>();

	@Input() white = false;
	@Input() transparent = false;
	@Input() relativeButtons = false;

	@Input()
	set header(value: boolean) {
		this._header = value;
	}

	get header() {
		return this._header;
	}

	@Input()
	set footer(value: boolean) {
		this._footer = value;
	}

	get footer() {
		return this._footer;
	}

	@Input()
	set fullHeight(value: boolean) {
		this._fullHeight = value;
	}

	get fullHeight() {
		return this._fullHeight;
	}

	@Input()
	set buttons(value: CardButtonDisplay[]) {
		this._buttons = value;
	}

	get buttons() {
		return this._buttons;
	}

	@Input()
	set hoverMode(value: boolean) {
		this._hoverMode = value;
		this._hoverMode && (this.opacity = 0);
	}

	get hoverMode() {
		return this._hoverMode;
	}

	@Input()
	set btnOpacity(opac: number) {
		this.opacity = opac;
	}

	@Input()
	set tabs(tabs: string[]) {
		this._tabs = tabs;
		this.containerTabs = [];

		if (tabs.length < 1) {
			return;
		}

		tabs.forEach((tab, index) => {
			this.containerTabs.push({ key: tab, isActive: index === 0 });
		});

		this.onTabClick(this.containerTabs[0], 0);
	}

	get tabs() {
		return this._tabs;
	}

	@Input()
	set tabAccess(value: boolean) {
		this._tabAccess = value;
	}

	get tabAccess() {
		return this._tabAccess;
	}

	@Input() blue = false;
	@Input() blueHover = false;
	@Input() noPadding = false;
	@Input() paddingCondensed = false;

	@Output() outputBtn = new EventEmitter<CardButtonOutput>();

	ngOnInit() {
		this.checkActiveTabs();
	}

	checkActiveTabs(): void {
		const tab = this.route.snapshot.queryParams['tab'];
		if (!tab) {
			return;
		}

		const index = this.containerTabs.findIndex(ctab => ctab.key === tab);
		if (index !== -1) {
			this.onTabClick(this.containerTabs[index], index);
		}
	}

	onBtnClick(row: CardButtonOutput) {
		this.outputBtn.emit({ type: row.type });
	}

	setOpacity(bool: boolean) {
		if (this.hoverMode && !bool) {
			this.opacity = 0;
			return;
		}
		this.opacity = 0.93;
	}

	hasAccess(
		role?: UserRoles,
		appRole?: YunoUserRoles | null,
		authRole?: UserRoles,
		authAppRole?: YunoUserRoles
	): boolean {
		if (!role || !appRole) {
			return false;
		}

		if (authRole && !minimalUserRole(authRole || 'superadmin').includes(role)) {
			return false;
		}

		if (authAppRole && !minimalYunoRole(authAppRole || 'admin').includes(appRole)) {
			return false;
		}

		return true;
	}

	onTabClick(event: ContainerTabDisplay, index: number) {
		this.containerTabs[this.activeTab].isActive = false;
		this.activeTab = index;
		this.containerTabs[index].isActive = true;
		this.outputTab.next({ key: event.key, isActive: event.isActive });
	}
}
