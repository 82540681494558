import { animate, group, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';

import { IfChangesDirective } from '@yuno/angular/directives';
import { TextfieldComponent } from '@yuno/angular/textfield';

import { BasicOverlayComponent } from '../basic_component';

@Component({
	selector: 'yuno-project-atlas-textfield-overlay-left-full',
	// providers: [provideTranslocoScope('overlays')],
	template: `
		<ng-container *transloco="let t">
			<div class="h-full p-2 md:p-0">
				<div
					class="textfield-width-overlay h-full overflow-hidden rounded-lg bg-white shadow-xl md:rounded-none">
					<button
						type="button"
						(click)="closeOverlay()"
						class="absolute right-0 top-0 z-10 m-4 whitespace-nowrap text-sm">
						{{ t('overlays.close') }}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="2.5"
							stroke="currentColor"
							class="inline-block h-4 w-4">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>

					<div class="h-full w-full">
						@if (data) {
							<yuno-textfield
								[@fadeIn]
								*ifChanges="data"
								[data]="data"
								[colors]="colors"
								[feature]="feature"
								[embed]="embed"
								[language]="language"
								[disableAnimations]="true">
								<div before-text class="p-2"></div>
							</yuno-textfield>
						}

						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</ng-container>
	`,
	styleUrls: ['./left-full.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateX(0)' }),
				group([animate('1200ms cubic-bezier(.35,0,.25,1)', style({ opacity: 1 }))])
			]),
			transition(':leave', [
				style({
					position: 'absolute',
					opacity: 1,
					transform: 'translateX(0)',
					height: '100%'
				}),
				group([animate('600ms linear', style({ opacity: 0 }))])
			])
		])
	],
	standalone: true,
	imports: [TranslocoDirective, IfChangesDirective, TextfieldComponent]
})
export class TextfieldOverlayLeftFullComponent extends BasicOverlayComponent {
	@HostBinding('class') classNames = 'absolute w-full sm:w-auto h-full';
	@HostBinding('class.hide') @Input() hide: boolean;

	async closeOverlay(): Promise<void> {
		this.startClosing('left-full', this.animationTime);
	}
}
