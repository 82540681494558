import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Fence, Marker, MarkerCategory, MarkerCategoryCluster } from '@yuno/api/interface';

import { markerCategoriesActions } from './marker-categories.actions';
import { markerCategoriesFeature } from './marker-categories.state';

@Injectable()
export class MarkerCategoriesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(markerCategoriesFeature.selectLoaded));
	markerCategories$ = this.store.pipe(select(markerCategoriesFeature.selectMarkerCategories));

	selectedMarkerCategory$ = this.store.pipe(select(markerCategoriesFeature.selectSelected));
	selectedInput$ = this.store.pipe(select(markerCategoriesFeature.selectSelectedInput));
	selectedStyle$ = this.store.pipe(select(markerCategoriesFeature.selectSelectedStyle));
	selectedLoaded$ = this.store.pipe(select(markerCategoriesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(markerCategoriesFeature.selectStatus));

	placeMarkers$ = this.store.pipe(select(markerCategoriesFeature.selectPlaceMarkers));
	selectedMarker$ = this.store.pipe(select(markerCategoriesFeature.selectSelectedMarker));
	markerStatus$ = this.store.pipe(select(markerCategoriesFeature.selectMarkerStatus));

	mapStyle$ = this.store.pipe(select(markerCategoriesFeature.selectMapStyle));
	mapStyleBounds$ = this.store.pipe(select(markerCategoriesFeature.selectMapStyleBounds));

	get() {
		this.store.dispatch(markerCategoriesActions.load());
	}

	createNew(): void {
		this.store.dispatch(markerCategoriesActions.create());
	}

	createFromTemplate(_id: string | undefined) {
		if (!_id || _id === 'create') {
			return;
		}
		this.store.dispatch(markerCategoriesActions.createTemplate({ _id }));
	}

	select(_id: string | null) {
		if (!_id || _id === 'create') {
			return;
		}
		this.store.dispatch(markerCategoriesActions.select({ _id }));
	}

	clearSelect() {
		this.store.dispatch(markerCategoriesActions.clearSelect());
	}

	updateSelect(data: Partial<MarkerCategory>) {
		this.store.dispatch(markerCategoriesActions.updateSelect({ data }));
	}

	updateCluster(data: Partial<MarkerCategoryCluster>) {
		this.store.dispatch(markerCategoriesActions.updateCluster({ data }));
	}

	selectInput(index: number) {
		this.store.dispatch(markerCategoriesActions.selectInput({ index }));
	}

	selectStyle(index: number) {
		this.store.dispatch(markerCategoriesActions.selectStyle({ index }));
	}

	save() {
		this.store.dispatch(markerCategoriesActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(markerCategoriesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(markerCategoriesActions.delete({ _id }));
	}

	// MARKERS
	getMarkers(category: string) {
		this.store.dispatch(markerCategoriesActions.loadMarkers({ category }));
	}

	getMarkerCount(variable: 'public' | 'non-public' | 'all'): Observable<number> {
		return this.store.pipe(select(markerCategoriesFeature.getMarkerCount(variable)));
	}

	selectMarker(_id: string) {
		this.store.dispatch(markerCategoriesActions.selectMarker({ _id }));
	}

	updateMarker(data: Partial<Marker>) {
		this.store.dispatch(markerCategoriesActions.updateMarker({ data }));
	}

	saveMarker(fences?: { [key: string]: Fence }) {
		this.store.dispatch(markerCategoriesActions.saveMarker({ fences }));
	}

	deleteMarker(_id: string) {
		this.store.dispatch(markerCategoriesActions.deleteMarker({ _id }));
	}

	clearMarker() {
		this.store.dispatch(markerCategoriesActions.clearMarker());
	}

	saveFence() {
		this.store.dispatch(markerCategoriesActions.saveFence());
	}

	loadMapStyle(style: string): void {
		this.store.dispatch(markerCategoriesActions.loadMapStyle({ style }));
	}
}
